import {
  Box,
  ButtonBase,
  Card,
  Grid,
  List,
  ListItem,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import UserFooter from "../../components/UserFooter";
import React, { useState } from "react";
import { appColors } from "../../theme";
import useWindowDimensions from "../../components/useWindowDimensions";
import { useLocation, useNavigate } from "react-router-dom";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import FlexBetween from "../../components/FlexBetween";
import { IMAGE_BASE_URL } from "../../utils/constants";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";

const LeasingInformation = () => {
  const { height, width } = useWindowDimensions();
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const navigate = useNavigate();
  const location = useLocation();
  const [selected, setSelected] = useState();
  const [leaseDate, setLeaseDate] = useState("");
  const [moveDate, setMoveDate] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const handleFooterClick = () => {
    // if (selected === undefined) {
    //   enqueueSnackbar("Please Select a Floor Plan", { variant: "error" });
    // } else
    if (leaseDate === "") {
      enqueueSnackbar("Please Select Lease Period", { variant: "error" });
    } else if (moveDate === undefined) {
      enqueueSnackbar("Please Select Move in date", { variant: "error" });
    } else {
      const moveFormat = dayjs(moveDate).format("DD/MM/YYYY");
      navigate("/connectWithLandlord", {
        state: {
          firstName: location.state.firstName,
          lastName: location.state.lastName,
          email: location.state.email,
          university: location.state.university,
          leaseDate: leaseDate,
          moveDate: moveFormat,
          housing: location.state.housing,
          selectedId: location.state.housing?.floorPlans[selected]?.id,
        },
      });
    }
  };

  const periods = ["3 months", "6 months", "12 months"];

  return (
    <div
      style={{
        background: "#F9F9F9",
      }}
    >
      <Box padding="1rem 6%">
        <Grid
          container
          sx={{
            height: {
              md: height - 240,
            },
          }}
        >
          <Grid
            xs={12}
            md={8}
            sx={{
              borderRight: isNonMobile ? 1 : 0,
              borderColor: "#C2C2C2",
            }}
          >
            <Stack>
              <Typography
                fontSize={32}
                fontFamily="AvertaStd-Bold"
                color={appColors.primaryTextColor}
              >
                Leasing information
              </Typography>
              <Typography color="#A0A0A0" fontSize={14}>
                All fields are required
              </Typography>
              <Typography
                fontSize={16}
                fontFamily="AvertaStd-semibold"
                color={appColors.primaryTextColor}
                style={{
                  marginTop: 24,
                }}
              >
                Which floor plan are you interested in?
              </Typography>
              <Box
                style={{
                  width: "100%",
                }}
                sx={{
                  overflow: "auto",
                }}
              >
                <List
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    maxHeight: "100%",
                    overflow: "auto",
                    maxWidth: 800,
                  }}
                >
                  {location.state.housing?.floorPlans?.map((item, index) => {
                    return (
                      <ListItem
                        key={item}
                        style={{
                          marginRight: 8,
                        }}
                        onClick={() => {
                          setSelected(index);
                        }}
                      >
                        <Paper
                          elevation={2}
                          style={{
                            height: 135,
                            width: 235,
                            borderRadius: 15,
                            paddingTop: 15,
                          }}
                        >
                          <Stack>
                            <img
                              style={{
                                objectFit: "cover",
                                height: 72,
                              }}
                              alt={`plan${index}`}
                              src={`${IMAGE_BASE_URL}${item?.floorImage[0]}`}
                            />
                            <FlexBetween
                              style={{
                                backgroundColor:
                                  selected === index ? "#9490DB" : "white",
                                borderBottomLeftRadius: 15,
                                borderBottomRightRadius: 15,
                              }}
                            >
                              <Stack
                                direction="row"
                                style={{
                                  marginLeft: 12,
                                  marginRight: 12,
                                }}
                              >
                                <img
                                  alt="pdf"
                                  src={
                                    selected === index
                                      ? "/assets/icons/u_building.svg"
                                      : "/assets/icons/u_building_black.svg"
                                  }
                                />
                                <Typography
                                  color={selected === index ? "white" : "black"}
                                  fontSize={14}
                                  fontFamily="AvertaStd-semibold"
                                  style={{
                                    marginLeft: 4,
                                  }}
                                >
                                  Floor plan
                                </Typography>
                              </Stack>
                              <img
                                alt="save"
                                src="/assets/icons/tick.svg"
                                style={{
                                  visibility:
                                    selected === index ? "visible" : "hidden",
                                }}
                              />
                            </FlexBetween>
                          </Stack>
                        </Paper>
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
              <Typography
                fontSize={16}
                fontFamily="AvertaStd-semibold"
                color={appColors.primaryTextColor}
                style={{
                  marginTop: 24,
                }}
              >
                Lease period
              </Typography>
              <Select
                style={{
                  marginTop: 16,
                  paddingRight: 10,
                  fontSize: 14,
                  fontFamily: "AvertaStd-semibold",
                  color: appColors.primaryTextColor,
                }}
                sx={{
                  width: isNonMobile ? "50%" : "100%",
                  border: 1,
                  borderColor: "#E0E0E0",
                  borderRadius: 30,
                }}
                displayEmpty
                value={leaseDate}
                onChange={(event) => {
                  setLeaseDate(event.target.value);
                }}
                renderValue={
                  leaseDate !== "" ? undefined : () => "Select lease period"
                }
              >
                {periods.map((item, index) => {
                  return (
                    <MenuItem value={item} key={index}>
                      <Typography>{item}</Typography>
                    </MenuItem>
                  );
                })}
              </Select>
              <Typography
                fontSize={16}
                fontFamily="AvertaStd-semibold"
                color={appColors.primaryTextColor}
                style={{
                  marginTop: 24,
                }}
              >
                Move in date
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  slotProps={{
                    textField: {
                      variant: "standard",
                      InputProps: {
                        disableUnderline: true,
                      },
                    },
                  }}
                  sx={{
                    width: isNonMobile ? "50%" : "100%",
                    marginTop: 2.5,
                    paddingRight: 2,
                    paddingLeft: 2,
                    border: 1,
                    borderColor: "#C2C2C2",
                    borderRadius: 30,
                    height: 50,
                    display: "flex",
                    justifyContent: "center",
                  }}
                  renderInput={(params) => (
                    <TextField variant="standard" {...params} />
                  )}
                  value={moveDate}
                  inputFormat="MM/dd/yyyy"
                  onChange={(e) => {
                    setMoveDate(e);
                  }}
                />
              </LocalizationProvider>
            </Stack>
          </Grid>
          <Grid xs={12} md={4}>
            <Card
              sx={{
                border: 1,
                borderColor: "#C2C2C2",
                borderRadius: 5,
                marginTop: isNonMobile ? 0 : 2,
                height: isNonMobile ? 350 : 400,
                marginLeft: isNonMobile ? 2 : 0,
                width: {
                  md: 280,
                },
              }}
            >
              <Stack>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    height: "100%",
                  }}
                >
                  <ButtonBase>
                    <img
                      style={{
                        objectFit: "cover",
                        width: isNonMobile ? 268 : width - 70,
                        height: 172,
                        margin: 7,
                        borderRadius: 12,
                      }}
                      alt="listing"
                      src={IMAGE_BASE_URL + location.state.housing?.photos[0]}
                      onClick={() => {
                        //navigate(`/listing/${item.id}`);
                      }}
                    />
                  </ButtonBase>
                  <ButtonBase
                    onClick={() => {
                      console.log("Love Clicked");
                    }}
                  >
                    <img
                      style={{
                        position: "absolute",
                        top: 12,
                        right: 12,
                      }}
                      alt="love"
                      src="/assets/love.svg"
                    />
                  </ButtonBase>
                </Box>
                <Stack
                  style={{
                    marginLeft: 12,
                    marginRight: 12,
                  }}
                  onClick={() => {
                    //console.log(item.id);
                  }}
                >
                  <Stack direction="row">
                    <img width={16} alt="star" src="/assets/star.svg" />
                    <Typography
                      align="center"
                      fontSize={12}
                      color="#7F7F7F"
                      style={{
                        marginLeft: 4,
                      }}
                    >
                      {location.state.housing?.reviewsAverage?.toFixed(2)} (
                      {location.state.housing?.numberOfReviews})
                    </Typography>
                  </Stack>
                  <Typography
                    fontSize={16}
                    fontFamily="AvertaStd-Bold"
                    noWrap
                    style={{
                      marginTop: 6,
                    }}
                  >
                    {location.state.housing?.title}
                  </Typography>
                  <Stack
                    direction="row"
                    style={{
                      height: 24,
                      width: 82,
                      backgroundColor: "#D4D3EB",
                      borderRadius: 6,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img alt="usd" src="/assets/usd.svg" />
                    <Typography
                      fontSize={11}
                      color="#9490DB"
                      style={{
                        marginLeft: 4,
                      }}
                    >
                      Promotion
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={0.3}
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <img alt="subway" src="/assets/icons/subway.svg" />
                    <img alt="utensils" src="/assets/icons/utensils.svg" />
                  </Stack>
                  <Stack
                    direction="row"
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <Typography
                      fontSize={18}
                      fontFamily="AvertaStd-Bold"
                      color="#231F20"
                    >
                      Starting at $
                      {location.state.housing?.minRent === undefined
                        ? location.state.housing?.floorPlans[0]?.minRent
                        : location.state.housing?.minRent}
                    </Typography>
                    <Typography
                      fontSize={12}
                      color="#949494"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      /month
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <div
        style={{
          textAlign: "center",
        }}
      >
        <UserFooter buttonTitle="Next" handleClick={handleFooterClick} />
      </div>
    </div>
  );
};

export default LeasingInformation;
