import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import useWindowDimensions from "../../../components/useWindowDimensions";
import { appColors } from "../../../theme";
import { useDispatch, useSelector } from "../../../state/store";
import { getMyHousings } from "../../../state/slices/froomie";
import ListingItem from "../../../components/ListingItem";

const MyProperties = () => {
  const { height } = useWindowDimensions();
  const dispatch = useDispatch();
  const { myHousings } = useSelector((state) => state.froomie);

  useEffect(() => {
    dispatch(getMyHousings());
  }, [dispatch]);

  return (
    <div
      style={{
        background: "#F9F9F9",
      }}
    >
      <Box padding="1rem 6%">
        <Grid
          container
          sx={{
            height: {
              md: height - 240,
            },
          }}
        >
          <Grid xs={12} md={8}>
            <Stack>
              <Typography
                fontSize={32}
                fontFamily="AvertaStd-Bold"
                color={appColors.primaryTextColor}
              >
                Your Properties
              </Typography>
              <Grid
                container
                justifyContent="left"
                spacing={2}
                maxWidth={1200}
                style={{
                  marginTop: 30,
                }}
              >
                {myHousings.map((item) => {
                  return (
                    <Grid
                      key={item.id}
                      item
                      style={{
                        marginBottom: 10,
                      }}
                    >
                      <ListingItem item={item} />
                    </Grid>
                  );
                })}
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default MyProperties;
