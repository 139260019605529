import { Box, ButtonBase, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import FlexBetween from "../../components/FlexBetween";

const PrivacyPolicy = () => {
  return (
    <>
      <Box
        padding="1rem 6%"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack>
          <Typography
            align="center"
            fontSize={40}
            fontFamily="AvertaStd-Bold"
            style={{
              color: "#000000",
              marginTop: 40,
            }}
          >
            PRIVACY NOTICE
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            Last updated Dec 5th, 2023
          </Typography>
          <Typography
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            Thank you for choosing to be part of our community at Froomie LLC,
            doing business as Froomie{" "}
            <Box fontFamily="AvertaStd-Bold" display="inline">
              ("Froomie", "we", "us", "our").
            </Box>{" "}
            We are committed to protecting your personal information and your
            right to privacy. If you have any questions or concerns about this
            privacy notice, or our practices with regards to your personal
            information, please contact us at hello@froomie.io.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            When you and more generally, use any of our services (the
            "Services", which include the ), we appreciate that you are trusting
            us with your personal information. We take
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            your privacy very seriously. In this privacy notice, we seek to
            explain to you in the clearest way possible what information we
            collect, how we use it and what rights you have in relation to it.
            We hope you take some time to read through it carefully, as it is
            important. If there are any terms in this privacy notice that you do
            not agree with, please discontinue use of our Services immediately.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            This privacy notice applies to all information collected through our
            Services (which, as described above, includes our ), as well as, any
            related services, sales, marketing or events.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            Please read this privacy notice carefully as it will help you
            understand what we do with the information that we collect.
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={24}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            TABLE OF CONTENTS
          </Typography>
          <FlexBetween
            style={{
              marginTop: 24,
            }}
          >
            <ButtonBase
              sx={{
                textAlign: "left",
                color: "blue",
              }}
            >
              1. WHAT INFORMATION DO WE COLLECT?
            </ButtonBase>
          </FlexBetween>
          <FlexBetween
            style={{
              marginTop: 8,
            }}
          >
            <ButtonBase
              sx={{
                textAlign: "left",
                color: "blue",
              }}
            >
              2. HOW DO WE USE YOUR INFORMATION?
            </ButtonBase>
          </FlexBetween>
          <FlexBetween
            style={{
              marginTop: 8,
            }}
          >
            <ButtonBase
              sx={{
                textAlign: "left",
                color: "blue",
              }}
            >
              3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
            </ButtonBase>
          </FlexBetween>
          <FlexBetween
            style={{
              marginTop: 8,
            }}
          >
            <ButtonBase
              sx={{
                textAlign: "left",
                color: "blue",
              }}
            >
              4. WHO WILL YOUR INFORMATION BE SHARED WITH?
            </ButtonBase>
          </FlexBetween>
          <FlexBetween
            style={{
              marginTop: 8,
            }}
          >
            <ButtonBase
              sx={{
                textAlign: "left",
                color: "blue",
              }}
            >
              5. HOW LONG DO WE KEEP YOUR INFORMATION?
            </ButtonBase>
          </FlexBetween>
          <FlexBetween
            style={{
              marginTop: 8,
            }}
          >
            <ButtonBase
              sx={{
                textAlign: "left",
                color: "blue",
              }}
            >
              6. HOW DO WE KEEP YOUR INFORMATION SAFE?
            </ButtonBase>
          </FlexBetween>
          <FlexBetween
            style={{
              marginTop: 8,
            }}
          >
            <ButtonBase
              sx={{
                textAlign: "left",
                color: "blue",
              }}
            >
              7. WHAT ARE YOUR PRIVACY RIGHTS?
            </ButtonBase>
          </FlexBetween>
          <FlexBetween
            style={{
              marginTop: 8,
            }}
          >
            <ButtonBase
              sx={{
                textAlign: "left",
                color: "blue",
              }}
            >
              8. CONTROLS FOR DO-NOT-TRACK FEATURES
            </ButtonBase>
          </FlexBetween>
          <FlexBetween
            style={{
              marginTop: 8,
            }}
          >
            <ButtonBase
              sx={{
                textAlign: "left",
                color: "blue",
              }}
            >
              9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </ButtonBase>
          </FlexBetween>
          <FlexBetween
            style={{
              marginTop: 8,
            }}
          >
            <ButtonBase
              sx={{
                textAlign: "left",
                color: "blue",
              }}
            >
              10. DO WE MAKE UPDATES TO THIS NOTICE?
            </ButtonBase>
          </FlexBetween>
          <FlexBetween
            style={{
              marginTop: 8,
            }}
          >
            <ButtonBase
              sx={{
                textAlign: "left",
                color: "blue",
              }}
            >
              11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </ButtonBase>
          </FlexBetween>
          <FlexBetween
            style={{
              marginTop: 8,
            }}
          >
            <ButtonBase
              sx={{
                textAlign: "left",
                color: "blue",
              }}
            >
              12. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </ButtonBase>
          </FlexBetween>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={24}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            1. WHAT INFORMATION DO WE COLLECT? Personal information you disclose
            to us
          </Typography>
          <Typography
            component="div"
            fontStyle="italic"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              In Short:
            </Box>{" "}
            We collect personal information that you provide to us.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            We collect personal information that you voluntarily provide to us
            when you register to express an interest in obtaining information
            about us or our products and Services, when you participate in
            activities on the (such as by posting messages in our online forums
            or entering competitions, contests or giveaways) or otherwise when
            you contact us.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            The personal information that we collect depends on the context of
            your interactions with us and the choices you make and the products
            and features you use. The personal information we collect may
            include the following:
          </Typography>
          <Typography
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              Personal Information Provided by You.
            </Box>{" "}
            We collect names; email addresses; passwords; contact or
            authentication data; university; graduation year; and other similar
            information.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            All personal information that you provide to us must be true,
            complete and accurate, and you must notify us of any changes to such
            personal information.
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={22}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            Information automatically collected
          </Typography>
          <Typography
            fontStyle="italic"
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              In Short:
            </Box>{" "}
            Some information — such as your Internet Protocol (IP) address
            and/or browser and device characteristics — is collected
            automatically when you visit our website.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            We automatically collect certain information when you visit, use or
            navigate the . This information does not reveal your specific
            identity (like your name or contact information) but may include
            device and usage information, such as your IP address, browser and
            device characteristics, operating system, language preferences,
            referring URLs, device name, country, location, information about
            how and when you use our and other technical information. This
            information is primarily needed to maintain the security and
            operation of our , and for our internal analytics and reporting
            purposes.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            The information we collect includes:
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            Log and Usage Data. Log and usage data is service-related,
            diagnostic, usage and performance information our servers
            automatically collect when you access or use our and which we record
            in log files. Depending on how you interact with us, this log data
            may include your IP address, device information, browser type and
            settings and information about your activity in the (such as the
            date/time stamps associated with your usage, pages and files viewed,
            searches and other actions you take such as which features you use),
            device event information (such as system activity, error reports
            (sometimes called 'crash dumps') and hardware settings).
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            Device Data. We collect device data such as information about your
            computer, phone, tablet or other device you use to access them.
            Depending on the device used, this device data may include
            information such as your IP address (or proxy server), device and
            application identification numbers, location, browser type, hardware
            model, Internet service provider and/or mobile carrier, operating
            system and system configuration information.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            Location Data. We collect location data such as information about
            your device's location, which can be either precise or imprecise.
            How much information we collect depends on the type and settings of
            the device you use to access the . For example, we may use GPS and
            other technologies to collect geolocation data that tells us your
            current location (based on your IP address). You can opt out of
            allowing us to collect this information either by refusing access to
            the information or by disabling your Location setting on your
            device. Note however, if you choose to opt out, you may not be able
            to use certain aspects of the Services.
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={22}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            Information automatically collected
          </Typography>
          <Typography
            fontStyle="italic"
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              In Short:
            </Box>{" "}
            We may collect limited data from public databases, marketing
            partners, and other outside sources.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            In order to enhance our ability to provide relevant marketing,
            offers and services to you and update our records, we may obtain
            information about you from other sources, such as public databases,
            joint marketing partners, affiliate programs, data providers, as
            well as from other third parties. This information includes mailing
            addresses, job titles, email addresses, phone numbers, intent data
            (or user behavior data), Internet Protocol (IP) addresses, social
            media profiles, social media URLs and custom profiles, for purposes
            of targeted advertising and event promotion.
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={24}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            2. HOW DO WE USE YOUR INFORMATION?
          </Typography>
          <Typography
            fontStyle="italic"
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              In Short:
            </Box>{" "}
            We process your information for purposes based on legitimate
            business interests, the fulfillment of our contract with you,
            compliance with our legal obligations, and/or your consent.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            We use personal information collected via our for a variety of
            business purposes described below. We process your personal
            information for these purposes in reliance on our legitimate
            business interests, in order to enter into or perform a contract
            with you, with your consent, and/or for compliance with our legal
            obligations. We indicate the specific processing grounds we rely on
            next to each purpose listed below.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            We use the information we collect or receive:
          </Typography>
          <Typography
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              To facilitate account creation and logon process.
            </Box>{" "}
            If you choose to link your account with us to a third-party account
            (such as your Google or Facebook account), we use the information
            you allowed us to collect from those third parties to facilitate
            account creation and logon process for the performance of the
            contract.
          </Typography>
          <Typography
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              To post testimonials.
            </Box>{" "}
            We post testimonials that may contain personal information. Prior to
            posting a testimonial, we will obtain your consent to use your name
            and the content of the testimonial. If you wish to update, or delete
            your testimonial, please contact us at hello@froomie.io and be sure
            to include your name, testimonial location, and contact information.
          </Typography>
          <Typography
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              Request feedback.
            </Box>{" "}
            We may use your information to request feedback and to contact you
            about your use of our .
          </Typography>
          <Typography
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              To enable user-to-user communications.
            </Box>{" "}
            We may use your information in order to enable user-to-user
            communications with each user's consent.
          </Typography>
          <Typography
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              To manage user accounts.
            </Box>{" "}
            We may use your information for the purposes of managing our account
            and keeping it in working order.
          </Typography>
          <Typography
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              To send administrative information to you.
            </Box>{" "}
            We may use your personal information to send you product, service
            and new feature information and/or information about changes to our
            terms, conditions, and policies.
          </Typography>
          <Typography
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              To protect our Services.
            </Box>{" "}
            We may use your information as part of our efforts to keep our safe
            and secure (for example, for fraud monitoring and prevention).
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            To enforce our terms, conditions and policies for business purposes,
            to comply with legal and regulatory requirements or in connection
            with our contract.
          </Typography>
          <Typography
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              To respond to legal requests and prevent harm.
            </Box>{" "}
            If we receive a subpoena or other legal request, we may need to
            inspect the data we hold to determine how to respond.
          </Typography>
          <Typography
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              Fulfill and manage your orders.
            </Box>{" "}
            We may use your information to fulfill and manage your orders,
            payments, returns, and exchanges made through the.
          </Typography>
          <Typography
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              Administer prize draws and competitions.
            </Box>{" "}
            We may use your information to administer prize draws and
            competitions when you elect to participate in our competitions.
          </Typography>
          <Typography
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              To deliver and facilitate delivery of services to the user.
            </Box>{" "}
            We may use your information to provide you with the requested
            service.
          </Typography>
          <Typography
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              To respond to user inquiries/offer support to users.
            </Box>{" "}
            We may use your information to respond to your inquiries and solve
            any potential issues you might have with the use of our Services.
          </Typography>
          <Typography
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              To send you marketing and promotional communications.
            </Box>{" "}
            We and/or our third-party marketing partners may use the personal
            information you send to us for our marketing purposes, if this is in
            accordance with your marketing
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            preferences. For example, when expressing an interest in obtaining
            information about us or our , subscribing to marketing or otherwise
            contacting us, we will collect personal information from you. You
            can opt-out of our marketing emails at any time (see the "WHAT ARE
            YOUR PRIVACY RIGHTS?" below).
          </Typography>
          <Typography
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              Deliver targeted advertising to you.
            </Box>{" "}
            We may use your information to develop and display personalized
            content and advertising (and work with third parties who do so)
            tailored to your interests and/or location and to measure its
            effectiveness.
          </Typography>
          <Typography
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              For other business purposes.
            </Box>{" "}
            We may use your information for other business purposes, such as
            data analysis, identifying usage trends, determining the
            effectiveness of our promotional campaigns and to evaluate and
            improve our products, marketing and your experience. We may use and
            store this information in aggregated and anonymized form so that it
            is not associated with individual end users and does not include
            personal information. We will not use identifiable personal
            information without your consent.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            Property Manager Contact
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={24}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
          </Typography>
          <Typography
            fontStyle="italic"
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              In Short:
            </Box>{" "}
            We only share information with your consent, to comply with laws, to
            provide you with services, to protect your rights, or to fulfill
            business obligations.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            We may process or share your data that we hold based on the
            following legal basis:
          </Typography>
          <Typography
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              Consent:
            </Box>{" "}
            We may process your data if you have given us specific consent to
            use your personal information for a specific purpose.
          </Typography>
          <Typography
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              Legitimate Interests:
            </Box>{" "}
            We may process your data when it is reasonably necessary to achieve
            our legitimate business interests.
          </Typography>
          <Typography
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              Performance of a Contract:
            </Box>{" "}
            Where we have entered into a contract with you, we may process your
            personal information to fulfill the terms of our contract.
          </Typography>
          <Typography
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              Legal Obligations:
            </Box>{" "}
            We may disclose your information where we are legally required to do
            so in order to comply with applicable law, governmental requests, a
            judicial proceeding, court order, or legal process, such as in
            response to a court order or a subpoena (including in response to
            public authorities to meet national security or law enforcement
            requirements).
          </Typography>
          <Typography
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              Vital Interests:
            </Box>{" "}
            We may disclose your information where we believe it is necessary to
            investigate, prevent, or take action regarding potential violations
            of our policies, suspected fraud, situations involving potential
            threats to the safety of any person and illegal activities, or as
            evidence in litigation in which we are involved. requirements).
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            More specifically, we may need to process your data or share your
            personal information in the following situations:
          </Typography>
          <Typography
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              Business Transfers.
            </Box>{" "}
            We may share or transfer your information in connection with, or
            during negotiations of, any merger, sale of company assets,
            financing, or acquisition of all or a portion of our business to
            another company.
          </Typography>
          <Typography
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              Vendors, Consultants and Other Third-Party Service Providers.
            </Box>{" "}
            We may share your data with third-party vendors, service providers,
            contractors or agents who perform services for us or on our behalf
            and require access to such information to do that work. Examples
            include: payment processing, data analysis, email delivery, hosting
            services, customer service and marketing efforts. We may allow
            selected third parties to use tracking technology on the , which
            will enable them to collect data on our behalf about how you
            interact with our over time. This information may be used to, among
            other things, analyze and track data, determine the popularity of
            certain content, pages or features, and better understand online
            activity. Unless described in this notice,
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            We do not share, sell, rent or trade any of your information with
            third parties for their promotional purposes.
          </Typography>
          <Typography
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              Third-Party Advertisers.
            </Box>{" "}
            We may use third-party advertising companies to serve ads when you
            visit or use the . These companies may use information about your
            visits to our Website(s) and other websites that are contained in
            web cookies and other tracking technologies in order to provide
            advertisements about goods and services of interest to you.
          </Typography>
          <Typography
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              Affiliates.
            </Box>{" "}
            We may share your information with our affiliates, in which case we
            will require those affiliates to honor this privacy notice.
            Affiliates include our parent company and any subsidiaries, joint
            venture partners or other companies that we control or that are
            under common control with us.
          </Typography>
          <Typography
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              Business Partners.
            </Box>{" "}
            We may share your information with our business partners to offer
            you certain products, services or promotions.
          </Typography>
          <Typography
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              Other Users.
            </Box>{" "}
            When you share personal information (for example, by posting
            comments, contributions or other content to the ) or otherwise
            interact with public areas of the , such personal information may be
            viewed by all users and may be publicly made available outside the
            in perpetuity. Similarly, other users will be able to view
            descriptions of your activity, communicate with you within our , and
            view your profile.
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={24}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            4. WHO WILL YOUR INFORMATION BE SHARED WITH?
          </Typography>
          <Typography
            fontStyle="italic"
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              In Short:
            </Box>{" "}
            We only share information with the following categories of third
            parties.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            We only share and disclose your information with the following
            categories of third parties. If we have processed your data based on
            your consent and you wish to revoke your consent, please contact us
            using the contact details provided in the section below titled "HOW
            CAN YOU CONTACT US ABOUT THIS NOTICE?".
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            Affiliate Marketing Programs
          </Typography>
          <Typography
            style={{
              color: "#000000",
            }}
          >
            Data Analytics Services
          </Typography>
          <Typography
            style={{
              color: "#000000",
            }}
          >
            Finance & Accounting Tools
          </Typography>
          <Typography
            style={{
              color: "#000000",
            }}
          >
            Sales & Marketing Tools
          </Typography>
          <Typography
            style={{
              color: "#000000",
            }}
          >
            User Account Registration & Authentication Services
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={24}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            5. HOW LONG DO WE KEEP YOUR INFORMATION?
          </Typography>
          <Typography
            fontStyle="italic"
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              In Short:
            </Box>{" "}
            We keep your information for as long as necessary to fulfill the
            purposes outlined in this privacy notice unless otherwise required
            by law.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting or other legal requirements). No purpose in this
            notice will require us keeping your personal information for longer
            than the period of time in which users have an account with us.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize such
            information, or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={24}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            6. HOW DO WE KEEP YOUR INFORMATION SAFE?
          </Typography>
          <Typography
            fontStyle="italic"
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              In Short:
            </Box>{" "}
            We aim to protect your personal information through a system of
            organizational and technical security measures.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            We have implemented appropriate technical and organizational
            security measures designed to protect the security of any personal
            information we process. However, despite our safeguards and efforts
            to secure your information, no electronic transmission over the
            Internet or information storage technology can be guaranteed to be
            100% secure, so we cannot promise or guarantee that hackers,
            cybercriminals, or other unauthorized third parties will not be able
            to defeat our security, and improperly collect, access, steal, or
            modify your information. Although we will do our best to protect
            your personal information, transmission of personal information to
            and from us is at your own risk. You should only access it within a
            secure environment.
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={24}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            7. WHAT ARE YOUR PRIVACY RIGHTS?
          </Typography>
          <Typography
            fontStyle="italic"
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              In Short:
            </Box>{" "}
            You may review, change, or terminate your account at any time.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            We have implemented appropriate technical and organizational
            security measures designed to protect the security of any personal
            information we process. However, despite our safeguards and efforts
            to secure your information, no electronic transmission over the
            Internet or information storage technology can be guaranteed to be
            100% secure, so we cannot promise or guarantee that hackers,
            cybercriminals, or other unauthorized third parties will not be able
            to defeat our security, and improperly collect, access, steal, or
            modify your information. Although we will do our best to protect
            your personal information, transmission of personal information to
            and from us is at your own risk. You should only access it within a
            secure environment.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            If you are a resident in the EEA or UK and you believe we are
            unlawfully processing your personal information, you also have the
            right to complain to your local data protection supervisory
            authority. You can find their contact details here:
          </Typography>
          <Link
            to={
              "http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
            }
          >
            http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
          </Link>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            If you are a resident in Switzerland, the contact details for the
            data protection authorities are available here:
          </Typography>
          <Link to={"https://www.edoeb.admin.ch/edoeb/en/home.html"}>
            https://www.edoeb.admin.ch/edoeb/en/home.html
          </Link>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            If you have questions or comments about your privacy rights, you may
            email us at hello@froomie.io
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={24}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            Account Information
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            If you would at any time like to review or change the information in
            your account or terminate your account, you can:
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            Log in to your account settings and update your user account.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            Contact us using the contact information provided.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, we may retain some information in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our Terms of Use and/or comply with applicable legal
            requirements.
          </Typography>
          <Typography
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              Opting out of email marketing:
            </Box>{" "}
            You can unsubscribe from our marketing email list at any time by
            clicking on the unsubscribe link in the emails that we send or by
            contacting us using the details provided below. You will then be
            removed from the marketing email list — however, we may still
            communicate with you, for example to send you service-related emails
            that are necessary for the administration and use of your account,
            to respond to service requests, or for other non-marketing purposes.
            To otherwise opt-out, you may:
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            Contact us using the contact information provided.
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={24}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            8. CONTROLS FOR DO-NOT-TRACK FEATURES
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track ("DNT") feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage no uniform technology standard for recognizing and
            implementing DNT signals has been finalized. As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online. If
            a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version
            of this privacy notice.
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={24}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </Typography>
          <Typography
            fontStyle="italic"
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              In Short:
            </Box>{" "}
            Yes, if you are a resident of California, you are granted specific
            rights regarding access to your personal information.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            California Civil Code Section 1798.83, also known as the "Shine The
            Light" law, permits our users who are California residents to
            request and obtain from us, once a year and free of charge,
            information about categories of personal information (if any) we
            disclosed to third parties for direct marketing purposes and the
            names and addresses of all third parties with which we shared
            personal information in the immediately preceding calendar year. If
            you are a California resident and would like to make such a request,
            please submit your request in writing to us using the contact
            information provided below.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            If you are under 18 years of age, reside in California, and have a
            registered account with , you have the right to request removal of
            unwanted data that you publicly post on the . To request removal of
            such data, please contact us using the contact information provided
            below, and include the email address associated with your account
            and a statement that you reside in California. We will make sure the
            data is not publicly displayed on the , but please be aware that the
            data may not be completely or comprehensively removed from all our
            systems (e.g. backups, etc.).
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={24}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            10. DO WE MAKE UPDATES TO THIS NOTICE?
          </Typography>
          <Typography
            fontStyle="italic"
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            <Box fontFamily="AvertaStd-Bold" display="inline">
              In Short:
            </Box>{" "}
            Yes, we will update this notice as necessary to stay compliant with
            relevant laws.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated "Revised" date and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy notice, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={24}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            If you have questions or comments about this notice, you may email
            us at hello@froomie.io or by post to:
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            Froomie LLC __________ Austin, TX United States
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={24}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            change that information, or delete it in some circumstances. To
            request to review, update, or delete your personal information,
            please visit:
          </Typography>
          <Link to={"http://www.froomie.io"}>http://www.froomie.io</Link>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
              marginBottom: 40,
            }}
          >
            Privacy Policy Terms Of Use Disclaimer Cookie Policy Roadmap Support
            Limit the use of my sensitive personal information Do not sell or
            share my personal information Consent Preferences
          </Typography>
        </Stack>
      </Box>
    </>
  );
};

export default PrivacyPolicy;
