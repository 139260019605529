import { Box, ButtonBase, Card, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { IMAGE_BASE_URL } from "../utils/constants";

const ListingItem = ({ item }) => {
  const navigate = useNavigate();

  return (
    <div>
      <Card
        sx={{
          border: 1,
          borderColor: "#C2C2C2",
          borderRadius: 5,
          width: 280,
          height: 340,
        }}
      >
        <Stack>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <ButtonBase
              onClick={() => {
                navigate(`/listing/${item?.id}`);
              }}
              style={{
                width: 268,
                height: 180,
                margin: 7,
              }}
            >
              <img
                style={{
                  objectFit: "cover",
                  width: 268,
                  height: 180,
                  borderRadius: 12,
                }}
                alt="listing"
                src={IMAGE_BASE_URL + item?.photos[0]}
              />
            </ButtonBase>
            <ButtonBase
              onClick={() => {
                console.log("Love Clicked");
              }}
            >
              <img
                style={{
                  position: "absolute",
                  top: 12,
                  right: 12,
                }}
                alt="love"
                src="/assets/love.svg"
              />
            </ButtonBase>
          </Box>
          <Stack
            style={{
              marginLeft: 12,
              marginRight: 12,
            }}
            onClick={() => {
              console.log(item.id);
            }}
          >
            <Stack direction="row">
              <img width={16} alt="star" src="/assets/star.svg" />
              <Typography
                align="center"
                fontSize={12}
                color="#7F7F7F"
                style={{
                  marginLeft: 4,
                }}
              >
                {item?.reviewsAverage?.toFixed(2)} ({item?.numberOfReviews})
              </Typography>
            </Stack>
            <ButtonBase
              style={{ display: "flex", justifyContent: "flex-start" }}
              onClick={() => {
                navigate(`/listing/${item?.id}`);
              }}
            >
              <Typography
                fontSize={16}
                fontFamily="AvertaStd-Bold"
                noWrap
                style={{
                  marginTop: 6,
                }}
              >
                {item?.title}
              </Typography>
            </ButtonBase>
            <Stack
              direction="row"
              style={{
                height: 24,
                width: 82,
                backgroundColor: "#D4D3EB",
                borderRadius: 6,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img alt="usd" src="/assets/usd.svg" />
              <Typography
                fontSize={11}
                color="#9490DB"
                style={{
                  marginLeft: 4,
                }}
              >
                Promotion
              </Typography>
            </Stack>
            <Stack
              direction="row"
              spacing={0.3}
              style={{
                marginTop: 10,
              }}
            >
              <img alt="subway" src="/assets/icons/subway.svg" />
              <img alt="utensils" src="/assets/icons/utensils.svg" />
            </Stack>
            <Stack
              direction="row"
              style={{
                marginTop: 10,
              }}
            >
              <Typography
                fontSize={18}
                fontFamily="AvertaStd-Bold"
                color="#231F20"
              >
                Starting at $
                {item?.minRent === undefined
                  ? item?.floorPlans[0]?.minRent
                  : item?.minRent}
              </Typography>
              <Typography
                fontSize={12}
                color="#949494"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                /month
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </div>
  );
};

export default ListingItem;
