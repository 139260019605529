import { Box, Link, Paper, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { appColors } from "../../../../theme";
import FlexBetween from "../../../../components/FlexBetween";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import UserFooter from "../../../../components/UserFooter";
import { useSnackbar } from "notistack";

const Welcome = () => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleFooterClick = () => {
    if (checked) {
      navigate("/yourlisting");
    } else {
      enqueueSnackbar("Please agree terms and conditions", {
        variant: "error",
      });
    }
  };

  return (
    <div>
      <Stack
        padding="1rem 6%"
        style={{
          marginTop: 38,
        }}
      >
        <Typography
          fontSize={32}
          fontFamily="AvertaStd-Bold"
          color={appColors.primaryTextColor}
        >
          Create a listing
        </Typography>
        <Typography color="#A0A0A0" fontSize={14}>
          All fields are required
        </Typography>
        <Paper
          style={{
            marginTop: 24,
            height: 70,
            borderRadius: 16,
          }}
        >
          <FlexBetween
            style={{
              height: 70,
              marginLeft: 24,
              marginRight: 24,
            }}
          >
            <Stack direction="row">
              <Box
                style={{
                  width: 32,
                  height: 32,
                  borderRadius: 16,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                sx={{
                  border: 1,
                  borderColor: "#9490DB",
                }}
              >
                <Typography
                  fontSize={16}
                  fontFamily="AvertaStd-semibold"
                  color="#9490DB"
                >
                  1
                </Typography>
              </Box>
              <Typography
                style={{
                  marginLeft: 16,
                }}
                fontSize={20}
                fontFamily="AvertaStd-semibold"
                color={appColors.primaryTextColor}
              >
                Create a listing
              </Typography>
            </Stack>
            <Link underline="none" component={RouterLink} to="">
              <Typography
                color="primary"
                fontSize={14}
                fontFamily="AvertaStd-semibold"
              >
                Learn more
              </Typography>
            </Link>
          </FlexBetween>
        </Paper>
        <Paper
          style={{
            marginTop: 24,
            height: 70,
            borderRadius: 16,
          }}
        >
          <FlexBetween
            style={{
              height: 70,
              marginLeft: 24,
              marginRight: 24,
            }}
          >
            <Stack direction="row">
              <Box
                style={{
                  width: 32,
                  height: 32,
                  borderRadius: 16,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                sx={{
                  border: 1,
                  borderColor: "#9490DB",
                }}
              >
                <Typography
                  fontSize={16}
                  fontFamily="AvertaStd-semibold"
                  color="#9490DB"
                >
                  2
                </Typography>
              </Box>
              <Typography
                style={{
                  marginLeft: 16,
                }}
                fontSize={20}
                fontFamily="AvertaStd-semibold"
                color={appColors.primaryTextColor}
              >
                Receive messages from potential tenants
              </Typography>
            </Stack>
            <Link underline="none" component={RouterLink} to="">
              <Typography
                color="primary"
                fontSize={14}
                fontFamily="AvertaStd-semibold"
              >
                Learn more
              </Typography>
            </Link>
          </FlexBetween>
        </Paper>
        <Paper
          style={{
            marginTop: 24,
            height: 70,
            borderRadius: 16,
          }}
        >
          <FlexBetween
            style={{
              height: 70,
              marginLeft: 24,
              marginRight: 24,
            }}
          >
            <Stack direction="row">
              <Box
                style={{
                  width: 32,
                  height: 32,
                  borderRadius: 16,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                sx={{
                  border: 1,
                  borderColor: "#9490DB",
                }}
              >
                <Typography
                  fontSize={16}
                  fontFamily="AvertaStd-semibold"
                  color="#9490DB"
                >
                  3
                </Typography>
              </Box>
              <Typography
                style={{
                  marginLeft: 16,
                }}
                fontSize={20}
                fontFamily="AvertaStd-semibold"
                color={appColors.primaryTextColor}
              >
                Confirm tenant
              </Typography>
            </Stack>
            <Link underline="none" component={RouterLink} to="">
              <Typography
                color="primary"
                fontSize={14}
                fontFamily="AvertaStd-semibold"
              >
                Learn more
              </Typography>
            </Link>
          </FlexBetween>
        </Paper>
        <Stack
          direction="row"
          style={{
            height: 20,
            marginTop: 36,
          }}
        >
          <Checkbox
            checked={checked}
            onChange={(event) => {
              setChecked(event.target.checked);
            }}
          />
          <Typography align="center" fontSize={16}>
            I have read and agree to the Froomie
          </Typography>
          <Link
            component={RouterLink}
            to=""
            style={{
              marginLeft: 2,
            }}
          >
            <Typography color="primary" fontSize={16}>
              Terms and conditions
            </Typography>
          </Link>
          <Typography
            align="center"
            fontSize={16}
            style={{
              marginLeft: 2,
              marginRight: 2,
            }}
          >
            and
          </Typography>
          <Link
            component={RouterLink}
            to=""
            style={{
              marginLeft: 2,
            }}
          >
            <Typography color="primary" fontSize={16}>
              Privacy policy.
            </Typography>
          </Link>
        </Stack>
      </Stack>
      <div
        style={{
          position: "absolute",
          bottom: "0px",
          left: 0,
          right: 0,
          textAlign: "center",
          marginLeft: 250,
        }}
      >
        <UserFooter buttonTitle="Next" handleClick={handleFooterClick} />
      </div>
    </div>
  );
};

export default Welcome;
