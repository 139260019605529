import { Grid, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "../../state/store";
import { getFavorites } from "../../state/slices/froomie";
import ListingItem from "../../components/ListingItem";

const Favorites = () => {
  const dispatch = useDispatch();
  const { favorites } = useSelector((state) => state.froomie);

  useEffect(() => {
    dispatch(getFavorites(100));
  }, [dispatch]);

  return (
    <div
      style={{
        backgroundColor: "white",
      }}
    >
      <Stack
        padding="1rem 6%"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 100,
        }}
      >
        <Typography
          style={{
            marginTop: 30,
          }}
          fontSize={36}
          fontFamily="AvertaStd-Bold"
        >
          My Favorites
        </Typography>
        <Grid
          container
          justifyContent="center"
          spacing={2}
          maxWidth={1200}
          style={{
            marginTop: 30,
          }}
        >
          {favorites.map((item) => {
            return (
              <Grid
                key={item.id}
                item
                style={{
                  marginBottom: 10,
                }}
              >
                <ListingItem item={item.housing} />
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </div>
  );
};

export default Favorites;
