import { useMemo } from "react";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";
import Home from "./pages/home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Layout from "./pages/layout";
import FindRoommates from "./pages/roommates";
import ListingDetails from "./pages/listingDetails";
import Landlords from "./pages/landlords";
import RoommateDetails from "./pages/roommateDetails";
import UserLayout from "./pages/userLayout";
import UserSignUp from "./pages/userSignUp/signUp";
import UserInfo from "./pages/userSignUp/userInfo.jsx";
import Questions from "./pages/userSignUp/questions";
import Activities from "./pages/userSignUp/activities";
import Major from "./pages/userSignUp/major";
import ProfileSignUp from "./pages/userSignUp/profileSignUp";
import ListingLayout from "./pages/listingLayout";
import Welcome from "./pages/home/createListing/welcome";
import Listing from "./pages/home/createListing/listing";
import Amentities from "./pages/home/createListing/amentities";
import Media from "./pages/home/createListing/media";
import Preview from "./pages/home/createListing/preview";
import SelectingLayout from "./pages/selectingLayout";
import PersonalInformation from "./pages/personalInformation";
import LeasingInformation from "./pages/leasingInformation";
import ConnectLandlords from "./pages/connectLandlords";
import SignIn from "./pages/signIn";
import Rentals from "./pages/rentals";
import useAuth from "./hooks/useAuth";
import ScrollToTop from "./components/ScrollToTop";
import DashboardLayout from "./pages/dashboard/dashboardLayout";
import Dashboard from "./pages/dashboard";
import MyProperties from "./pages/dashboard/myProperties";
import Messages from "./pages/dashboard/messages";
import HelpCenter from "./pages/dashboard/helpCenter";
import Favorites from "./pages/favorites";
import Message from "./pages/message";
import PrivacyPolicy from "./pages/privacyPolicy/index.jsx";
import Terms from "./pages/terms/index.jsx";

function App() {
  const { isInitialized } = useAuth();
  const mode = "light";
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return (
    <div className="app">
      <BrowserRouter>
        <ScrollToTop />
        <ThemeProvider theme={theme}>
          <CssBaseline>
            {isInitialized
              ? console.log("Initialized")
              : console.log("Loading")}
            <Routes>
              <Route element={<Layout />}>
                <Route path="/" element={<Home />} />
                <Route path="/roommates" element={<FindRoommates />} />
                <Route path="/listing/:id" element={<ListingDetails />} />
                <Route path="/landlords" element={<Landlords />} />
                <Route path="/roommate/:id" element={<RoommateDetails />} />
                <Route path="/rentals" element={<Rentals />} />
                <Route path="/favorites" element={<Favorites />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-conditions" element={<Terms />} />
              </Route>
              <Route element={<UserLayout />}>
                <Route path="/userSignUp" element={<UserSignUp />} />
                <Route path="/userInfo" element={<UserInfo />} />
                <Route path="/questions" element={<Questions />} />
                <Route path="/activities" element={<Activities />} />
                <Route path="/major" element={<Major />} />
                <Route path="/profileSignUp" element={<ProfileSignUp />} />
                <Route path="/signIn" element={<SignIn />} />
              </Route>
              <Route element={<ListingLayout />}>
                <Route path="/welcome" element={<Welcome />} />
                <Route path="/yourListing" element={<Listing />} />
                <Route path="/amentities" element={<Amentities />} />
                <Route path="/Media" element={<Media />} />
                <Route path="/preview" element={<Preview />} />
              </Route>
              <Route element={<SelectingLayout />}>
                <Route
                  path="/personalInformation"
                  element={<PersonalInformation />}
                />
                <Route
                  path="/leasingInformation"
                  element={<LeasingInformation />}
                />
                <Route
                  path="/connectWithLandlord"
                  element={<ConnectLandlords />}
                />
              </Route>
              <Route element={<DashboardLayout />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/myProperties" element={<MyProperties />} />
                <Route path="/messages" element={<Messages />} />
                <Route path="/helpCenter" element={<HelpCenter />} />
              </Route>
              <Route path="/message" element={<Message />} />
            </Routes>
          </CssBaseline>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
