import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UserFooter from "../../../components/UserFooter";
import {
  Box,
  ButtonBase,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { appColors } from "../../../theme";
import DragDropFiles from "../../../components/DragDropFiles";
import Popup from "../../../components/Popup";
import { useSnackbar } from "notistack";
import axiosInstance from "../../../utils/axios";
import useAuth from "../../../hooks/useAuth";
import { useDispatch, useSelector } from "../../../state/store";
import { getColleges } from "../../../state/slices/froomie";

const UserInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [files, setFiles] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [reTypePassword, setReTypePassword] = useState("");
  const { register } = useAuth();
  const { colleges } = useSelector((state) => state.froomie);
  const [university, setUniversity] = useState(location.state.school);
  const dispatch = useDispatch();

  const handleFooterClick = async () => {
    if (files === undefined) {
      enqueueSnackbar("Photo Required", { variant: "error" });
    } else if (firstName === "") {
      enqueueSnackbar("First Name Required", { variant: "error" });
    } else if (lastName === "") {
      enqueueSnackbar("Last Name Required", { variant: "error" });
    } else if (email === "") {
      enqueueSnackbar("Email Required", { variant: "error" });
    } else if (password === "") {
      enqueueSnackbar("Password Required", { variant: "error" });
    } else if (reTypePassword === "") {
      enqueueSnackbar("Retype Password Required", { variant: "error" });
    } else if (password !== reTypePassword) {
      enqueueSnackbar("Password not matched.", { variant: "error" });
    } else {
      const data = new FormData();
      data.append("photo", files);
      data.append("email", email);
      data.append("password", password);
      data.append("passwordConfirm", reTypePassword);
      data.append("name", `${firstName} ${lastName}`);
      data.append("school", location.state.school);
      data.append("graduationYear", location.state.year);
      data.append("type", location.state.type.toLowerCase());
      console.log(files);
      try {
        const options = {
          method: "POST",
          url: "/users/signup",
          data,
        };
        const response = await axiosInstance.request(options);
        if (!response.data) {
          console.log("400");
          return [400, { message: response.data.message }];
        }

        register(response.data);
        setOpenSuccessPopup(true);
      } catch (error) {
        enqueueSnackbar(error, { variant: "error" });
      }
    }
  };

  const continueClick = async () => {
    if (location.state.school !== university) {
      const data = new FormData();
      data.append("school", university);
      try {
        const options = {
          method: "PATCH",
          url: "/users/updateMe",
          data,
        };
        const response = await axiosInstance.request(options);
        if (response.data.status) {
          navigate("/questions");
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar("Server Error", { variant: "error" });
      }
    } else {
      navigate("/questions");
    }
  };

  useEffect(() => {
    dispatch(getColleges());
  }, [dispatch]);

  return (
    <>
      <Box
        sx={{
          height: "calc(100vh - 110px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          flexDirection="column"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: isNonMobile ? 0 : 20,
          }}
          sx={{
            flex: "1 1 auto",
          }}
        >
          <Stack
            style={{
              marginTop: 38,
              width: isNonMobile ? 750 : "100%",
            }}
          >
            <Typography
              fontSize={32}
              fontFamily="AvertaStd-Bold"
              color={appColors.primaryTextColor}
            >
              Create an account
            </Typography>
            <Typography fontSize={14} color="#A0A0A0">
              All fields are required
            </Typography>
            <Typography
              fontSize={16}
              fontFamily="AvertaStd-semibold"
              color={appColors.primaryTextColor}
              style={{
                marginTop: 32,
              }}
            >
              Profile picture
            </Typography>
            <DragDropFiles files={files} setFiles={setFiles} showFiles={true} />
            <Grid
              container
              style={{
                marginTop: 32,
              }}
            >
              <Grid item xs={6}>
                <Typography
                  fontSize={16}
                  fontFamily="AvertaStd-semibold"
                  color={appColors.primaryTextColor}
                >
                  First name
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  fontSize={16}
                  fontFamily="AvertaStd-semibold"
                  color={appColors.primaryTextColor}
                >
                  Last name
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                marginTop: 0,
              }}
            >
              <Grid item xs={6}>
                <Box
                  style={{
                    backgroundColor: "#EEEEEE",
                    height: 50,
                    marginRight: 16,
                    marginTop: 16,
                    borderRadius: 25,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    style={{
                      paddingLeft: 20,
                      paddingRight: 20,
                    }}
                    fullWidth
                    hiddenLabel
                    placeholder="First Name"
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    fontSize={14}
                    value={firstName}
                    color={appColors.primaryTextColor}
                    onChange={(event) => {
                      setFirstName(event.target.value);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  style={{
                    backgroundColor: "#EEEEEE",
                    height: 50,
                    marginRight: 16,
                    marginTop: 16,
                    borderRadius: 25,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    style={{
                      paddingLeft: 20,
                      paddingRight: 20,
                    }}
                    fullWidth
                    hiddenLabel
                    placeholder="Last Name"
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    fontSize={14}
                    color={appColors.primaryTextColor}
                    value={lastName}
                    onChange={(event) => {
                      setLastName(event.target.value);
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Typography
              fontSize={16}
              fontFamily="AvertaStd-semibold"
              color={appColors.primaryTextColor}
              style={{
                marginTop: 32,
              }}
            >
              University email
            </Typography>
            <Box
              style={{
                backgroundColor: "#EEEEEE",
                height: 50,
                marginRight: 16,
                marginTop: 16,
                borderRadius: 25,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                fullWidth
                hiddenLabel
                placeholder="Email Address"
                variant="standard"
                InputProps={{ disableUnderline: true }}
                fontSize={14}
                color={appColors.primaryTextColor}
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </Box>
            <Grid
              container
              style={{
                marginTop: 32,
              }}
            >
              <Grid item xs={6}>
                <Typography
                  fontSize={16}
                  fontFamily="AvertaStd-semibold"
                  color={appColors.primaryTextColor}
                >
                  Create Password
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  fontSize={16}
                  fontFamily="AvertaStd-semibold"
                  color={appColors.primaryTextColor}
                >
                  Re-type Password
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                marginTop: 0,
              }}
            >
              <Grid item xs={6}>
                <Box
                  style={{
                    backgroundColor: "#EEEEEE",
                    height: 50,
                    marginRight: 16,
                    marginTop: 16,
                    borderRadius: 25,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    style={{
                      paddingLeft: 20,
                      paddingRight: 20,
                    }}
                    fullWidth
                    hiddenLabel
                    placeholder="Password"
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    fontSize={14}
                    color={appColors.primaryTextColor}
                    type={"password"}
                    value={password}
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  style={{
                    backgroundColor: "#EEEEEE",
                    height: 50,
                    marginRight: 16,
                    marginTop: 16,
                    borderRadius: 25,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    style={{
                      paddingLeft: 20,
                      paddingRight: 20,
                    }}
                    fullWidth
                    hiddenLabel
                    id="standard-basic"
                    placeholder="Re-type Password"
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    fontSize={14}
                    color={appColors.primaryTextColor}
                    type={"password"}
                    value={reTypePassword}
                    onChange={(event) => {
                      setReTypePassword(event.target.value);
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Stack>
        </Box>
        <Box
          style={{
            width: "100%",
            textAlign: "center",
            marginTop: 30,
          }}
          sx={{
            flex: "0 1 auto",
          }}
        >
          <UserFooter
            buttonTitle={isNonMobile ? "Create account" : "Create"}
            handleClick={handleFooterClick}
          />
        </Box>
      </Box>
      <Popup
        title="Edit Color"
        openPopup={openSuccessPopup}
        setOpenPopup={setOpenSuccessPopup}
      >
        <div
          style={{
            width: isNonMobile ? 500 : "100%",
          }}
        >
          <Stack
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ButtonBase
              style={{
                width: 40,
                height: 40,
                position: "absolute",
                top: 16,
                right: 16,
              }}
              onClick={() => setOpenSuccessPopup(false)}
            >
              <img alt="x_circle" src="/assets/icons/fi_x-circle.svg" />
            </ButtonBase>
            <img
              width={140}
              height={140}
              alt="success"
              src="/assets/icons/approve.svg"
              style={{
                marginTop: 40,
              }}
            />
            <Typography
              style={{
                marginTop: 40,
              }}
              fontSize={24}
              fontFamily="AvertaStd-Bold"
              color={appColors.primaryTextColor}
            >
              Congratulations!
            </Typography>
            <Typography fontSize={14} color="#A0A0A0">
              Your account has been created
            </Typography>
            <Typography
              style={{
                marginTop: 40,
              }}
              fontSize={isNonMobile ? 16 : 13}
              fontFamily="AvertaStd-Bold"
              color={appColors.primaryTextColor}
            >
              Would you like to make a roommate profile?
            </Typography>
            <Select
              style={{
                width: isNonMobile ? 380 : "100%",
                height: 50,
                marginTop: 16,
                paddingRight: 10,
                fontSize: 14,
                fontFamily: "AvertaStd-semibold",
                color: appColors.primaryTextColor,
              }}
              sx={{
                border: 1,
                borderColor: "#9490DB",
                borderRadius: 30,
              }}
              displayEmpty
              value={university}
              onChange={(event) => {
                setUniversity(event.target.value);
              }}
              renderValue={
                university !== "" ? undefined : () => "Select a university..."
              }
            >
              {colleges.map((item, index) => {
                return (
                  <MenuItem value={item.name} key={index}>
                    <Typography>{item.name}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
            <ButtonBase
              style={{
                backgroundColor: "#9490DB",
                width: isNonMobile ? 400 : "100%",
                height: 50,
                marginTop: 27,
                borderRadius: 25,
                marginBottom: 20,
              }}
              onClick={continueClick}
            >
              <Typography
                fontSize={14}
                fontFamily="AvertaStd-semibold"
                color="#FDFDFD"
              >
                Continue
              </Typography>
            </ButtonBase>
            <ButtonBase
              style={{
                marginBottom: 10,
              }}
              onClick={() => {
                navigate("/");
              }}
            >
              <Typography
                color="#9490DB"
                fontSize={14}
                fontFamily="AvertaStd-Bold"
              >
                No thanks, just looking for housing
              </Typography>
            </ButtonBase>
          </Stack>
        </div>
      </Popup>
    </>
  );
};

export default UserInfo;
