import { Box, Stack, TextField, Typography } from "@mui/material";
import UserFooter from "../../../components/UserFooter";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { appColors } from "../../../theme";

const Activities = () => {
  const navigate = useNavigate();
  const [activity, setActivity] = useState("");
  const [hobbies, setHobbies] = useState("");
  const location = useLocation();

  // const suggested = ["Sports", "Student Government", "Green life"];
  // const suggestedHobbies = [
  //   "Partying",
  //   "Reading",
  //   "Shopping",
  //   "Self-care",
  //   "LGBTQ+",
  //   "Coffee",
  // ];

  const handleFooterClick = () => {
    const hobbiesArray = hobbies.split(",");
    const activitiesArray = activity.split(",");

    navigate("/major", {
      state: {
        living: location.state.living,
        neatFreak: location.state.neatFreak,
        nightOwl: location.state.nightOwl,
        relationship: location.state.relationship,
        weed: location.state.weed,
        temperature: location.state.temperature,
        activities: activitiesArray,
        hobbies: hobbiesArray,
      },
    });
  };

  return (
    <>
      <Box
        sx={{
          height: "calc(100vh - 110px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          flexDirection="column"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          sx={{
            flex: "1 1 auto",
          }}
        >
          <Stack
            style={{
              marginTop: 38,
              width: 350,
              marginBottom: 40,
            }}
          >
            <Typography
              fontSize={32}
              fontFamily="AvertaStd-Bold"
              color={appColors.primaryTextColor}
            >
              My roommate profile
            </Typography>
            <Typography fontSize={14} color="#A0A0A0">
              Let’s set up your roommate profile!
            </Typography>
            <Typography
              fontSize={16}
              fontFamily="AvertaStd-semibold"
              color={appColors.primaryTextColor}
              style={{
                marginTop: 32,
              }}
            >
              3. Activities or intended
            </Typography>
            <Box
              style={{
                backgroundColor: "#EEEEEE",
                height: 170,
                marginRight: 16,
                marginTop: 16,
                borderRadius: 16,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                fullWidth
                hiddenLabel
                id="standard-basic"
                placeholder="Type here"
                variant="standard"
                InputProps={{ disableUnderline: true }}
                fontSize={14}
                color={appColors.primaryTextColor}
                multiline
                maxRows={7}
                value={activity}
                onChange={(event) => {
                  setActivity(event.target.value);
                }}
                //inputRef={activityRef}
                // onKeyDown={(ev) => {
                //   if (ev.key === "Enter") {
                //     ev.preventDefault();
                //     setActivity([...activity, activityRef.current.value]);
                //     activityRef.current.value = "";
                //   }
                // }}
              />
            </Box>
            {/* <Grid
              container
              style={{
                marginTop: 16,
                maxWidth: 350,
              }}
            >
              {activity.map((item, index) => {
                return (
                  <Grid item key={index}>
                    <Box
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 21,
                        backgroundColor: "#9490DB",
                        height: 42,
                        marginTop: 10,
                        marginRight: 10,
                      }}
                    >
                      <Typography
                        fontSize={14}
                        fontFamily="AvertaStd-semibold"
                        color="white"
                        style={{
                          marginTop: 4,
                          marginBottom: 4,
                          marginLeft: 12,
                          marginRight: 12,
                        }}
                      >
                        {item}
                      </Typography>
                    </Box>
                  </Grid>
                );
              })}
            </Grid> */}
            <Typography
              fontSize={14}
              fontFamily="AvertaStd-semibold"
              color={appColors.primaryTextColor}
              style={{
                marginTop: 20,
              }}
            >
              Use comma separated string
            </Typography>
            {/* <Grid
              container
              style={{
                marginTop: 10,
                maxWidth: 350,
              }}
            >
              {suggested.map((item, index) => {
                return (
                  <Grid item key={index}>
                    <Box
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 21,
                        height: 42,
                        marginTop: 10,
                        marginRight: 10,
                      }}
                      sx={{
                        border: 1,
                        borderColor: "#E0E0E0",
                      }}
                    >
                      <Typography
                        fontSize={14}
                        fontFamily="AvertaStd-semibold"
                        color={appColors.primaryTextColor}
                        style={{
                          marginTop: 4,
                          marginBottom: 4,
                          marginLeft: 12,
                          marginRight: 12,
                        }}
                      >
                        {item}
                      </Typography>
                    </Box>
                  </Grid>
                );
              })}
            </Grid> */}
            <Typography
              fontSize={16}
              fontFamily="AvertaStd-semibold"
              color={appColors.primaryTextColor}
              style={{
                marginTop: 32,
              }}
            >
              Interests/Hobbies/Lifestyle
            </Typography>
            <Box
              style={{
                backgroundColor: "#EEEEEE",
                height: 170,
                marginRight: 16,
                marginTop: 16,
                borderRadius: 16,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                fullWidth
                hiddenLabel
                id="standard-basic"
                placeholder="Type here"
                variant="standard"
                InputProps={{ disableUnderline: true }}
                fontSize={14}
                color={appColors.primaryTextColor}
                multiline
                maxRows={7}
                value={hobbies}
                onChange={(event) => {
                  setHobbies(event.target.value);
                }}
                //inputRef={hobbiesRef}
                // onKeyDown={(ev) => {
                //   if (ev.key === "Enter") {
                //     ev.preventDefault();
                //     setHobbies([...hobbies, hobbiesRef.current.value]);
                //     hobbiesRef.current.value = "";
                //   }
                // }}
              />
            </Box>
            {/* <Grid
              container
              style={{
                marginTop: 16,
                maxWidth: 350,
              }}
            >
              {hobbies.map((item, index) => {
                return (
                  <Grid item key={index}>
                    <Box
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 21,
                        backgroundColor: "#9490DB",
                        height: 42,
                        marginTop: 10,
                        marginRight: 10,
                      }}
                    >
                      <Typography
                        fontSize={14}
                        fontFamily="AvertaStd-semibold"
                        color="white"
                        style={{
                          marginTop: 4,
                          marginBottom: 4,
                          marginLeft: 12,
                          marginRight: 12,
                        }}
                      >
                        {item}
                      </Typography>
                    </Box>
                  </Grid>
                );
              })}
            </Grid> */}
            <Typography
              fontSize={14}
              fontFamily="AvertaStd-semibold"
              color={appColors.primaryTextColor}
              style={{
                marginTop: 20,
              }}
            >
              Use comma separated string
            </Typography>
            {/* <Grid
              container
              style={{
                marginTop: 10,
                maxWidth: 350,
              }}
            >
              {suggestedHobbies.map((item, index) => {
                return (
                  <Grid item key={index}>
                    <Box
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 21,
                        height: 42,
                        marginTop: 10,
                        marginRight: 10,
                      }}
                      sx={{
                        border: 1,
                        borderColor: "#E0E0E0",
                      }}
                    >
                      <Typography
                        fontSize={14}
                        fontFamily="AvertaStd-semibold"
                        color={appColors.primaryTextColor}
                        style={{
                          marginTop: 4,
                          marginBottom: 4,
                          marginLeft: 12,
                          marginRight: 12,
                        }}
                      >
                        {item}
                      </Typography>
                    </Box>
                  </Grid>
                );
              })}
            </Grid> */}
          </Stack>
        </Box>
        <Box
          style={{
            width: "100%",
            textAlign: "center",
            marginTop: 30,
          }}
          sx={{
            flex: "0 1 auto",
          }}
        >
          <UserFooter buttonTitle="Continue" handleClick={handleFooterClick} />
        </Box>
      </Box>
    </>
  );
};

export default Activities;
