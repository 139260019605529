import {
  Box,
  ButtonBase,
  Card,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { appColors } from "../../theme";
import useWindowDimensions from "../../components/useWindowDimensions";
import UserFooter from "../../components/UserFooter";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "../../state/store";
import { getSchools, getSingleHousing } from "../../state/slices/froomie";
import { IMAGE_BASE_URL } from "../../utils/constants";

const PersonalInformation = () => {
  const { height, width } = useWindowDimensions();
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { schools } = useSelector((state) => state.froomie);
  const { housing } = useSelector((state) => state.froomie);
  const [university, setUniversity] = useState(
    "The University of Mississippi (Ole Miss)"
  );
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    dispatch(getSingleHousing(location.state.id));
    dispatch(getSchools());
  }, [dispatch, location]);

  const handleFooterClick = () => {
    navigate("/leasingInformation", {
      state: {
        firstName: firstName,
        lastName: lastName,
        email: email,
        university: university,
        housing: housing,
      },
    });
  };

  return (
    <div
      style={{
        background: "#F9F9F9",
      }}
    >
      <Box padding="1rem 6%">
        <Grid
          container
          sx={{
            height: {
              md: height - 240,
            },
          }}
        >
          <Grid
            xs={12}
            md={8}
            sx={{
              borderRight: isNonMobile ? 1 : 0,
              borderColor: "#C2C2C2",
            }}
          >
            <Stack>
              <Typography
                fontSize={32}
                fontFamily="AvertaStd-Bold"
                color={appColors.primaryTextColor}
              >
                Tell us about you
              </Typography>
              <Typography color="#A0A0A0" fontSize={14}>
                All fields are required
              </Typography>
              <Stack direction={isNonMobile ? "row" : "column"}>
                <Stack
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    fontSize={16}
                    fontFamily="AvertaStd-semibold"
                    color={appColors.primaryTextColor}
                    style={{
                      marginTop: 24,
                    }}
                  >
                    First Name
                  </Typography>
                  <Box
                    style={{
                      backgroundColor: "#EEEEEE",
                      height: 50,
                      marginRight: isNonMobile ? 16 : 0,
                      marginTop: 16,
                      borderRadius: 25,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                      }}
                      fullWidth
                      hiddenLabel
                      id="standard-basic"
                      placeholder="First Name"
                      variant="standard"
                      InputProps={{ disableUnderline: true }}
                      fontSize={14}
                      color={appColors.primaryTextColor}
                      value={firstName}
                      onChange={(event) => {
                        setFirstName(event.target.value);
                      }}
                    />
                  </Box>
                </Stack>
                <Stack
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    fontSize={16}
                    fontFamily="AvertaStd-semibold"
                    color={appColors.primaryTextColor}
                    style={{
                      marginTop: 24,
                    }}
                  >
                    Last Name
                  </Typography>
                  <Box
                    style={{
                      backgroundColor: "#EEEEEE",
                      height: 50,
                      marginRight: isNonMobile ? 16 : 0,
                      marginTop: 16,
                      borderRadius: 25,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                      }}
                      fullWidth
                      hiddenLabel
                      id="standard-basic"
                      placeholder="Last Name"
                      variant="standard"
                      InputProps={{ disableUnderline: true }}
                      fontSize={14}
                      color={appColors.primaryTextColor}
                      value={lastName}
                      onChange={(event) => {
                        setLastName(event.target.value);
                      }}
                    />
                  </Box>
                </Stack>
              </Stack>
              <Typography
                fontSize={16}
                fontFamily="AvertaStd-semibold"
                color={appColors.primaryTextColor}
                style={{
                  marginTop: 24,
                }}
              >
                Email address
              </Typography>
              <Box
                style={{
                  backgroundColor: "#EEEEEE",
                  height: 50,
                  marginRight: isNonMobile ? 16 : 0,
                  marginTop: 16,
                  borderRadius: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                  fullWidth
                  hiddenLabel
                  id="standard-basic"
                  placeholder="Email Address"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  fontSize={14}
                  color={appColors.primaryTextColor}
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                />
              </Box>
              <Typography
                fontSize={16}
                fontFamily="AvertaStd-semibold"
                color={appColors.primaryTextColor}
                style={{
                  marginTop: 24,
                }}
              >
                University
              </Typography>
              <Select
                style={{
                  marginTop: 16,
                  paddingRight: 10,
                  marginRight: isNonMobile ? 16 : 0,
                  fontSize: 14,
                  fontFamily: "AvertaStd-semibold",
                  color: appColors.primaryTextColor,
                }}
                sx={{
                  border: 1,
                  borderColor: "#9490DB",
                  borderRadius: 30,
                  width: {
                    md: "50%",
                  },
                }}
                displayEmpty
                defaultValue={university}
                onChange={(event) => {
                  setUniversity(event.target.value);
                }}
                renderValue={
                  university !== "" ? "" : () => "Select a university..."
                }
              >
                {schools.map((item, index) => {
                  return (
                    <MenuItem value={item} key={index}>
                      <Typography>{item}</Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </Stack>
          </Grid>
          <Grid xs={12} md={4}>
            <Card
              sx={{
                border: 1,
                borderColor: "#C2C2C2",
                borderRadius: 5,
                marginTop: isNonMobile ? 0 : 2,
                height: isNonMobile ? 350 : 400,
                marginLeft: isNonMobile ? 2 : 0,
                width: {
                  md: 280,
                },
              }}
            >
              <Stack>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    height: "100%",
                  }}
                >
                  <ButtonBase>
                    <img
                      style={{
                        objectFit: "cover",
                        width: isNonMobile ? 268 : width - 70,
                        height: 172,
                        margin: 7,
                        borderRadius: 12,
                      }}
                      alt="listing"
                      src={IMAGE_BASE_URL + housing?.photos[0]}
                      onClick={() => {
                        //navigate(`/listing/${item.id}`);
                      }}
                    />
                  </ButtonBase>
                  <ButtonBase
                    onClick={() => {
                      console.log("Love Clicked");
                    }}
                  >
                    <img
                      style={{
                        position: "absolute",
                        top: 12,
                        right: 12,
                      }}
                      alt="love"
                      src="/assets/love.svg"
                    />
                  </ButtonBase>
                </Box>
                <Stack
                  style={{
                    marginLeft: 12,
                    marginRight: 12,
                  }}
                  onClick={() => {
                    //console.log(item.id);
                  }}
                >
                  <Stack direction="row">
                    <img width={16} alt="star" src="/assets/star.svg" />
                    <Typography
                      align="center"
                      fontSize={12}
                      color="#7F7F7F"
                      style={{
                        marginLeft: 4,
                      }}
                    >
                      {housing?.reviewsAverage?.toFixed(2)} (
                      {housing?.numberOfReviews})
                    </Typography>
                  </Stack>
                  <Typography
                    fontSize={16}
                    fontFamily="AvertaStd-Bold"
                    noWrap
                    style={{
                      marginTop: 6,
                    }}
                  >
                    {housing?.title}
                  </Typography>
                  <Stack
                    direction="row"
                    style={{
                      height: 24,
                      width: 82,
                      backgroundColor: "#D4D3EB",
                      borderRadius: 6,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img alt="usd" src="/assets/usd.svg" />
                    <Typography
                      fontSize={11}
                      color="#9490DB"
                      style={{
                        marginLeft: 4,
                      }}
                    >
                      Promotion
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={0.3}
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <img alt="subway" src="/assets/icons/subway.svg" />
                    <img alt="utensils" src="/assets/icons/utensils.svg" />
                  </Stack>
                  <Stack
                    direction="row"
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <Typography
                      fontSize={18}
                      fontFamily="AvertaStd-Bold"
                      color="#231F20"
                    >
                      Starting at $
                      {housing?.minRent === undefined
                        ? housing?.floorPlans[0]?.minRent
                        : housing?.minRent}
                    </Typography>
                    <Typography
                      fontSize={12}
                      color="#949494"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      /month
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <div
        style={{
          textAlign: "center",
        }}
      >
        <UserFooter buttonTitle="Next" handleClick={handleFooterClick} />
      </div>
    </div>
  );
};

export default PersonalInformation;
