import {
  Box,
  ButtonBase,
  Divider,
  Grid,
  Paper,
  Slider,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
//import useWindowDimensions from "../../components/useWindowDimensions";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FlexBetween from "../../components/FlexBetween";

const Landlords = () => {
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  //const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const [room, setRoom] = useState(0);
  const [rent, setRent] = useState(0);
  const [saving, setSaving] = useState(0);

  const calculateSavings = () => {
    const totalRent = room * rent;
    var result = 0.75 * totalRent;
    setSaving(result);
  };

  return (
    <div
      style={{
        backgroundColor: "white",
      }}
    >
      <Stack padding="1rem 6%">
        <Box
          style={{
            marginBottom: 24,
            height: 600,
            position: "relative",
            backgroundColor: "white",
          }}
        >
          <img
            width="100%"
            height="100%"
            alt="cover"
            src="/assets/cover.svg"
            style={{
              objectFit: "cover",
              marginTop: 20,
              borderRadius: 30,
            }}
          />
          <Stack
            style={{
              position: "absolute",
              left: 0,
              bottom: 0,
              display: "flex",
              justifyContent: "left",
              alignItems: "left",
            }}
          >
            <Box
              style={{
                marginLeft: isNonMobile ? 100 : 20,
                display: "flex",
                justifyContent: "left",
                alignItems: "left",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                sx={{
                  backgroundColor: "white",
                  borderRadius: 10,
                }}
              >
                <Typography
                  fontSize={14}
                  fontFamily="AvertaStd-semibold"
                  color="primary"
                  style={{
                    marginLeft: 16,
                    marginRight: 16,
                    marginTop: 8,
                    marginBottom: 8,
                  }}
                >
                  FOR LANDLORDS
                </Typography>
              </Box>
            </Box>
            <Typography
              style={{
                color: "black",
                marginLeft: isNonMobile ? 100 : 20,
                marginBottom: -20,
                marginTop: 12,
              }}
              fontSize={isNonMobile ? 64 : 32}
              fontFamily="AvertaStd-Bold"
            >
              Find student renters
            </Typography>
            <Typography
              style={{
                color: "black",
                marginLeft: isNonMobile ? 100 : 20,
              }}
              fontSize={isNonMobile ? 64 : 32}
              fontFamily="AvertaStd-Bold"
            >
              for your property
            </Typography>
            <Typography
              style={{
                color: "black",
                borderRadius: 25,
                marginLeft: isNonMobile ? 100 : 20,
                marginBottom: 45,
              }}
            >
              Join to earn more rental income and increase the occupancy rate
              for your property.
            </Typography>
            <Stack
              direction={isNonMobile ? "row" : "column"}
              style={{
                marginLeft: isNonMobile ? 100 : 20,
                marginBottom: 80,
              }}
            >
              <ButtonBase
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "left",
                  width: 180,
                  height: 48,
                  borderRadius: 100,
                }}
                onClick={() => {
                  navigate("/welcome");
                }}
              >
                <Box
                  style={{
                    width: 180,
                    height: 48,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  sx={{
                    backgroundColor: "#9490DB",
                    borderRadius: 10,
                  }}
                >
                  <Typography
                    fontSize={14}
                    fontFamily="AvertaStd-semibold"
                    color="white"
                  >
                    Create free listing
                  </Typography>
                </Box>
              </ButtonBase>
              <Link to={"https://apps.apple.com/us/app/froomie/id1570687219"}>
                <ButtonBase>
                  <img
                    style={{
                      marginLeft: isNonMobile ? 24 : 0,
                      marginTop: isNonMobile ? 0 : 20,
                    }}
                    width={175}
                    height={52}
                    alt="appstore"
                    src="/assets/appstore.svg"
                  />
                </ButtonBase>
              </Link>
            </Stack>
          </Stack>
        </Box>
        <Typography
          style={{
            marginTop: 90,
          }}
          align="center"
          fontSize={24}
          fontFamily="AvertaStd-semibold"
        >
          As seen in
        </Typography>
        <Stack
          align="center"
          direction={isNonMobile ? "row" : "column"}
          spacing={6}
          style={{
            marginTop: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img alt="google" src="/assets/brand/usNews.svg" />
          <img alt="google" src="/assets/brand/superTalk.svg" />
          <img alt="google" src="/assets/brand/oxfordEagle.svg" />
          <img alt="google" src="/assets/brand/dallasInnovates.svg" />
        </Stack>
        <Stack
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 120,
          }}
        >
          <Box
            style={{
              width: 150,
              height: 35,
              borderRadius: 27,
              backgroundColor: "#EBEAFA",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              color="#9490DB"
              fontSize={14}
              fontFamily="AvertaStd-Bold"
            >
              HOW IT WORKS
            </Typography>
          </Box>
          <Typography
            style={{
              marginTop: 20,
            }}
            fontSize={20}
            fontFamily="AvertaStd-semibold"
          >
            Here is how it works for landlords:
          </Typography>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Stack
                spacing={4}
                style={{
                  marginTop: 40,
                  marginLeft: isNonMobile ? 60 : 0,
                  marginRight: isNonMobile ? 60 : 0,
                }}
              >
                <Stack direction="row" spacing={2}>
                  <Box
                    sx={{
                      width: "60px",
                      height: "60px",
                      backgroundColor: "#9490DB",
                      borderRadius: 30,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      color="white"
                      fontSize={32}
                      fontFamily="AvertaStd-Bold"
                    >
                      1
                    </Typography>
                  </Box>
                  <Stack>
                    <Typography
                      color="black"
                      fontSize={32}
                      fontFamily="AvertaStd-Bold"
                    >
                      List your property
                    </Typography>
                    <Typography fontSize={20} color="black">
                      Create an account and list your rental home or apartment
                      complex for free.
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Box
                    sx={{
                      width: "60px",
                      height: "60px",
                      backgroundColor: "#9490DB",
                      borderRadius: 30,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      color="white"
                      fontSize={32}
                      fontFamily="AvertaStd-Bold"
                    >
                      2
                    </Typography>
                  </Box>
                  <Stack>
                    <Typography
                      color="black"
                      fontSize={32}
                      fontFamily="AvertaStd-Bold"
                    >
                      Get approved
                    </Typography>
                    <Typography fontSize={20} color="black">
                      Once your listings are up, you can get contacted by
                      potential tenants.
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Box
                    sx={{
                      width: "60px",
                      height: "60px",
                      backgroundColor: "#9490DB",
                      borderRadius: 30,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      color="white"
                      fontSize={32}
                      fontFamily="AvertaStd-Bold"
                    >
                      3
                    </Typography>
                  </Box>
                  <Stack>
                    <Typography
                      color="black"
                      fontSize={32}
                      fontFamily="AvertaStd-Bold"
                    >
                      Start earning
                    </Typography>
                    <Typography fontSize={20} color="black">
                      Once you find a tenant and the lease is confirmed, we will
                      invoice you 25% of the first month's rent.
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Box
                    sx={{
                      width: "60px",
                      height: "60px",
                      backgroundColor: "#9490DB",
                      borderRadius: 30,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      color="white"
                      fontSize={32}
                      fontFamily="AvertaStd-Bold"
                    >
                      4
                    </Typography>
                  </Box>
                  <Stack>
                    <Typography
                      color="black"
                      fontSize={32}
                      fontFamily="AvertaStd-Bold"
                    >
                      Manage your listings
                    </Typography>
                    <Typography fontSize={20} color="black">
                      Monitor incoming messages from potential tenants from our
                      platform. (or we can for you!)
                    </Typography>
                    <ButtonBase
                      style={{
                        marginTop: 32,
                        width: 200,
                        borderRadius: 100,
                      }}
                      onClick={() => {
                        navigate("/userSignUp");
                      }}
                    >
                      <Box
                        style={{
                          width: 200,
                          height: 42,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        sx={{
                          border: 1,
                          borderColor: "#9490DB",
                          borderRadius: 10,
                        }}
                      >
                        <Typography
                          fontSize={14}
                          fontFamily="AvertaStd-semibold"
                          color="primary"
                        >
                          Create an account today
                        </Typography>
                      </Box>
                    </ButtonBase>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <img
                width={isNonMobile ? 550 : 350}
                height={isNonMobile ? 550 : 350}
                style={{
                  paddingTop: 100,
                  paddingLeft: isNonMobile ? 50 : 0,
                }}
                alt="building1"
                src="/assets/building1.svg"
              />
            </Grid>
          </Grid>
        </Stack>
        <Stack
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 120,
          }}
        >
          <Typography fontSize={36} fontFamily="AvertaStd-Bold">
            What makes us different
          </Typography>
          <Stack
            style={{
              marginTop: 40,
            }}
          >
            <Stack direction={isNonMobile ? "row" : "column"} spacing={8}>
              <Stack
                style={{
                  width: 210,
                }}
              >
                <img
                  width={195}
                  height={195}
                  alt="findPlace"
                  src="/assets/different1.svg"
                />
                <Typography
                  style={{
                    marginTop: 30,
                  }}
                  fontSize={22}
                  fontFamily="AvertaStd-Bold"
                >
                  Free Listings
                </Typography>
                <Typography
                  style={{
                    marginTop: 10,
                  }}
                  fontSize={14}
                >
                  List completely for free! Only pay when we find you a tenant
                  and you are satisfied with our service.
                </Typography>
              </Stack>
              <Stack
                style={{
                  width: 240,
                }}
              >
                <img
                  width={195}
                  height={195}
                  alt="findroommate"
                  src="/assets/different2.svg"
                />
                <Typography
                  style={{
                    marginTop: 30,
                  }}
                  fontSize={22}
                  fontFamily="AvertaStd-Bold"
                >
                  Roommate Matching
                </Typography>
                <Typography
                  style={{
                    marginTop: 10,
                  }}
                  fontSize={14}
                >
                  We offer complimentary, free roommate matching for all
                  students.
                </Typography>
              </Stack>
              <Stack
                style={{
                  width: 240,
                }}
              >
                <img
                  width={195}
                  height={195}
                  alt="connectLandlords"
                  src="/assets/different3.svg"
                />
                <Typography
                  style={{
                    marginTop: 30,
                  }}
                  fontSize={22}
                  fontFamily="AvertaStd-Bold"
                >
                  Founded by Students
                </Typography>
                <Typography
                  style={{
                    marginTop: 10,
                  }}
                  fontSize={14}
                >
                  Froomie was founded by students who set out to improve the
                  leasing process for both parties.
                </Typography>
              </Stack>
              <Stack
                style={{
                  width: 220,
                }}
              >
                <img
                  width={195}
                  height={195}
                  alt="signLease"
                  src="/assets/different4.svg"
                />
                <Typography
                  style={{
                    marginTop: 30,
                  }}
                  fontSize={22}
                  fontFamily="AvertaStd-Bold"
                >
                  Budget-Friendly
                </Typography>
                <Typography
                  style={{
                    marginTop: 10,
                  }}
                  fontSize={14}
                >
                  On average, property managers save up to 75% when it comes to
                  finding tenants.
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 100,
          }}
        >
          <Typography
            style={{
              marginTop: 30,
            }}
            fontSize={36}
            fontFamily="AvertaStd-Bold"
          >
            Pricing Calculator
          </Typography>
          <Paper
            style={{
              borderRadius: 30,
              marginTop: 40,
              width: "100%",
              height: isNonMobile ? 550 : 1200,
            }}
            sx={{
              border: 1,
              borderColor: "#E0E0E0",
            }}
          >
            <Stack>
              <Grid
                container
                style={{
                  height: "100%",
                  margin: isNonMobile ? 0 : 12,
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={5}
                  style={{
                    display: isNonMobile ? "flex" : "block",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: isNonMobile ? 0 : 30,
                  }}
                >
                  <Stack
                    style={{
                      marginLeft: isNonMobile ? 62 : 5,
                      marginRight: isNonMobile ? 0 : 20,
                    }}
                  >
                    <Typography fontSize={36} fontFamily="AvertaStd-Bold">
                      Savings Calculator
                    </Typography>
                    <Typography fontSize={14}>
                      We only make money when you do. List your property for
                      free, and when you find a tenant, we charge 25% of the
                      first month's rent for the lease. Froomie offers cost
                      effective solutions for landlords, use our calculator
                      below to see how switching to Froomie can increase your
                      annual revenue.
                    </Typography>
                    <FlexBetween
                      style={{
                        marginTop: 56,
                      }}
                    >
                      <Typography
                        color="#000000"
                        fontSize={16}
                        fontFamily="AvertaStd-semibold"
                      >
                        Select rooms
                      </Typography>
                      <Typography
                        color="#9490DB"
                        fontSize={20}
                        fontFamily="AvertaStd-Bold"
                      >
                        {room}
                      </Typography>
                    </FlexBetween>
                    <Slider
                      min={0}
                      max={20}
                      onChange={(event) => {
                        setRoom(event.target.value);
                        calculateSavings();
                      }}
                    />
                    <FlexBetween>
                      <Typography fontSize={14}>01</Typography>
                      <Typography fontSize={14}>20</Typography>
                    </FlexBetween>
                    <Divider
                      style={{
                        marginTop: 15,
                      }}
                    />
                    <FlexBetween
                      style={{
                        marginTop: 15,
                      }}
                    >
                      <Typography
                        color="#000000"
                        fontSize={16}
                        fontFamily="AvertaStd-semibold"
                      >
                        Monthly rent
                      </Typography>
                      <Typography
                        color="#9490DB"
                        fontSize={20}
                        fontFamily="AvertaStd-Bold"
                      >
                        ${rent}
                      </Typography>
                    </FlexBetween>
                    <Slider
                      min={500}
                      max={20000}
                      onChange={(event) => {
                        setRent(event.target.value);
                        calculateSavings();
                      }}
                    />
                    <FlexBetween>
                      <Typography fontSize={14}>$500</Typography>
                      <Typography fontSize={14}>$20000</Typography>
                    </FlexBetween>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Stack
                    style={{
                      maxWidth: isNonMobile ? 450 : 300,
                      marginTop: 20,
                      marginLeft: isNonMobile ? 200 : 5,
                    }}
                  >
                    <FlexBetween>
                      <Typography></Typography>
                      <img
                        width={isNonMobile ? 124 : 94}
                        height={isNonMobile ? 33 : 24}
                        alt="logo"
                        src="/assets/logo.svg"
                      />
                      <Typography fontSize={16} fontFamily="AvertaStd-semibold">
                        Agent*
                      </Typography>
                    </FlexBetween>
                    <Divider
                      style={{
                        marginTop: 24,
                      }}
                    />
                    <FlexBetween
                      style={{
                        marginTop: 24,
                      }}
                    >
                      <Typography fontSize={16} fontFamily="AvertaStd-semibold">
                        First months rent
                      </Typography>
                      <Typography fontSize={16} fontFamily="AvertaStd-semibold">
                        25%
                      </Typography>
                      <Typography fontSize={16} fontFamily="AvertaStd-semibold">
                        100%
                      </Typography>
                    </FlexBetween>
                    <Divider
                      style={{
                        marginTop: 24,
                      }}
                    />
                    <FlexBetween
                      style={{
                        marginTop: 24,
                      }}
                    >
                      <Typography fontSize={16} fontFamily="AvertaStd-semibold">
                        Verified students
                      </Typography>
                      <img
                        style={{
                          marginLeft: 8,
                        }}
                        alt="check1"
                        src="/assets/u_check.svg"
                      />
                      <img alt="check1" src="/assets/u_minus.svg" />
                    </FlexBetween>
                    <Divider
                      style={{
                        marginTop: 24,
                      }}
                    />
                    <FlexBetween
                      style={{
                        marginTop: 24,
                      }}
                    >
                      <Typography fontSize={16} fontFamily="AvertaStd-semibold">
                        Mobile & web outrech
                      </Typography>
                      <img
                        style={{
                          marginRight: 24,
                        }}
                        alt="check1"
                        src="/assets/u_check.svg"
                      />
                      <img alt="check1" src="/assets/u_minus.svg" />
                    </FlexBetween>
                    <Divider
                      style={{
                        marginTop: 24,
                      }}
                    />
                    <FlexBetween
                      style={{
                        marginTop: 24,
                      }}
                    >
                      <Typography fontSize={16} fontFamily="AvertaStd-semibold">
                        Direct student info
                      </Typography>
                      <img alt="check1" src="/assets/u_check.svg" />
                      <img alt="check1" src="/assets/u_minus.svg" />
                    </FlexBetween>
                    <Divider
                      style={{
                        marginTop: 24,
                      }}
                    />
                    <FlexBetween
                      style={{
                        marginTop: 24,
                      }}
                    >
                      <Typography fontSize={16} fontFamily="AvertaStd-semibold">
                        Save with us
                      </Typography>
                      <Typography fontSize={16} fontFamily="AvertaStd-semibold">
                        ${saving}
                      </Typography>
                      <Typography />
                    </FlexBetween>
                  </Stack>
                </Grid>
              </Grid>
              <Typography
                align="center"
                fontSize={14}
                style={{
                  marginTop: 36,
                  marginRight: isNonMobile ? 0 : 16,
                }}
              >
                We only make money when you do. List your property for free, and
                when you find a tenant, we charge a 25% service fee on the first
                month's rent value amount.
              </Typography>
            </Stack>
          </Paper>
        </Stack>
        <Box
          style={{
            marginTop: 80,
            marginBottom: 40,
            height: 450,
            borderRadius: 30,
          }}
          backgroundColor="#EFEDF7"
        >
          <Grid container>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Stack
                style={{
                  marginLeft: isNonMobile ? 0 : 100,
                  marginTop: isNonMobile ? 100 : 80,
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "left",
                  }}
                >
                  <Box
                    style={{
                      width: 230,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: 10,
                    }}
                  >
                    <Typography
                      fontSize={14}
                      fontFamily="AvertaStd-semibold"
                      color="primary"
                      style={{
                        marginLeft: 16,
                        marginRight: 16,
                        marginTop: 8,
                        marginBottom: 8,
                      }}
                    >
                      LOOKING FOR SOME HELP?
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  fontSize={isNonMobile ? 48 : 24}
                  fontFamily="AvertaStd-Bold"
                  style={{
                    marginTop: 20,
                  }}
                >
                  Reach out to our team
                </Typography>
                <Typography fontSize={16}>
                  Have questions or want help creating your account or listing?
                </Typography>
                <ButtonBase
                  style={{
                    marginTop: 26,
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "left",
                  }}
                >
                  <Box
                    style={{
                      width: 160,
                      height: 48,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    sx={{
                      borderRadius: 10,
                      border: 1,
                      borderColor: "#9490DB",
                    }}
                  >
                    <Typography
                      fontSize={14}
                      fontFamily="AvertaStd-semibold"
                      color="#9490DB"
                    >
                      Contact support
                    </Typography>
                  </Box>
                </ButtonBase>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <img
                style={{
                  height: 450,
                  width: "100%",
                  marginTop: isNonMobile ? 70 : 180,
                }}
                alt="contactUs"
                src="/assets/ContactUs.svg"
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </div>
  );
};

export default Landlords;
