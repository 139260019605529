import {
  Avatar,
  Box,
  ButtonBase,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useMemo } from "react";
import { appColors } from "../../../../theme";
import UserFooter from "../../../../components/UserFooter";
import { useLocation, useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import FlexBetween from "../../../../components/FlexBetween";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import useAuth from "../../../../hooks/useAuth";
import axiosInstance from "../../../../utils/axios";
import { useSnackbar } from "notistack";
import { MAP_KEY } from "../../../../utils/constants";

//const APIkey = "39507be4ad974c11865f463d8d6e4e73";

const Preview = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isNonMobileScreens = useMediaQuery("(min-width: 600px)");
  const { user } = useAuth();

  //const items = [1, 2, 3];

  const handleFooterClick = async () => {
    const data = new FormData();
    Array.from(location.state.propertyPicture).forEach((item) => {
      data.append("photos", item);
    });
    data.append("title", location.state.apartment);
    data.append("description", location.state.description);
    data.append("promotions", location.state.displayName);
    data.append("lat", location.state.lat);
    data.append("lng", location.state.lon);
    data.append("address", location.state.address);
    data.append("school", location.state.university);
    data.append("videoUrl", location.state.videoUrl);
    data.append("managerName", location.state.managerName);
    data.append("floorCount", location.state.floorCount);
    data.append("type", location.state.type);
    data.append("lease", location.state.lease);
    data.append("promotionStartDate", location.state.startDate);
    data.append("promotionEndDate", location.state.endDate);

    location.state.fav.map((item) => data.append("favorite", item));

    location.state.security.map((item) => data.append("security", item));

    location.state.facility.map((item) => data.append("facility", item));

    try {
      const options = {
        method: "POST",
        url: "/housing/createHousing",
        data,
      };
      const response = await axiosInstance.request(options);
      if (response.data.status) {
        createFloorPlan(response.data.data.housing.id);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Server Error", { variant: "error" });
    }
  };

  const createFloorPlan = (id) => {
    location.state.data.map((item, index) => {
      const data = new FormData();
      Array.from(item.floorPhotos).forEach((photo) => {
        data.append("floorplanimage", photo);
      });
      data.append("housing", id);
      data.append("bedrooms", item.bedRoomCount);
      data.append("bathrooms", item.bathRoomCount);
      data.append("minRent", item.minRent);
      data.append("maxRent", item.maxRent);

      var isLast = false;
      if (index === location.state.data.length - 1) {
        isLast = true;
      }

      floorPlanApiCall(data, id, isLast);
    });
  };

  const floorPlanApiCall = async (data, id, isLast) => {
    try {
      const options = {
        method: "POST",
        url: "housing/createFloorplanForHousing",
        data,
      };
      const response = await axiosInstance.request(options);
      if (response.data.status) {
        if (isLast) {
          navigate(`/listing/${id}`);
        }
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Server Error", { variant: "error" });
    }
  };

  function get_youtube_thumbnail(url, quality) {
    if (url) {
      var video_id, thumbnail, result;
      if ((result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/))) {
        video_id = result.pop();
      } else if ((result = url.match(/youtu.be\/(.{11})/))) {
        video_id = result.pop();
      }

      if (video_id) {
        if (typeof quality == "undefined") {
          quality = "high";
        }

        var quality_key = "maxresdefault"; // Max quality
        if (quality === "low") {
          quality_key = "sddefault";
        } else if (quality === "medium") {
          quality_key = "mqdefault";
        } else if (quality === "high") {
          quality_key = "hqdefault";
        }

        thumbnail =
          "http://img.youtube.com/vi/" + video_id + "/" + quality_key + ".jpg";
        return thumbnail;
      }
    }
    return false;
  }

  //const [currentLocation, setCurrentLocation] = useState();

  // function getLocationInfo(latitude, longitude) {
  //   const url = `https://api.opencagedata.com/geocode/v1/json?q=${latitude},${longitude}&key=${APIkey}`;
  //   fetch(url)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log(data);
  //       if (data.status.code === 200) {
  //         console.log("results:", data.results);
  //         setCurrentLocation(data.results[0].formatted);
  //       } else {
  //         console.log("Reverse geolocation request failed.");
  //       }
  //     })
  //     .catch((error) => console.error(error));
  // }

  // var options = {
  //   enableHighAccuracy: true,
  //   timeout: 5000,
  //   maximumAge: 0,
  // };

  // function success(pos) {
  //   var crd = pos.coords;
  //   console.log("Your current position is:");
  //   console.log(`Latitude : ${crd.latitude}`);
  //   console.log(`Longitude: ${crd.longitude}`);
  //   console.log(`More or less ${crd.accuracy} meters.`);

  //   getLocationInfo(crd.latitude, crd.longitude);
  // }

  // function errors(err) {
  //   console.warn(`ERROR(${err.code}): ${err.message}`);
  // }

  //
  // GOOGLE MAPS
  //

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: MAP_KEY,
  });

  const center = useMemo(
    () => ({ lat: location.state?.lat, lng: location.state?.lon }),
    [location.state?.lat, location.state?.lon]
  );

  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.permissions
  //       .query({ name: "geolocation" })
  //       .then(function (result) {
  //         console.log(result);
  //         if (result.state === "granted") {
  //           //If granted then you can directly call your function here
  //           navigator.geolocation.getCurrentPosition(success, errors, options);
  //         } else if (result.state === "prompt") {
  //           //If prompt then the user will be asked to give permission
  //           navigator.geolocation.getCurrentPosition(success, errors, options);
  //         } else if (result.state === "denied") {
  //           //If denied then you have to show instructions to enable location
  //         }
  //       });
  //   } else {
  //     console.log("Geolocation is not supported by this browser.");
  //   }
  // }, []);

  return (
    <div
      style={{
        background: "#F9F9F9",
      }}
    >
      <Stack padding="1rem 6%">
        <Typography
          fontSize={32}
          fontFamily="AvertaStd-Bold"
          color={appColors.primaryTextColor}
        >
          Preview
        </Typography>
        <Typography color="#A0A0A0" fontSize={14}>
          All fields are required
        </Typography>
        <Grid
          container
          spacing={isNonMobileScreens ? 5 : 2}
          style={{
            height: {
              md: 650,
            },
            marginTop: 20,
          }}
        >
          <Grid item xs={12} md={5}>
            <Box
              style={{
                width: "100%",
              }}
              sx={{
                height: {
                  xs: 300,
                  md: 650,
                },
              }}
            >
              <img
                style={{
                  objectFit: "cover",
                  borderTopLeftRadius: 15,
                  borderBottomLeftRadius: isNonMobileScreens ? 15 : 0,
                  borderTopRightRadius: isNonMobileScreens ? 0 : 15,
                  width: "100%",
                  height: "100%",
                }}
                alt={location.state?.propertyPicture[0]?.name}
                src={
                  location.state?.propertyPicture[0] === undefined
                    ? ""
                    : URL.createObjectURL(location.state?.propertyPicture[0])
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box
              style={{
                width: "100%",
              }}
            >
              <Grid
                container
                style={{
                  height: "100%",
                  width: "100%",
                }}
              >
                <Grid
                  item
                  xs={6}
                  style={{
                    paddingRight: isNonMobileScreens ? 20 : 5,
                  }}
                >
                  <Box
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <Grid
                      container
                      direction="column"
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingBottom: 20,
                        }}
                      >
                        <Box
                          style={{
                            height: isNonMobileScreens ? 305 : 152,
                            width: "100%",
                          }}
                        >
                          <img
                            style={{
                              objectFit: "cover",
                              width: "100%",
                              height: "100%",
                            }}
                            alt={location.state?.propertyPicture[1]?.name}
                            src={
                              location.state?.propertyPicture[1] === undefined
                                ? ""
                                : URL.createObjectURL(
                                    location.state?.propertyPicture[1]
                                  )
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingTop: isNonMobileScreens ? 20 : 0,
                        }}
                      >
                        <Box
                          style={{
                            height: isNonMobileScreens ? 305 : 152,
                            width: "100%",
                            marginBottom: isNonMobileScreens ? 0 : 20,
                          }}
                        >
                          <img
                            style={{
                              objectFit: "cover",
                              width: "100%",
                              height: "100%",
                              borderBottomLeftRadius: isNonMobileScreens
                                ? 0
                                : 15,
                            }}
                            alt={location.state?.propertyPicture[2]?.name}
                            src={
                              location.state?.propertyPicture[2] === undefined
                                ? ""
                                : URL.createObjectURL(
                                    location.state?.propertyPicture[2]
                                  )
                            }
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    paddingLeft: 20,
                  }}
                >
                  <Box
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <Grid
                      container
                      direction="column"
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingBottom: 20,
                        }}
                      >
                        <Box
                          style={{
                            height: isNonMobileScreens ? 305 : 152,
                            width: "100%",
                          }}
                        >
                          <img
                            style={{
                              objectFit: "cover",
                              borderTopRightRadius: isNonMobileScreens ? 15 : 0,
                              width: "100%",
                              height: "100%",
                            }}
                            alt={location.state?.propertyPicture[3]?.name}
                            src={
                              location.state?.propertyPicture[3] === undefined
                                ? ""
                                : URL.createObjectURL(
                                    location.state?.propertyPicture[3]
                                  )
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingTop: isNonMobileScreens ? 20 : 0,
                        }}
                      >
                        <Box
                          style={{
                            height: isNonMobileScreens ? 305 : 152,
                            width: "100%",
                          }}
                        >
                          <img
                            style={{
                              objectFit: "cover",
                              borderBottomRightRadius: 15,
                              width: "100%",
                              height: "100%",
                            }}
                            alt={location.state?.propertyPicture[4]?.name}
                            src={
                              location.state?.propertyPicture[4] === undefined
                                ? ""
                                : URL.createObjectURL(
                                    location.state?.propertyPicture[4]
                                  )
                            }
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={20}
          style={{
            marginTop: 44,
          }}
        >
          <Grid item xs={12} md={7}>
            <Stack spacing={1}>
              {/* <Stack direction="row">
                <img width={16} alt="star" src="/assets/star.svg" />
                <Typography
                  align="center"
                  fontSize={16}
                  fontFamily="AvertaStd-semibold"
                  color="#231F20"
                  style={{
                    marginLeft: 4,
                  }}
                >
                  4.5 (58 Reviews)
                </Typography>
              </Stack> */}
              <Typography
                fontSize={32}
                fontFamily="AvertaStd-Bold"
                color="#231F20"
              >
                {location.state?.apartment}
              </Typography>
              <Typography fontSize={20} color="#4E4547">
                {location.state?.address}
              </Typography>
              <Divider color="#E0E0E0" />
              <Typography
                style={{
                  marginTop: 32,
                }}
                fontSize={16}
                color="#4E4547"
              >
                {location.state?.description}
              </Typography>
              <Link underline="none" component={RouterLink} to="">
                <Typography
                  color="primary"
                  fontSize={14}
                  fontFamily="AvertaStd-semibold"
                  style={{
                    marginTop: 20,
                  }}
                >
                  Learn more
                </Typography>
              </Link>
              <Divider
                color="#E0E0E0"
                style={{
                  marginTop: 32,
                }}
              />
              <Typography
                fontSize={20}
                fontFamily="AvertaStd-Bold"
                color={appColors.primaryTextColor}
                style={{
                  marginTop: 32,
                }}
              >
                Location
              </Typography>
              <Stack direction="row">
                <img
                  alt="location"
                  src="/assets/icons/u_location-pin-alt.svg"
                />
                <Typography
                  fontSize={14}
                  color={appColors.primaryTextColor}
                  style={{
                    marginLeft: 4,
                  }}
                >
                  3 Mi from campus
                </Typography>
              </Stack>
              <Box
                style={{
                  height: 400,
                  width: "100%",
                  borderRadius: 20,
                }}
              >
                {!isLoaded ? (
                  <h1>Loading...</h1>
                ) : (
                  <GoogleMap
                    mapContainerClassName="map-container"
                    center={center}
                    zoom={10}
                  >
                    <MarkerF
                      position={{
                        lat: location.state?.lat,
                        lng: location.state?.lon,
                      }}
                      icon={"/assets/mapMarker.svg"}
                    />
                  </GoogleMap>
                )}
              </Box>
              <Divider
                color="#E0E0E0"
                style={{
                  marginTop: 32,
                }}
              />
              <Typography
                fontSize={20}
                fontFamily="AvertaStd-Bold"
                color={appColors.primaryTextColor}
                style={{
                  marginTop: 32,
                }}
              >
                Floor plans
              </Typography>
              <Box
                style={{
                  width: "100%",
                }}
                sx={{
                  overflow: "auto",
                }}
              >
                <List
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    maxHeight: "100%",
                    overflow: "auto",
                  }}
                >
                  {Array.from(location.state?.data[0].floorPhotos).map(
                    (item, index) => {
                      return (
                        <ListItem
                          key={index}
                          style={{
                            marginRight: 8,
                          }}
                        >
                          <Paper
                            elevation={2}
                            style={{
                              height: 135,
                              width: 235,
                              borderRadius: 15,
                              paddingTop: 15,
                            }}
                          >
                            <Stack>
                              <img
                                style={{
                                  height: 72,
                                  objectFit: "cover",
                                }}
                                alt={index}
                                src={URL.createObjectURL(item)}
                              />
                              <FlexBetween>
                                <Stack
                                  direction="row"
                                  style={{
                                    marginLeft: 12,
                                    marginRight: 12,
                                  }}
                                >
                                  <img alt="pdf" src="/assets/icons/pdf.svg" />
                                  <Typography
                                    color="black"
                                    fontSize={14}
                                    fontFamily="AvertaStd-semibold"
                                    style={{
                                      marginLeft: 4,
                                    }}
                                  >
                                    floor plan.pdf
                                  </Typography>
                                </Stack>
                                <ButtonBase>
                                  <img
                                    alt="save"
                                    src="/assets/icons/save.svg"
                                  />
                                </ButtonBase>
                              </FlexBetween>
                            </Stack>
                          </Paper>
                        </ListItem>
                      );
                    }
                  )}
                </List>
              </Box>
              <Divider
                color="#E0E0E0"
                style={{
                  marginTop: 32,
                }}
              />
              <Typography
                fontSize={20}
                fontFamily="AvertaStd-Bold"
                color={appColors.primaryTextColor}
                style={{
                  marginTop: 32,
                }}
              >
                What this place offers
              </Typography>
              <Typography
                fontSize={20}
                fontFamily="AvertaStd-Bold"
                color={appColors.primaryTextColor}
                style={{
                  marginTop: 32,
                }}
              >
                Favorites
              </Typography>
              <Grid
                container
                style={{
                  marginTop: 22,
                }}
              >
                {location.state?.fav?.map((item) => {
                  return (
                    <Grid item xs={6} key={item}>
                      <Stack
                        direction="row"
                        style={{
                          height: 40,
                        }}
                      >
                        {/* <img
                          alt="wifi"
                          src="/assets/icons/u_wifi.svg"
                          style={{
                            width: 20,
                            height: 20,
                          }}
                        /> */}
                        <Typography color={appColors.primaryTextColor}>
                          {item}
                        </Typography>
                      </Stack>
                    </Grid>
                  );
                })}
              </Grid>
              <Typography
                fontSize={20}
                fontFamily="AvertaStd-Bold"
                color={appColors.primaryTextColor}
                style={{
                  marginTop: 32,
                }}
              >
                Security
              </Typography>
              <Grid
                container
                style={{
                  marginTop: 22,
                }}
              >
                {location.state?.security?.map((item) => {
                  return (
                    <Grid item xs={6} key={item}>
                      <Stack
                        direction="row"
                        style={{
                          height: 40,
                        }}
                      >
                        {/* <img
                          alt="wifi"
                          src="/assets/icons/u_wifi.svg"
                          style={{
                            width: 20,
                            height: 20,
                          }}
                        /> */}
                        <Typography color={appColors.primaryTextColor}>
                          {item}
                        </Typography>
                      </Stack>
                    </Grid>
                  );
                })}
              </Grid>
              <Typography
                fontSize={20}
                fontFamily="AvertaStd-Bold"
                color={appColors.primaryTextColor}
                style={{
                  marginTop: 32,
                }}
              >
                Facility
              </Typography>
              <Grid
                container
                style={{
                  marginTop: 22,
                }}
              >
                {location.state?.facility?.map((item) => {
                  return (
                    <Grid item xs={6} key={item}>
                      <Stack
                        direction="row"
                        style={{
                          height: 40,
                        }}
                      >
                        {/* <img
                          alt="wifi"
                          src="/assets/icons/u_wifi.svg"
                          style={{
                            width: 20,
                            height: 20,
                          }}
                        /> */}
                        <Typography color={appColors.primaryTextColor}>
                          {item}
                        </Typography>
                      </Stack>
                    </Grid>
                  );
                })}
              </Grid>
              {/* <ButtonBase
                style={{
                  marginTop: 16,
                  width: 120,
                }}
              >
                <Box
                  style={{
                    width: 120,
                    height: 42,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  sx={{
                    border: 1,
                    borderColor: "#9490DB",
                    borderRadius: 10,
                  }}
                >
                  <Typography
                    fontSize={14}
                    fontFamily="AvertaStd-semibold"
                    color="primary"
                  >
                    See all
                  </Typography>
                </Box>
              </ButtonBase> */}
              <Divider
                color="#E0E0E0"
                style={{
                  marginTop: 32,
                }}
              />
              <Typography
                fontSize={20}
                fontFamily="AvertaStd-Bold"
                color={appColors.primaryTextColor}
                style={{
                  marginTop: 32,
                }}
              >
                Video tour
              </Typography>
              <Typography fontSize={16} color={appColors.darkGrayTexColor}>
                {location.state?.videoUrl}
              </Typography>
              <Box
                style={{
                  height: 350,
                  width: "100%",
                  borderRadius: 20,
                  marginTop: 24,
                }}
              >
                <img
                  style={{
                    objectFit: "cover",
                    borderRadius: 15,
                    width: "100%",
                    height: "100%",
                  }}
                  alt="videoThumb"
                  src={get_youtube_thumbnail(
                    `${location.state?.videoUrl}`,
                    "max"
                  )}
                />
                <img
                  style={{
                    position: "absolute",
                    left: "45%",
                    top: 160,
                  }}
                  alt="videoPlay"
                  src="/assets/videoPlay.svg"
                />
              </Box>
              {!isNonMobileScreens && (
                <Divider
                  color="#E0E0E0"
                  style={{
                    marginTop: 32,
                  }}
                />
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} md={5}>
            <Paper
              style={{
                borderRadius: 20,
                marginTop: isNonMobileScreens ? 0 : 32,
              }}
              sx={{
                border: 1,
                borderColor: "#E0E0E0",
              }}
            >
              <Stack
                style={{
                  margin: 25,
                }}
              >
                <FlexBetween>
                  <Stack>
                    <Typography
                      fontSize={16}
                      color={appColors.primaryTextColor}
                    >
                      Starting from
                    </Typography>
                    <Stack direction="row">
                      <Typography
                        fontSize={24}
                        fontFamily="AvertaStd-Bold"
                        color={appColors.primaryTextColor}
                      >
                        ${location.state?.data[0]?.minRent}
                      </Typography>
                      <Typography
                        fontSize={14}
                        color={appColors.grayTextColor}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        /month
                      </Typography>
                    </Stack>
                  </Stack>
                  <Avatar
                    sx={{ width: 50, height: 50 }}
                    alt={location.state?.propertyPicture[0]?.name}
                    src={
                      location.state?.propertyPicture[0] === undefined
                        ? ""
                        : URL.createObjectURL(
                            location.state?.propertyPicture[0]
                          )
                    }
                  />
                </FlexBetween>
                <Divider
                  color="#E0E0E0"
                  style={{
                    marginTop: 22,
                  }}
                />
                <Typography
                  fontSize={14}
                  color={appColors.primaryTextColor}
                  style={{
                    marginTop: 22,
                  }}
                >
                  Managed by:
                </Typography>
                <Stack
                  direction="row"
                  style={{
                    marginTop: 12,
                  }}
                >
                  <Avatar
                    sx={{ width: 50, height: 50 }}
                    alt="managerPhoto"
                    src={
                      location.state?.managerPhoto === null ||
                      location.state?.managerPhoto === undefined
                        ? ""
                        : URL.createObjectURL(location.state?.managerPhoto[0])
                    }
                  />
                  <Stack
                    style={{
                      marginLeft: 16,
                    }}
                  >
                    <Typography
                      fontSize={20}
                      fontFamily="AvertaStd-Bold"
                      color={appColors.primaryTextColor}
                    >
                      {location.state?.managerName}
                    </Typography>
                    <Typography
                      fontSize={14}
                      color={appColors.darkGrayTexColor}
                    >
                      Property Manager
                    </Typography>
                  </Stack>
                </Stack>
                <Typography
                  fontSize={16}
                  color={appColors.darkGrayTexColor}
                  style={{
                    marginTop: 18,
                  }}
                >
                  {user?.email}
                </Typography>
                <Divider
                  color="#E0E0E0"
                  style={{
                    marginTop: 22,
                  }}
                />
                <ButtonBase
                  style={{
                    marginTop: 20,
                  }}
                >
                  <Box
                    style={{
                      height: 48,
                      width: "100%",
                      borderRadius: 24,
                      backgroundColor: "#9490DB",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      color="white"
                      fontSize={14}
                      fontFamily="AvertaStd-semibold"
                    >
                      Contact
                    </Typography>
                  </Box>
                </ButtonBase>
                <ButtonBase
                  style={{
                    marginTop: 20,
                  }}
                >
                  <Box
                    style={{
                      height: 48,
                      width: "100%",
                      borderRadius: 24,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    sx={{
                      border: 1,
                      borderColor: "#9490DB",
                    }}
                  >
                    <Stack direction="row" spacing={1}>
                      <img alt="love" src="/assets/icons/lovePurple.svg" />
                      <Typography
                        color="primary"
                        fontSize={14}
                        fontFamily="AvertaStd-semibold"
                      >
                        Add to Favorites
                      </Typography>
                    </Stack>
                  </Box>
                </ButtonBase>
              </Stack>
            </Paper>
            <Paper
              style={{
                borderRadius: 20,
                marginTop: 20,
              }}
              sx={{
                border: 1,
                borderColor: "#E0E0E0",
              }}
            >
              <Stack
                direction="row"
                spacing={3}
                style={{
                  margin: 25,
                }}
              >
                <img alt="mike" src="/assets/icons/mike.svg" />
                <Stack>
                  <Typography
                    fontSize={18}
                    fontFamily="AvertaStd-Bold"
                    color={appColors.primaryTextColor}
                  >
                    Promotion:
                  </Typography>
                  <Typography fontSize={14} color={appColors.darkGrayTexColor}>
                    {location.state?.displayName}
                  </Typography>
                </Stack>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Stack>
      <div
        style={{
          textAlign: "center",
          marginTop: 40,
        }}
      >
        <UserFooter
          buttonTitle="Create Housing"
          handleClick={handleFooterClick}
        />
      </div>
    </div>
  );
};

export default Preview;
