import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
// utils
import axios from "../utils/axios";
import { setSession } from "../utils/jwt";

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  MANAGER_LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  managerLogin: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        if (accessToken) {
          setSession(accessToken);

          const response = await axios.get("/users/myAccount");
          const { user } = response.data;

          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (error) {
        console.error(error);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const managerLogin = async (email, password, passwordConfirm) => {
    const values = {
      email,
      password,
      passwordConfirm,
    };

    try {
      const options = {
        method: "POST",
        url: "/users/managerLogin",
        data: values,
      };
      const response = await axios.request(options);
      if (!response.data.status) {
        return [400, { message: response.data.message }];
      }
      const { token, data } = response.data;
      const { user } = data;
      setSession(token);
      dispatch({
        type: "MANAGER_LOGIN",
        payload: {
          user,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  };

  const login = async (email, password) => {
    const values = {
      email,
      password,
    };

    try {
      const options = {
        method: "POST",
        url: "/users/login",
        data: values,
      };
      const response = await axios.request(options);
      if (!response.data.status) {
        return [400, { message: response.data.message }];
      }
      const { token, data } = response.data;
      const { user } = data;
      setSession(token);
      dispatch({
        type: "LOGIN",
        payload: {
          user,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  };

  const register = (responseData) => {
    const { token, data } = responseData;
    const { user } = data;
    setSession(token);
    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
    console.log(responseData);
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
  };

  const resetPassword = () => {};

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        managerLogin,
        logout,
        register,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
