import {
  Box,
  Link,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { appColors } from "../theme";
import { Link as RouterLink } from "react-router-dom";

const navItems = [
  {
    text: "Dashboard",
    completeIcon: "/assets/u_chart.svg",
    selectedIcon: "/assets/u_chart_active.svg",
  },
  {
    text: "My Properties",
    completeIcon: "/assets/u_building_gray.svg",
    selectedIcon: "/assets/u_building_active.svg",
  },
  {
    text: "Messages",
    completeIcon: "/assets/u_comment-alt-dots.svg",
    selectedIcon: "/assets/u_comment_active.svg",
  },
  {
    text: "Help Center",
    completeIcon: "/assets/u_exclamation-circle.svg",
    selectedIcon: "/assets/u_exclamation_active.svg",
  },
];

const DashboardSidebar = ({
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [active, setActive] = useState("dashboard");
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    setActive(pathname.substring(1));
    if (pathname.substring(1) === "dashboard") {
      setActiveIndex(0);
    } else if (pathname.substring(1) === "yourlisting") {
      setActiveIndex(1);
    } else if (pathname.substring(1) === "amentities") {
      setActiveIndex(2);
    } else if (pathname.substring(1) === "media") {
      setActiveIndex(3);
    } else if (pathname.substring(1) === "preview") {
      setActiveIndex(4);
    }
  }, [pathname]);

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Box
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: "#797979",
              backgroundColor: "#F8F8F8",
              boxSizing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
              marginTop: 15,
            },
          }}
        >
          <Box width="100%">
            <Link underline="none" component={RouterLink} to="/">
              <img
                width="100%"
                height="42px"
                alt="advert"
                src="/assets/logo.svg"
                style={{ borderRadius: "0.75rem", margin: "0.75rem 0" }}
              />
            </Link>
            <List>
              {navItems.map(({ text, completeIcon, selectedIcon }, index) => {
                const tempText = text.replace(/\s/g, "");
                const lcText = tempText.toLowerCase();
                return (
                  <ListItem
                    key={text}
                    disablePadding
                    style={{
                      marginTop: 36,
                      marginLeft: 70,
                      background: active === lcText ? "white" : "#F6F6F6",
                      borderRadius: 5,
                    }}
                  >
                    <ListItemButton
                      onClick={() => {
                        navigate(`/${lcText}`);
                        setActive(lcText);
                        setActiveIndex(index);
                      }}
                    >
                      <Stack
                        key={text}
                        direction="row"
                        style={{
                          height: 50,
                          alignItems: "center",
                        }}
                      >
                        {active === lcText ? (
                          <img
                            style={{
                              height: 45,
                              marginLeft: 10,
                            }}
                            alt="side"
                            src="/assets/side.svg"
                          />
                        ) : null}

                        <img
                          style={{
                            width: 24,
                            height: 24,
                            marginLeft: active === lcText ? 20 : 30,
                          }}
                          alt="u_circle"
                          src={active === lcText ? selectedIcon : completeIcon}
                        />
                        <Typography
                          style={{
                            marginLeft: 8,
                          }}
                          align="center"
                          fontSize={16}
                          fontFamily={
                            active === lcText
                              ? "AvertaStd-semibold"
                              : "AvertaStd-Regular"
                          }
                          color={active === lcText ? "#9490DB" : "#797979"}
                        >
                          {text}
                        </Typography>
                      </Stack>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DashboardSidebar;
