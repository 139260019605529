import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";

const initialState = {
  isLoading: false,
  error: false,
  schools: [],
  message: "",
  roommate: null,
  roommates: [],
  roommatesCount: 0,
  housings: [],
  housing: null,
  locations: [],
  reviews: [],
  myHousings: [],
  favorites: [],
  similarHousings: [],
  schoolList: [],
  chats: [],
  chat: [],
  popular: [],
  popularHousings: [],
  colleges: [],
};

const slice = createSlice({
  name: "froomie",
  initialState,
  reducers: {
    // * START LOADING
    startLoading: (state) => {
      state.isLoading = true;
    },

    // * HAS ERROR
    hasError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    // * GET SCHOOLS
    getSchoolSuccess: (state, action) => {
      state.isLoading = false;
      state.schools = action.payload;
    },

    // * GET SCHOOL LIST
    getSchoolListSuccess: (state, action) => {
      state.isLoading = false;
      state.schoolList = action.payload;
    },

    // * GET HOME SLIDERS
    getSingleRoommateSuccess: (state, action) => {
      state.isLoading = false;
      state.roommate = action.payload;
    },

    // * GET ROOMMATE LIST
    getRoommatesSuccess: (state, action) => {
      state.isLoading = false;
      state.roommates = action.payload;
    },

    // * GET ROOMMATE LIST
    roommatesCountSuccess: (state, action) => {
      state.isLoading = false;
      state.roommatesCount = action.payload;
    },

    // * GET HOUSING LIST
    housingsSuccess: (state, action) => {
      state.isLoading = false;
      state.housings = action.payload;
    },

    // * GET MY HOUSING LIST
    myHousingsSuccess: (state, action) => {
      state.isLoading = false;
      state.myHousings = action.payload;
    },

    // * GET SINGLE HOUSING
    getSingleHousingSuccess: (state, action) => {
      state.isLoading = false;
      state.housing = action.payload;
    },

    // * GET SIMILAR HOUSING
    getSimilarHousingSuccess: (state, action) => {
      state.isLoading = false;
      state.similarHousings = action.payload;
    },

    // * GET Locations
    locationsSuccess: (state, action) => {
      state.isLoading = false;
      state.locations = action.payload;
    },

    // * GET Reviews
    reviewsSuccess: (state, action) => {
      state.isLoading = false;
      state.reviews = action.payload;
    },

    // * GET FAVORITES
    favoriteSuccess: (state, action) => {
      state.isLoading = false;
      state.favorites = action.payload;
    },

    // * GET CHATS
    chatSuccess: (state, action) => {
      state.isLoading = false;
      state.chats = action.payload;
    },

    // * GET CHATS
    chatHistorySuccess: (state, action) => {
      state.isLoading = false;
      state.chat = action.payload;
    },

    // * SEND CHAT
    sendChatSuccess: (state, action) => {
      state.isLoading = false;
      // const chat = action.payload;
      // state.chat = [...state.chat, chat];
    },

    // * POPULAR
    popularSuccess: (state, action) => {
      state.isLoading = false;
      state.popular = action.payload;
    },

    // * POPULAR
    popularHousingSuccess: (state, action) => {
      state.isLoading = false;
      state.popularHousings = action.payload;
    },

    // * ADD NEW MESSAGE
    updateChatMessage: (state, action) => {
      state.chat = [action.payload, ...state.chat];
    },

    // * COLLEGES
    collegesSuccess: (state, action) => {
      state.isLoading = false;
      state.colleges = action.payload;
    },
  },
});

export default slice.reducer;

// * ACTIONS
// export const { schools } = slice.actions;

// ------------------------------------------------------
// * GET SCHOOLS
// ------------------------------------------------------
export function getSchools() {
  return async (dispatch) => {
    try {
      const options = {
        method: "GET",
        url: "/schools/getSchools",
      };
      const response = await axiosInstance.request(options);
      dispatch(slice.actions.getSchoolSuccess(response.data.data.schools));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------------------------------
// * GET COLLEGES
// ------------------------------------------------------
export function getColleges() {
  return async (dispatch) => {
    try {
      const options = {
        method: "GET",
        url: "/colleges/getColleges",
      };
      const response = await axiosInstance.request(options);
      dispatch(slice.actions.collegesSuccess(response.data.data.colleges));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------------------------------
// * GET SCHOOL LIST
// ------------------------------------------------------
export function getSchoolList() {
  return async (dispatch) => {
    try {
      const options = {
        method: "GET",
        url: "/schools/getSchoolList",
      };
      const response = await axiosInstance.request(options);
      dispatch(slice.actions.getSchoolListSuccess(response.data.data.schools));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------------------------------
// * GET Single Roommate
// ------------------------------------------------------
export function getSingleRoommate(id) {
  return async (dispatch) => {
    try {
      const options = {
        method: "GET",
        url: `/roommates/getRoommate/${id}`,
      };
      const response = await axiosInstance.request(options);
      dispatch(
        slice.actions.getSingleRoommateSuccess(response.data.data.roommmate)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------------------------------
// * GET Roommates
// ------------------------------------------------------
export function getRoommates(params) {
  return async (dispatch) => {
    try {
      const options = {
        method: "GET",
        url: "/roommates/getRoommates/",
        params: params,
      };
      const response = await axiosInstance.request(options);
      dispatch(slice.actions.getRoommatesSuccess(response.data.data.roommates));
      dispatch(slice.actions.roommatesCountSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------------------------------
// * GET HOUSINGS
// ------------------------------------------------------
export function getHousings(params) {
  return async (dispatch) => {
    try {
      const options = {
        method: "GET",
        url: "/housing/getHousings",
        params: params,
      };
      const response = await axiosInstance.request(options);
      dispatch(slice.actions.housingsSuccess(response.data.data.housingList));
      //dispatch(slice.actions.roommatesCountSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------------------------------
// * GET FAVORITES
// ------------------------------------------------------
export function getFavorites(limit) {
  return async (dispatch) => {
    try {
      const options = {
        method: "GET",
        url: "/favorite/getFavorites",
      };
      const response = await axiosInstance.request(options);
      dispatch(slice.actions.favoriteSuccess(response.data.data.favorites));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------------------------------
// * GET MY HOUSINGS
// ------------------------------------------------------
export function getMyHousings(limit) {
  return async (dispatch) => {
    try {
      const options = {
        method: "GET",
        url: "/housing/getHousingByUser",
      };
      const response = await axiosInstance.request(options);
      dispatch(slice.actions.myHousingsSuccess(response.data.data.housingList));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------------------------------
// * GET Single HOUSING
// ------------------------------------------------------
export function getSingleHousing(id) {
  return async (dispatch) => {
    try {
      const options = {
        method: "GET",
        url: `/housing/getHousing/${id}`,
      };
      const response = await axiosInstance.request(options);
      dispatch(
        slice.actions.getSingleHousingSuccess(response.data.data.housing)
      );
      dispatch(
        slice.actions.getSimilarHousingSuccess(
          response.data.data.similarHousings
        )
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------------------------------
// * GET Locations
// ------------------------------------------------------
export function getLocations(searchQuery) {
  return async (dispatch) => {
    try {
      const options = {
        method: "GET",
        url: `location/searchLocations?searchQuery=${searchQuery}`,
      };
      const response = await axiosInstance.request(options);
      console.log(response);
      dispatch(slice.actions.locationsSuccess(response.data.data.locations));
      //dispatch(slice.actions.roommatesCountSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------------------------------
// * GET HOUSING REVIEW
// ------------------------------------------------------
export function getHousingReview(id) {
  return async (dispatch) => {
    try {
      const options = {
        method: "GET",
        url: `/review/getHousingReviewList/${id}`,
      };
      const response = await axiosInstance.request(options);
      dispatch(slice.actions.reviewsSuccess(response.data.data.reviews));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------------------------------
// * GET CHAT LIST
// ------------------------------------------------------
export function getChatList() {
  return async (dispatch) => {
    try {
      const options = {
        method: "GET",
        url: "/chats/getChatUserList?page=1&limit=200",
      };
      const response = await axiosInstance.request(options);
      dispatch(slice.actions.chatSuccess(response.data.data.chats));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------------------------------
// * GET CHAT HISTORY
// ------------------------------------------------------
export function getHistory(id) {
  return async (dispatch) => {
    try {
      const options = {
        method: "GET",
        url: `/chats/${id}/getChatHistory`,
      };
      const response = await axiosInstance.request(options);
      dispatch(slice.actions.chatHistorySuccess(response.data.data.chat));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------------------------------
// * SEND CHAT MESSAGE
// ------------------------------------------------------
export function sendChatMessage(id, message) {
  return async (dispatch) => {
    const values = {
      id,
      message,
    };
    try {
      const options = {
        method: "POST",
        url: "/chats/sendMessage",
        data: values,
      };
      const response = await axiosInstance.request(options);
      dispatch(slice.actions.sendChatSuccess(response.data.data.chat));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------------------------------
// * GET POPULAR HOUSING
// ------------------------------------------------------
export function getPopular(school) {
  return async (dispatch) => {
    try {
      const options = {
        method: "GET",
        url: `/housing/getPopular`,
      };
      const response = await axiosInstance.request(options);
      dispatch(slice.actions.popularSuccess(response.data.data));
      dispatch(filterPopular(school, response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------------------------------
// * GET POPULAR HOUSING FILTER
// ------------------------------------------------------

export function filterPopular(school, array) {
  return async (dispatch) => {
    try {
      const results = array.filter(function (e, i) {
        return array[i]._id === school;
      });
      dispatch(slice.actions.popularHousingSuccess(results[0]?.housings));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------------------------------
// * ADD NEW MESSAGE
// ------------------------------------------------------

export function addNewMessage(message) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.updateChatMessage(message));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
