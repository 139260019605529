import {
  Box,
  ButtonBase,
  Card,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import UserFooter from "../../components/UserFooter";
import useWindowDimensions from "../../components/useWindowDimensions";
import React, { useState } from "react";
import { appColors } from "../../theme";
import Popup from "../../components/Popup";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { IMAGE_BASE_URL } from "../../utils/constants";
import axiosInstance from "../../utils/axios";
import { useSnackbar } from "notistack";

const ConnectLandlords = () => {
  const { height, width } = useWindowDimensions();
  const location = useLocation();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const { enqueueSnackbar } = useSnackbar();
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const [openLoginPopup, setOpenLoginPopup] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const { isAuthenticated, login, register } = useAuth();

  const handleFooterClick = async () => {
    if (isAuthenticated) {
      sendLeaseData();
    } else {
      setOpenLoginPopup(true);
    }
  };

  const userLogin = async () => {
    try {
      const loginResponse = await login(email, password);
      if (loginResponse.status) {
        setOpenLoginPopup(false);
        sendLeaseData();
      } else {
        enqueueSnackbar(loginResponse.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
    }
  };

  const sendLeaseData = async () => {
    let data = {
      firstName: location.state.firstName,
      lastName: location.state.lastName,
      email: location.state.email,
      university: location.state.university,
      housing: location.state.housing.id,
      floorPlan: location.state.selectedId,
      leasePeriod: location.state.leaseDate,
      moveIn: location.state.moveDate,
      subject: subject,
      message: message,
      url: window.location.origin + "/listing/" + location.state.housing.id,
    };

    try {
      const options = {
        method: "POST",
        url: "/lease/postLease",
        data,
      };
      const response = await axiosInstance.request(options);
      if (response.data.status) {
        sendMessage();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Server Error", { variant: "error" });
    }
  };

  const sendMessage = async () => {
    const values = {
      id: location.state.housing?.host?.id,
      message: message,
    };
    try {
      const options = {
        method: "POST",
        url: "/chats/sendMessage",
        data: values,
      };
      const response = await axiosInstance.request(options);
      if (response.data.status) {
        enqueueSnackbar("Message Sent Successfully", { variant: "success" });
        navigate(`/listing/${location.state.housing.id}`);
      }
    } catch (error) {
      enqueueSnackbar("Server Error", { variant: "error" });
    }
  };

  const continueClick = async () => {
    const data = new FormData();
    data.append("email", email);
    data.append("password", password);
    data.append("passwordConfirm", passwordConfirm);
    data.append(
      "name",
      `${location.state.firstName} ${location.state.lastName}`
    );
    data.append("school", location.state.university);
    data.append("type", "other");
    try {
      const options = {
        method: "POST",
        url: "/users/signup",
        data,
      };
      const response = await axiosInstance.request(options);
      if (!response.data) {
        console.log("400");
        return [400, { message: response.data.message }];
      }

      register(response.data);
      setOpenSuccessPopup(false);
      sendLeaseData();
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
    }
  };

  return (
    <div
      style={{
        background: "#F9F9F9",
      }}
    >
      <Box padding="1rem 6%">
        <Grid
          container
          sx={{
            height: {
              md: height - 240,
            },
          }}
        >
          <Grid
            xs={12}
            md={8}
            sx={{
              borderRight: isNonMobile ? 1 : 0,
              borderColor: "#C2C2C2",
            }}
          >
            <Stack>
              <Typography
                fontSize={32}
                fontFamily="AvertaStd-Bold"
                color={appColors.primaryTextColor}
              >
                Connect with Landlord
              </Typography>
              <Typography color="#A0A0A0" fontSize={14}>
                All fields are required
              </Typography>
              <Typography
                fontSize={16}
                fontFamily="AvertaStd-semibold"
                color={appColors.primaryTextColor}
                style={{
                  marginTop: 24,
                }}
              >
                Subject
              </Typography>
              <Box
                style={{
                  backgroundColor: "#EEEEEE",
                  height: 50,
                  marginRight: isNonMobile ? 16 : 0,
                  marginTop: 16,
                  borderRadius: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                  fullWidth
                  hiddenLabel
                  id="standard-basic"
                  placeholder="Subject"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  fontSize={14}
                  color={appColors.primaryTextColor}
                  value={subject}
                  onChange={(event) => {
                    setSubject(event.target.value);
                  }}
                />
              </Box>
              <Typography
                fontSize={16}
                fontFamily="AvertaStd-semibold"
                color={appColors.primaryTextColor}
                style={{
                  marginTop: 24,
                }}
              >
                Message
              </Typography>
              <Box
                style={{
                  height: 150,
                  marginRight: isNonMobile ? 16 : 0,
                  marginTop: 16,
                  borderRadius: 30,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                sx={{
                  border: 1,
                  borderColor: "#C2C2C2",
                }}
              >
                <TextField
                  style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                  fullWidth
                  hiddenLabel
                  multiline
                  id="standard-basic"
                  placeholder="Message"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  fontSize={14}
                  color={appColors.primaryTextColor}
                  minRows={6}
                  maxRows={7}
                  value={message}
                  onChange={(event) => {
                    setMessage(event.target.value);
                  }}
                />
              </Box>
            </Stack>
          </Grid>
          <Grid xs={12} md={4}>
            <Card
              sx={{
                border: 1,
                borderColor: "#C2C2C2",
                borderRadius: 5,
                marginTop: isNonMobile ? 0 : 2,
                height: isNonMobile ? 350 : 400,
                marginLeft: isNonMobile ? 2 : 0,
                width: {
                  md: 280,
                },
              }}
            >
              <Stack>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    height: "100%",
                  }}
                >
                  <ButtonBase>
                    <img
                      style={{
                        objectFit: "cover",
                        width: isNonMobile ? 268 : width - 70,
                        margin: 7,
                        borderRadius: 12,
                        height: 172,
                      }}
                      alt="listing"
                      src={IMAGE_BASE_URL + location.state.housing?.photos[0]}
                      onClick={() => {
                        //navigate(`/listing/${item.id}`);
                      }}
                    />
                  </ButtonBase>
                  <ButtonBase
                    onClick={() => {
                      console.log("Love Clicked");
                    }}
                  >
                    <img
                      style={{
                        position: "absolute",
                        top: 12,
                        right: 12,
                      }}
                      alt="love"
                      src="/assets/love.svg"
                    />
                  </ButtonBase>
                </Box>
                <Stack
                  style={{
                    marginLeft: 12,
                    marginRight: 12,
                  }}
                  onClick={() => {
                    //console.log(item.id);
                  }}
                >
                  <Stack direction="row">
                    <img width={16} alt="star" src="/assets/star.svg" />
                    <Typography
                      align="center"
                      fontSize={12}
                      color="#7F7F7F"
                      style={{
                        marginLeft: 4,
                      }}
                    >
                      {location.state.housing?.reviewsAverage?.toFixed(2)} (
                      {location.state.housing?.numberOfReviews})
                    </Typography>
                  </Stack>
                  <Typography
                    fontSize={16}
                    fontFamily="AvertaStd-Bold"
                    noWrap
                    style={{
                      marginTop: 6,
                    }}
                  >
                    {location.state.housing?.title}
                  </Typography>
                  <Stack
                    direction="row"
                    style={{
                      height: 24,
                      width: 82,
                      backgroundColor: "#D4D3EB",
                      borderRadius: 6,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img alt="usd" src="/assets/usd.svg" />
                    <Typography
                      fontSize={11}
                      color="#9490DB"
                      style={{
                        marginLeft: 4,
                      }}
                    >
                      Promotion
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={0.3}
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <img alt="subway" src="/assets/icons/subway.svg" />
                    <img alt="utensils" src="/assets/icons/utensils.svg" />
                  </Stack>
                  <Stack
                    direction="row"
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <Typography
                      fontSize={18}
                      fontFamily="AvertaStd-Bold"
                      color="#231F20"
                    >
                      Starting at $
                      {location.state.housing?.minRent === undefined
                        ? location.state.housing?.floorPlans[0]?.minRent
                        : location.state.housing?.minRent}
                    </Typography>
                    <Typography
                      fontSize={12}
                      color="#949494"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      /month
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <div
        style={{
          textAlign: "center",
        }}
      >
        <UserFooter buttonTitle="Next" handleClick={handleFooterClick} />
      </div>
      <Popup openPopup={openLoginPopup} setOpenPopup={setOpenLoginPopup}>
        <div
          style={{
            width: 450,
          }}
        >
          <Stack>
            <ButtonBase
              style={{
                width: 40,
                height: 40,
                position: "absolute",
                top: 16,
                right: 16,
              }}
              onClick={() => setOpenLoginPopup(false)}
            >
              <img alt="x_circle" src="/assets/icons/fi_x-circle.svg" />
            </ButtonBase>
            <Typography
              style={{
                marginTop: 30,
              }}
              fontSize={24}
              fontFamily="AvertaStd-Bold"
              color={appColors.primaryTextColor}
            >
              Let’s create something
            </Typography>
            <Typography fontSize={14} color="#A0A0A0">
              Login to post new Housing
            </Typography>
            <Typography
              fontSize={16}
              fontFamily="AvertaStd-semibold"
              color={appColors.primaryTextColor}
              style={{
                marginTop: 32,
              }}
            >
              Email address
            </Typography>
            <Box
              style={{
                height: 50,
                marginTop: 16,
                borderRadius: 25,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              sx={{
                border: 1,
                borderColor: "#E0E0E0",
              }}
            >
              <TextField
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                fullWidth
                hiddenLabel
                id="standard-basic"
                placeholder="email"
                variant="standard"
                InputProps={{ disableUnderline: true }}
                fontSize={14}
                color={appColors.primaryTextColor}
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </Box>
            <Typography
              fontSize={16}
              fontFamily="AvertaStd-semibold"
              color={appColors.primaryTextColor}
              style={{
                marginTop: 32,
              }}
            >
              Password
            </Typography>
            <Box
              style={{
                height: 50,
                marginTop: 16,
                borderRadius: 25,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              sx={{
                border: 1,
                borderColor: "#E0E0E0",
              }}
            >
              <TextField
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                fullWidth
                hiddenLabel
                id="standard-basic"
                placeholder="Type here"
                variant="standard"
                InputProps={{ disableUnderline: true }}
                fontSize={14}
                color={appColors.primaryTextColor}
                type="password"
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />
            </Box>
            <ButtonBase
              style={{
                backgroundColor: "#9490DB",
                width: 450,
                height: 50,
                marginTop: 27,
                borderRadius: 25,
                marginBottom: 16,
              }}
              onClick={userLogin}
            >
              <Typography
                fontSize={14}
                fontFamily="AvertaStd-semibold"
                color="#FDFDFD"
              >
                Sign In
              </Typography>
            </ButtonBase>
            <ButtonBase
              style={{
                backgroundColor: "white",
                width: 450,
                height: 50,
                borderRadius: 25,
                marginBottom: 30,
              }}
              onClick={() => {
                setOpenLoginPopup(false);
                setOpenSuccessPopup(true);
              }}
              sx={{
                border: 1,
                borderColor: "#9490DB",
              }}
            >
              <Typography
                fontSize={14}
                fontFamily="AvertaStd-semibold"
                color="#9490DB"
              >
                Sign Up
              </Typography>
            </ButtonBase>
          </Stack>
        </div>
      </Popup>
      <Popup openPopup={openSuccessPopup} setOpenPopup={setOpenSuccessPopup}>
        <div
          style={{
            width: isNonMobile ? 450 : 300,
          }}
        >
          <Stack>
            <ButtonBase
              style={{
                width: 40,
                height: 40,
                position: "absolute",
                top: 16,
                right: 16,
              }}
              onClick={() => setOpenSuccessPopup(false)}
            >
              <img alt="x_circle" src="/assets/icons/fi_x-circle.svg" />
            </ButtonBase>
            <Typography
              style={{
                marginTop: 30,
              }}
              fontSize={24}
              fontFamily="AvertaStd-Bold"
              color={appColors.primaryTextColor}
            >
              Let’s create something
            </Typography>
            <Typography fontSize={14} color="#A0A0A0">
              Create a password before messaging the landlord
            </Typography>
            <Typography
              fontSize={16}
              fontFamily="AvertaStd-semibold"
              color={appColors.primaryTextColor}
              style={{
                marginTop: 32,
              }}
            >
              Email address
            </Typography>
            <Box
              style={{
                width: isNonMobile ? "100%" : 280,
                height: 50,
                marginTop: 16,
                borderRadius: 25,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              sx={{
                border: 1,
                borderColor: "#E0E0E0",
              }}
            >
              <TextField
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                fullWidth
                hiddenLabel
                id="standard-basic"
                placeholder="email"
                variant="standard"
                InputProps={{ disableUnderline: true }}
                fontSize={14}
                color={appColors.primaryTextColor}
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </Box>
            <Typography
              fontSize={16}
              fontFamily="AvertaStd-semibold"
              color={appColors.primaryTextColor}
              style={{
                marginTop: 32,
              }}
            >
              Create Password
            </Typography>
            <Box
              style={{
                width: isNonMobile ? "100%" : 280,
                height: 50,
                marginTop: 16,
                borderRadius: 25,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              sx={{
                border: 1,
                borderColor: "#E0E0E0",
              }}
            >
              <TextField
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                fullWidth
                hiddenLabel
                id="standard-basic"
                placeholder="Type here"
                variant="standard"
                InputProps={{ disableUnderline: true }}
                fontSize={14}
                color={appColors.primaryTextColor}
                type="password"
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />
            </Box>
            <Typography
              fontSize={16}
              fontFamily="AvertaStd-semibold"
              color={appColors.primaryTextColor}
              style={{
                marginTop: 32,
              }}
            >
              Re-type Password
            </Typography>
            <Box
              style={{
                width: isNonMobile ? "100%" : 280,
                height: 50,
                marginTop: 16,
                borderRadius: 25,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              sx={{
                border: 1,
                borderColor: "#E0E0E0",
              }}
            >
              <TextField
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                fullWidth
                hiddenLabel
                id="standard-basic"
                placeholder="Type here"
                variant="standard"
                InputProps={{ disableUnderline: true }}
                fontSize={14}
                color={appColors.primaryTextColor}
                type="password"
                value={passwordConfirm}
                onChange={(event) => {
                  setPasswordConfirm(event.target.value);
                }}
              />
            </Box>
            <ButtonBase
              style={{
                backgroundColor: "#9490DB",
                width: isNonMobile ? 450 : 280,
                height: 50,
                marginTop: 27,
                borderRadius: 25,
                marginBottom: 30,
              }}
              onClick={continueClick}
            >
              <Typography
                fontSize={14}
                fontFamily="AvertaStd-semibold"
                color="#FDFDFD"
              >
                Continue
              </Typography>
            </ButtonBase>
          </Stack>
        </div>
      </Popup>
    </div>
  );
};

export default ConnectLandlords;
