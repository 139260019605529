import {
  Box,
  ButtonBase,
  Checkbox,
  Divider,
  List,
  Paper,
  Stack,
  TextField,
  Typography,
  appBarClasses,
} from "@mui/material";
import UserFooter from "../../../../components/UserFooter";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DragDropFiles from "../../../../components/DragDropFiles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import FlexBetween from "../../../../components/FlexBetween";
import { appColors } from "../../../../theme";

const Amentities = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isEdit, setIsEdit] = useState(false);
  const [files, setFiles] = useState();
  const [isPropertyView, setIsPropertyView] = useState(false);
  const [isPromotion, setIsPromotion] = useState(false);
  const [bedRoomCount, setBedRoomCount] = useState("");
  const [bathRoomCount, setBathRoomCount] = useState("");
  const [minRent, setMinRent] = useState("");
  const [maxRent, setMaxRent] = useState("");
  const [fav, setFav] = useState([]);
  const [security, setSecurity] = useState([]);
  const [facility, setFacility] = useState([]);
  const [displayName, setDisplayName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const initialState = [
    {
      id: 1,
      bedRoomCount: "",
      bathRoomCount: "",
      minRent: "",
      maxRent: "",
      floorPhotos: [],
    },
  ];

  const [data, setData] = useState(initialState);
  const [selectedData, setSelectedData] = useState();

  const tenantFavorites = [
    "Wifi included",
    "Cleaning services",
    "Wifi available for purchase",
    "Water included",
    "Off- street parking",
  ];

  const securities = [
    "24h front desk",
    "Biometric access",
    "Burglar bars in bedrooms",
    "CCTV/Surveillance cameras",
    "Electric fencing",
    "On-site manager",
    "Security alarm",
    "Security officer",
  ];

  const facilities = [
    "24h front desk",
    "Biometric access",
    "Burglar bars in bedrooms",
    "CCTV/Surveillance cameras",
    "Electric fencing",
  ];

  const handleFooterClick = () => {
    navigate("/media", {
      state: {
        type: location.state.type,
        floorCount: location.state.floorCount,
        bedCount: location.state.bedCount,
        lease: location.state.lease,
        university: location.state.university,
        apartment: location.state.apartment,
        address: location.state.address,
        lat: location.state.lat,
        lon: location.state.lon,
        data: data,
        displayName: displayName,
        description: location.state.description,
        startDate: startDate,
        endDate: endDate,
        fav: fav,
        security: security,
        facility: facility,
      },
    });
  };

  const removeFloors = (item) => {
    setData(
      (current) => current.slice(0, -1)
      // current.filter((obj) => {
      //   return obj !== item;
      // })
    );
  };

  const removeFileByIndex = (index) => {
    setFiles((oldValues) => {
      Array.from(oldValues).filter((_, i) => i !== index);
    });
  };

  return (
    <div>
      <Stack
        padding="1rem 6%"
        style={{
          marginTop: 38,
        }}
      >
        <FlexBetween>
          <Stack>
            <Typography
              fontSize={32}
              fontFamily="AvertaStd-Bold"
              color={appColors.primaryTextColor}
            >
              Amentities
            </Typography>
            <Typography color="#A0A0A0" fontSize={14}>
              All fields are required
            </Typography>
          </Stack>
          <ButtonBase
            style={{
              width: 120,
              height: 42,
              borderRadius: 21,
            }}
            onClick={() => {
              let lastItem = data[data.length - 1];
              let newItem = {
                id: 1,
                bedRoomCount: bedRoomCount,
                bathRoomCount: "",
                minRent: "",
                maxRent: "",
                floorPhotos: [],
              };
              if (lastItem) {
                newItem = {
                  id: lastItem.id + 1,
                  bedRoomCount: "",
                  bathRoomCount: "",
                  minRent: "",
                  maxRent: "",
                  floorPhotos: [],
                };
              }
              setData((current) => [...current, newItem]);
            }}
          >
            <Box
              style={{
                width: 120,
                height: 42,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              sx={{
                border: 1,
                borderRadius: 21,
                borderColor: "#9490DB",
              }}
            >
              <Stack direction="row">
                <img alt="plus_icon" src="/assets/icons/fi_plus-circle.svg" />
                <Typography
                  style={{
                    marginLeft: 4,
                  }}
                  fontSize={14}
                  fontFamily="AvertaStd-semibold"
                  color="#9490DB"
                >
                  Add more
                </Typography>
              </Stack>
            </Box>
          </ButtonBase>
        </FlexBetween>
        <List>
          {data.map((item) => {
            return (
              <Paper
                key={item.id}
                style={{
                  height: 100,
                  width: "100%",
                  borderRadius: 16,
                  padding: 30,
                  marginTop: 24,
                }}
              >
                <FlexBetween>
                  <ButtonBase
                    onClick={() => {
                      setSelectedData(item);
                      setBedRoomCount(item.bedRoomCount);
                      setBathRoomCount(item.bathRoomCount);
                      setMinRent(item.minRent);
                      setMaxRent(item.maxRent);
                      setFiles(item.floorPhotos);
                      setIsEdit(true);
                    }}
                    style={{
                      width: "100vh",
                      display: "flex",
                      justifyContent: "left",
                    }}
                  >
                    <Stack>
                      <Typography
                        fontSize={16}
                        fontFamily="AvertaStd-semibold"
                        color={appColors.primaryTextColor}
                      >
                        FloorPlan {item.id}
                      </Typography>
                      <Typography color="#EE1817" fontSize={14}>
                        Required
                      </Typography>
                    </Stack>
                  </ButtonBase>
                  <Stack direction="row">
                    <ButtonBase
                      style={{
                        width: 32,
                        height: 32,
                        marginRight: 20,
                      }}
                      onClick={() => removeFloors(item)}
                    >
                      <img
                        width={32}
                        height={32}
                        alt="x-Circle"
                        src="/assets/icons/fi_x-circle.svg"
                      />
                    </ButtonBase>
                    <ButtonBase
                      onClick={() => {
                        setSelectedData(item);
                        setBedRoomCount(item.bedRoomCount);
                        setBathRoomCount(item.bathRoomCount);
                        setMinRent(item.minRent);
                        setMaxRent(item.maxRent);
                        setFiles(item.floorPhotos);
                        setIsEdit(true);
                      }}
                    >
                      <img alt="rightIcon" src="/assets/icons/rightArrow.svg" />
                    </ButtonBase>
                  </Stack>
                </FlexBetween>
              </Paper>
            );
          })}
        </List>
        <Typography
          style={{
            marginTop: 36,
          }}
          fontSize={32}
          fontFamily="AvertaStd-Bold"
          color={appColors.primaryTextColor}
        >
          Promotion
        </Typography>
        <Typography color="#A0A0A0" fontSize={14}>
          All fields are required
        </Typography>
        <ButtonBase
          style={{
            width: "100%",
            marginTop: 24,
          }}
          onClick={() => setIsPromotion(true)}
        >
          <Paper
            style={{
              height: 74,
              width: "100%",
              borderRadius: 16,
              padding: 30,
            }}
          >
            <FlexBetween>
              <Typography
                fontSize={16}
                fontFamily="AvertaStd-semibold"
                color={appColors.primaryTextColor}
              >
                Promotion
              </Typography>
              <img alt="rightIcon" src="/assets/icons/rightArrow.svg" />
            </FlexBetween>
          </Paper>
        </ButtonBase>
        <Typography
          style={{
            marginTop: 36,
          }}
          fontSize={32}
          fontFamily="AvertaStd-Bold"
          color={appColors.primaryTextColor}
        >
          Property Features
        </Typography>
        <Typography color="#A0A0A0" fontSize={14}>
          All fields are required
        </Typography>
        <ButtonBase
          style={{
            width: "100%",
            marginTop: 24,
          }}
          onClick={() => {
            setIsPropertyView(true);
          }}
        >
          <Paper
            style={{
              height: 74,
              width: "100%",
              borderRadius: 16,
              padding: 30,
            }}
          >
            <FlexBetween>
              <Typography
                fontSize={16}
                fontFamily="AvertaStd-semibold"
                color={appColors.primaryTextColor}
              >
                Property Features
              </Typography>
              <img alt="rightIcon" src="/assets/icons/rightArrow.svg" />
            </FlexBetween>
          </Paper>
        </ButtonBase>
      </Stack>
      <div
        style={{
          position: "absolute",
          bottom: "0px",
          left: 0,
          right: 0,
          textAlign: "center",
          marginLeft: 250,
        }}
      >
        <UserFooter buttonTitle="Next" handleClick={handleFooterClick} />
      </div>
      {isEdit && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            top: 0,
            right: 0,
            width: "100%",
            textAlign: "center",
          }}
        >
          <Stack
            direction="row"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <Box
              style={{
                width: "100%",
                backgroundColor: appColors.primaryTextColor,
                opacity: 0.3,
              }}
            />
            <Box
              style={{
                width: 650,
                backgroundColor: "white",
                height: "100%",
              }}
            >
              <ButtonBase
                style={{
                  width: 32,
                  height: 32,
                  position: "absolute",
                  top: 40,
                  right: 30,
                }}
                onClick={() => {
                  setIsEdit(false);
                }}
              >
                <img
                  width={32}
                  height={32}
                  alt="x-Circle"
                  src="/assets/icons/fi_x-circle.svg"
                />
              </ButtonBase>
              <ButtonBase
                style={{
                  marginTop: 36,
                  height: 50,
                  borderRadius: 25,
                  paddingLeft: 20,
                  paddingRight: 20,
                  position: "absolute",
                  bottom: 50,
                  right: 20,
                  width: 120,
                  backgroundColor: "#9490DB",
                }}
                onClick={() => {
                  const newState = data.map((obj) => {
                    if (obj.id === selectedData.id) {
                      return {
                        ...obj,
                        bedRoomCount: bedRoomCount,
                        bathRoomCount: bathRoomCount,
                        minRent: minRent,
                        maxRent: maxRent,
                        floorPhotos: files,
                      };
                    }
                    return obj;
                  });
                  setData(newState);
                  setIsEdit(false);
                }}
              >
                <Typography
                  fontSize={14}
                  fontFamily="AvertaStd-semibold"
                  color="white"
                >
                  Next
                </Typography>
              </ButtonBase>
              <Stack
                style={{
                  marginLeft: 40,
                  marginRight: 40,
                  marginTop: 90,
                }}
                align="left"
              >
                <Typography
                  align="left"
                  fontSize={32}
                  fontFamily="AvertaStd-Bold"
                  color={appColors.primaryTextColor}
                >
                  FloorPlan {selectedData.id}
                </Typography>
                <Typography align="left" fontSize={14} color="#A0A0A0">
                  All fields are required
                </Typography>
                <Typography
                  fontSize={16}
                  fontFamily="AvertaStd-semibold"
                  color={appColors.primaryTextColor}
                  style={{
                    marginTop: 30,
                  }}
                >
                  How many bedrooms does this floorplan have?
                </Typography>
                <Box
                  style={{
                    height: 50,
                    marginTop: 16,
                    borderRadius: 25,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  sx={{
                    border: 1,
                    borderColor: "#E0E0E0",
                  }}
                >
                  <TextField
                    style={{
                      paddingLeft: 20,
                      paddingRight: 20,
                    }}
                    fullWidth
                    hiddenLabel
                    id="standard-basic"
                    placeholder="Type here"
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    fontSize={14}
                    color={appColors.primaryTextColor}
                    value={bedRoomCount}
                    onChange={(event) => {
                      setBedRoomCount(event.target.value);
                    }}
                  />
                </Box>
                <Typography
                  fontSize={16}
                  fontFamily="AvertaStd-semibold"
                  color={appColors.primaryTextColor}
                  style={{
                    marginTop: 30,
                  }}
                >
                  How many bathrooms does this floorplan have?
                </Typography>
                <Box
                  style={{
                    height: 50,
                    marginTop: 16,
                    borderRadius: 25,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  sx={{
                    border: 1,
                    borderColor: "#E0E0E0",
                  }}
                >
                  <TextField
                    style={{
                      paddingLeft: 20,
                      paddingRight: 20,
                    }}
                    fullWidth
                    hiddenLabel
                    id="standard-basic"
                    placeholder="Type here"
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    fontSize={14}
                    color={appColors.primaryTextColor}
                    value={bathRoomCount}
                    onChange={(event) => {
                      setBathRoomCount(event.target.value);
                    }}
                  />
                </Box>
                <Typography
                  fontSize={16}
                  fontFamily="AvertaStd-semibold"
                  color={appColors.primaryTextColor}
                  style={{
                    marginTop: 30,
                  }}
                >
                  What is the rent range for this floorplan?
                </Typography>
                <Stack
                  direction="row"
                  spacing={3}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 16,
                  }}
                >
                  <Box
                    style={{
                      height: 50,
                      borderRadius: 25,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    sx={{
                      border: 1,
                      borderColor: "#E0E0E0",
                    }}
                  >
                    <TextField
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                      }}
                      fullWidth
                      hiddenLabel
                      id="standard-basic"
                      placeholder="Type here"
                      variant="standard"
                      InputProps={{ disableUnderline: true }}
                      fontSize={14}
                      color={appColors.primaryTextColor}
                      value={minRent}
                      onChange={(event) => {
                        setMinRent(event.target.value);
                      }}
                    />
                  </Box>
                  <Typography>To</Typography>
                  <Box
                    style={{
                      height: 50,
                      borderRadius: 25,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    sx={{
                      border: 1,
                      borderColor: "#E0E0E0",
                    }}
                  >
                    <TextField
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                      }}
                      fullWidth
                      hiddenLabel
                      id="standard-basic"
                      placeholder="Type here"
                      variant="standard"
                      InputProps={{ disableUnderline: true }}
                      fontSize={14}
                      color={appColors.primaryTextColor}
                      value={maxRent}
                      onChange={(event) => {
                        setMaxRent(event.target.value);
                      }}
                    />
                  </Box>
                </Stack>
                <Typography
                  fontSize={16}
                  fontFamily="AvertaStd-semibold"
                  color={appColors.primaryTextColor}
                  style={{
                    marginTop: 30,
                  }}
                >
                  FloorPlan photos
                </Typography>
                <Paper
                  style={{
                    height: 130,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white",
                    borderRadius: 16,
                    marginTop: 16,
                  }}
                >
                  <DragDropFiles files={files} setFiles={setFiles} />
                </Paper>
                {files && (
                  <Stack
                    direction="row"
                    spacing={2}
                    style={{
                      marginTop: 24,
                    }}
                  >
                    {Array.from(files).map((file, index) => {
                      return (
                        <Stack direction="row" key={index}>
                          <img
                            width={50}
                            height={50}
                            alt={file.name}
                            src={URL.createObjectURL(file)}
                            style={{
                              borderRadius: 25,
                            }}
                          />
                          <ButtonBase
                            style={{
                              width: 14,
                              height: 14,
                            }}
                            onClick={() => removeFileByIndex(index)}
                          >
                            <img
                              alt="x_circle"
                              src="/assets/icons/fi_x-circle.svg"
                            />
                          </ButtonBase>
                        </Stack>
                      );
                    })}
                  </Stack>
                )}
              </Stack>
            </Box>
          </Stack>
        </div>
      )}
      {isPropertyView && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            top: 0,
            right: 0,
            width: "100%",
            textAlign: "center",
          }}
        >
          <Stack
            direction="row"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <Box
              style={{
                width: "100%",
                backgroundColor: appColors.primaryTextColor,
                opacity: 0.3,
              }}
            />
            <Box
              style={{
                width: 650,
                backgroundColor: "white",
                height: "100%",
              }}
            >
              <ButtonBase
                style={{
                  width: 32,
                  height: 32,
                  position: "absolute",
                  top: 40,
                  right: 30,
                }}
                onClick={() => setIsPropertyView(false)}
              >
                <img
                  width={32}
                  height={32}
                  alt="x-Circle"
                  src="/assets/icons/fi_x-circle.svg"
                />
              </ButtonBase>
              <Stack
                style={{
                  marginLeft: 40,
                  marginRight: 40,
                  marginTop: 90,
                }}
                align="left"
              >
                <Typography
                  align="left"
                  fontSize={32}
                  fontFamily="AvertaStd-Bold"
                  color={appColors.primaryTextColor}
                >
                  Property features
                </Typography>
                <Typography align="left" fontSize={14} color="#A0A0A0">
                  All fields are required
                </Typography>
                <List
                  style={{
                    marginTop: 32,
                  }}
                >
                  <Typography
                    fontSize={16}
                    fontFamily="AvertaStd-semibold"
                    color={appBarClasses.primaryTextColor}
                  >
                    Tenant favorites
                  </Typography>
                  {tenantFavorites.map((favorite, index) => {
                    return (
                      <FlexBetween key={index}>
                        <Typography
                          color="#A0A0A0"
                          fontSize={14}
                          fontFamily="AvertaStd-semibold"
                        >
                          {favorite}
                        </Typography>
                        <Checkbox
                          checked={fav.includes(favorite) ? true : false}
                          onClick={() => {
                            if (fav.includes(favorite)) {
                              setFav((current) =>
                                current.filter((obj) => {
                                  return obj !== favorite;
                                })
                              );
                            } else {
                              setFav((current) => [...current, favorite]);
                            }
                          }}
                        />
                      </FlexBetween>
                    );
                  })}
                  <Divider
                    style={{
                      backgroundColor: "#C2C2C2",
                      marginTop: 20,
                    }}
                  />
                  <Typography
                    style={{
                      marginTop: 20,
                    }}
                    fontSize={16}
                    fontFamily="AvertaStd-semibold"
                    color={appBarClasses.primaryTextColor}
                  >
                    Security
                  </Typography>
                  {securities.map((item, index) => {
                    return (
                      <FlexBetween key={index}>
                        <Typography
                          color="#A0A0A0"
                          fontSize={14}
                          fontFamily="AvertaStd-semibold"
                        >
                          {item}
                        </Typography>
                        <Checkbox
                          checked={security.includes(item) ? true : false}
                          onClick={() => {
                            if (security.includes(item)) {
                              setSecurity((current) =>
                                current.filter((obj) => {
                                  return obj !== item;
                                })
                              );
                            } else {
                              setSecurity((current) => [...current, item]);
                            }
                          }}
                        />
                      </FlexBetween>
                    );
                  })}
                  <Divider
                    style={{
                      backgroundColor: "#C2C2C2",
                      marginTop: 20,
                    }}
                  />
                  <Typography
                    style={{
                      marginTop: 20,
                    }}
                    fontSize={16}
                    fontFamily="AvertaStd-semibold"
                    color={appBarClasses.primaryTextColor}
                  >
                    Facilities
                  </Typography>
                  {facilities.map((item, index) => {
                    return (
                      <FlexBetween key={index}>
                        <Typography
                          color="#A0A0A0"
                          fontSize={14}
                          fontFamily="AvertaStd-semibold"
                        >
                          {item}
                        </Typography>
                        <Checkbox
                          checked={facility.includes(item) ? true : false}
                          onClick={() => {
                            if (facility.includes(item)) {
                              setFacility((current) =>
                                current.filter((obj) => {
                                  return obj !== item;
                                })
                              );
                            } else {
                              setFacility((current) => [...current, item]);
                            }
                          }}
                        />
                      </FlexBetween>
                    );
                  })}
                  <Divider
                    style={{
                      backgroundColor: "#C2C2C2",
                      marginTop: 20,
                    }}
                  />
                </List>
                <Stack direction="row" justifyContent="flex-end">
                  <ButtonBase
                    style={{
                      marginTop: 36,
                      marginBottom: 40,
                      height: 50,
                      width: 120,
                      borderRadius: 25,
                      backgroundColor: "#9490DB",
                    }}
                    onClick={() => {
                      setIsPropertyView(false);
                    }}
                  >
                    <Typography
                      fontSize={14}
                      fontFamily="AvertaStd-semibold"
                      color="white"
                    >
                      Next
                    </Typography>
                  </ButtonBase>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </div>
      )}
      {isPromotion && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            top: 0,
            right: 0,
            width: "100%",
            textAlign: "center",
          }}
        >
          <Stack
            direction="row"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <Box
              style={{
                width: "100%",
                backgroundColor: appColors.primaryTextColor,
                opacity: 0.3,
              }}
            />
            <Box
              style={{
                width: 650,
                backgroundColor: "white",
                height: "100%",
              }}
            >
              <ButtonBase
                style={{
                  width: 32,
                  height: 32,
                  position: "absolute",
                  top: 40,
                  right: 30,
                }}
                onClick={() => setIsPromotion(false)}
              >
                <img
                  width={32}
                  height={32}
                  alt="x-Circle"
                  src="/assets/icons/fi_x-circle.svg"
                />
              </ButtonBase>
              <ButtonBase
                style={{
                  marginTop: 36,
                  height: 50,
                  borderRadius: 25,
                  paddingLeft: 20,
                  paddingRight: 20,
                  position: "absolute",
                  bottom: 50,
                  right: 20,
                  width: 120,
                  backgroundColor: "#9490DB",
                }}
                onClick={() => setIsPromotion(false)}
              >
                <Typography
                  fontSize={14}
                  fontFamily="AvertaStd-semibold"
                  color="white"
                >
                  Done
                </Typography>
              </ButtonBase>
              <Stack
                style={{
                  marginLeft: 40,
                  marginRight: 40,
                  marginTop: 90,
                }}
                align="left"
              >
                <Typography
                  align="left"
                  fontSize={32}
                  fontFamily="AvertaStd-Bold"
                  color={appColors.primaryTextColor}
                >
                  Promotion
                </Typography>
                <Typography align="left" fontSize={14} color="#A0A0A0">
                  All fields are required
                </Typography>
                <Typography
                  fontSize={16}
                  fontFamily="AvertaStd-semibold"
                  color={appColors.primaryTextColor}
                  style={{
                    marginTop: 30,
                  }}
                >
                  Display name
                </Typography>
                <Box
                  style={{
                    height: 50,
                    marginTop: 16,
                    borderRadius: 25,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  sx={{
                    border: 1,
                    borderColor: "#E0E0E0",
                  }}
                >
                  <TextField
                    style={{
                      paddingLeft: 20,
                      paddingRight: 20,
                    }}
                    fullWidth
                    hiddenLabel
                    id="standard-basic"
                    placeholder="Type here"
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    fontSize={14}
                    color={appColors.primaryTextColor}
                    value={displayName}
                    onChange={(event) => {
                      setDisplayName(event.target.value);
                    }}
                  />
                </Box>
                <Typography
                  fontSize={16}
                  fontFamily="AvertaStd-semibold"
                  color={appColors.primaryTextColor}
                  style={{
                    marginTop: 30,
                  }}
                >
                  What is the promotion range?
                </Typography>
                <Stack
                  direction="row"
                  spacing={2}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 16,
                  }}
                >
                  <Box
                    style={{
                      height: 50,
                      borderRadius: 25,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            {...params}
                            value={startDate}
                            onChange={(event) => {
                              setStartDate(event.target.value);
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Typography>To</Typography>
                  <Box
                    style={{
                      height: 50,
                      borderRadius: 25,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            {...params}
                            value={endDate}
                            onChange={(event) => {
                              setEndDate(event.target.value);
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </div>
      )}
    </div>
  );
};

export default Amentities;
