import {
  Box,
  ButtonBase,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { appColors } from "../../../../theme";
import UserFooter from "../../../../components/UserFooter";
import DragDropFiles from "../../../../components/DragDropFiles";
import { useSnackbar } from "notistack";

const Media = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [files, setFiles] = useState();
  const [photos, setPhotos] = useState();
  const [videos, setVideos] = useState();
  const [managerName, setManagerName] = useState("");
  const [videoUrl, setVideoUrl] = useState("");

  const handleFooterClick = () => {
    if (photos === undefined || photos.length < 5) {
      enqueueSnackbar("Please Upload at least 5 Photos", { variant: "error" });
    } else {
      navigate("/preview", {
        state: {
          type: location.state.type,
          floorCount: location.state.floorCount,
          bedCount: location.state.bedCount,
          lease: location.state.lease,
          university: location.state.university,
          apartment: location.state.apartment,
          address: location.state.address,
          lat: location.state.lat,
          lon: location.state.lon,
          data: location.state.data,
          displayName: location.state.displayName,
          description: location.state.description,
          startDate: location.state.startDate,
          endDate: location.state.endDate,
          managerPhoto: files,
          managerName: managerName,
          propertyPicture: photos,
          videos: videos,
          videoUrl: videoUrl,
          fav: location.state.fav,
          security: location.state.security,
          facility: location.state.facility,
        },
      });
    }
  };

  return (
    <div
      style={{
        background: "#F9F9F9",
      }}
    >
      <Stack padding="1rem 6%">
        <Typography
          fontSize={32}
          fontFamily="AvertaStd-Bold"
          color={appColors.primaryTextColor}
        >
          Media
        </Typography>
        <Typography color="#A0A0A0" fontSize={14}>
          All fields are required
        </Typography>
        <Typography
          fontSize={14}
          fontFamily="AvertaStd-semibold"
          color={appColors.primaryTextColor}
          style={{
            marginTop: 24,
          }}
        >
          Property Manager Photo
        </Typography>
        <DragDropFiles
          files={files}
          setFiles={setFiles}
          showFiles={true}
          isMultiple={false}
        />
        <Typography
          fontSize={14}
          fontFamily="AvertaStd-semibold"
          color={appColors.primaryTextColor}
          style={{
            marginTop: 24,
          }}
        >
          Property Manager Name
        </Typography>
        <Box
          style={{
            backgroundColor: "#EEEEEE",
            height: 50,
            marginRight: 16,
            marginTop: 16,
            borderRadius: 25,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          sx={{
            width: {
              md: 350,
            },
          }}
        >
          <TextField
            style={{
              paddingLeft: 20,
              paddingRight: 20,
            }}
            fullWidth
            hiddenLabel
            id="standard-basic"
            placeholder="Property Manager Name here"
            variant="standard"
            InputProps={{ disableUnderline: true }}
            fontSize={14}
            color={appColors.primaryTextColor}
            value={managerName}
            onChange={(event) => {
              setManagerName(event.target.value);
            }}
          />
        </Box>
        <Typography
          style={{
            marginTop: 32,
          }}
          fontSize={20}
          fontFamily="AvertaStd-semibold"
          color={appColors.primaryTextColor}
        >
          Pictures:
        </Typography>
        <Typography fontSize={14} color="#A0A0A0">
          Select up to 15 photos of your property
        </Typography>
        <Paper
          style={{
            height: 130,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            borderRadius: 16,
            marginTop: 16,
          }}
          sx={{
            width: {
              md: 600,
            },
          }}
        >
          <DragDropFiles files={photos} setFiles={setPhotos} />
        </Paper>
        {photos && (
          <Stack
            direction="row"
            spacing={2}
            style={{
              marginTop: 24,
            }}
          >
            {Array.from(photos).map((file, index) => {
              return (
                <Stack direction="row">
                  <img
                    key={index}
                    width={50}
                    height={50}
                    alt={file.name}
                    src={URL.createObjectURL(file)}
                    style={{
                      borderRadius: 25,
                    }}
                  />
                  <ButtonBase
                    style={{
                      width: 14,
                      height: 14,
                    }}
                    onClick={() => {
                      const array = [...photos];
                      if (index !== -1) {
                        array.splice(index, 1);
                        setPhotos(array);
                      }
                    }}
                  >
                    <img alt="x_circle" src="/assets/icons/fi_x-circle.svg" />
                  </ButtonBase>
                </Stack>
              );
            })}
          </Stack>
        )}
        <Typography
          style={{
            marginTop: 32,
          }}
          fontSize={20}
          fontFamily="AvertaStd-semibold"
          color={appColors.primaryTextColor}
        >
          Video:
        </Typography>
        <Typography fontSize={14} color="#A0A0A0">
          Drop a video link of your property
        </Typography>
        <Paper
          style={{
            backgroundColor: "white",
            borderRadius: 16,
            marginTop: 16,
          }}
          sx={{
            width: {
              md: 600,
            },
          }}
        >
          <Stack>
            {/* <Box
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <DragDropFiles files={videos} setFiles={setVideos} />
            </Box>
            <Divider
              style={{
                marginTop: 24,
              }}
            /> */}
            <Typography
              style={{
                marginTop: 24,
              }}
              sx={{
                marginLeft: {
                  xs: 2,
                  md: 6,
                },
              }}
              fontSize={14}
              fontFamily="AvertaStd-semibold"
              color={appColors.primaryTextColor}
            >
              Import from URL
            </Typography>
            <Box
              style={{
                backgroundColor: "#EEEEEE",
                height: 50,
                marginTop: 16,
                borderRadius: 25,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 24,
              }}
              sx={{
                marginLeft: {
                  xs: 2,
                  md: 6,
                },
                marginRight: {
                  xs: 2,
                },
              }}
            >
              <TextField
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                fullWidth
                hiddenLabel
                id="standard-basic"
                placeholder="Add URL here"
                variant="standard"
                InputProps={{ disableUnderline: true }}
                fontSize={14}
                color={appColors.primaryTextColor}
                value={videoUrl}
                onChange={(event) => {
                  setVideoUrl(event.target.value);
                }}
              />
            </Box>
          </Stack>
        </Paper>
      </Stack>
      <div
        style={{
          textAlign: "center",
          marginTop: 40,
        }}
      >
        <UserFooter buttonTitle="Next" handleClick={handleFooterClick} />
      </div>
    </div>
  );
};

export default Media;
