import { Box, List, ListItem, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { appColors } from "../theme";

const navItems = [
  {
    text: "Welcome",
    status: "complete",
    completeIcon: "",
  },
  {
    text: "Your listing",
    status: "inProgress",
    completeIcon: "",
  },
  {
    text: "Amentities",
    status: "",
    completeIcon: "",
  },
  {
    text: "Media",
    status: "",
    completeIcon: "",
  },
  {
    text: "Preview",
    status: "",
    completeIcon: "",
  },
];

const Sidebar = ({
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("welcome");
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    setActive(pathname.substring(1));
    if (pathname.substring(1) === "welcome") {
      setActiveIndex(0);
    } else if (pathname.substring(1) === "yourlisting") {
      setActiveIndex(1);
    } else if (pathname.substring(1) === "amentities") {
      setActiveIndex(2);
    } else if (pathname.substring(1) === "media") {
      setActiveIndex(3);
    } else if (pathname.substring(1) === "preview") {
      setActiveIndex(4);
    }
  }, [pathname]);

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Box
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: "#797979",
              backgroundColor: "#F8F8F8",
              boxSizing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
              marginTop: 15,
            },
          }}
        >
          <Box width="100%">
            <List>
              {navItems.map(({ text }, index) => {
                const tempText = text.replace(/\s/g, "");
                const lcText = tempText.toLowerCase();
                if (activeIndex > index) {
                  return (
                    <ListItem
                      key={text}
                      disablePadding
                      style={{
                        marginTop: 36,
                        marginLeft: 70,
                      }}
                    >
                      {/* <ListItemButton
                        onClick={() => {
                          navigate(`/${lcText}`);
                          setActive(lcText);
                          setActiveIndex(index);
                        }}
                      > */}
                      <Stack
                        key={text}
                        direction="row"
                        style={{
                          height: 50,
                        }}
                      >
                        <img
                          style={{
                            width: 24,
                            height: 24,
                          }}
                          alt="u_circle"
                          src="/assets/icons/u_check-circle.svg"
                        />
                        <Typography
                          style={{
                            marginLeft: 8,
                          }}
                          align="center"
                          fontSize={16}
                          fontFamily="AvertaStd-semibold"
                          color="#9490DB"
                        >
                          {text}
                        </Typography>
                      </Stack>
                      {/* </ListItemButton> */}
                    </ListItem>
                  );
                }
                return (
                  <ListItem
                    key={text}
                    disablePadding
                    style={{
                      marginTop: 36,
                      marginLeft: 70,
                    }}
                  >
                    {/* <ListItemButton
                    onClick={() => {
                      navigate(`/${lcText}`);
                      setActive(lcText);
                      setActiveIndex(index);
                    }}
                    > */}
                    <Stack
                      key={text}
                      direction="row"
                      style={{
                        height: 50,
                      }}
                    >
                      <img
                        style={{
                          width: 24,
                          height: 24,
                        }}
                        alt="u_circle"
                        src={
                          active === lcText
                            ? "/assets/icons/u_minus-circle.svg"
                            : "/assets/icons/u_circle.svg"
                        }
                      />
                      <Typography
                        style={{
                          marginLeft: 8,
                        }}
                        align="center"
                        fontSize={16}
                        fontFamily={
                          active === lcText
                            ? "AvertaStd-semibold"
                            : "AvertaStd-Regular"
                        }
                        color={
                          active === lcText
                            ? appColors.darkGrayTextColor
                            : "#797979"
                        }
                      >
                        {text}
                      </Typography>
                    </Stack>
                    {/* </ListItemButton> */}
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Sidebar;
