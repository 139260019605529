import { Box, MenuItem, Select, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { appColors } from "../../../theme";
import UserFooter from "../../../components/UserFooter";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "../../../state/store";
import { getColleges } from "../../../state/slices/froomie";
import { useSnackbar } from "notistack";

const UserSignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { colleges } = useSelector((state) => state.froomie);
  const [userType, setUserType] = useState("");
  const [university, setUniversity] = useState("");
  const [year, setYear] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(getColleges());
  }, [dispatch]);

  const types = ["Student", "Parent", "Other"];

  const years = ["2024", "2025", "2026", "2027", "2028", "Graduate"];

  const handleFooterClick = () => {
    if (userType === "") {
      enqueueSnackbar("Type Required", { variant: "error" });
    } else if (university === "") {
      enqueueSnackbar("University Required", { variant: "error" });
    } else if (year === "") {
      enqueueSnackbar("Year Required", { variant: "error" });
    } else {
      navigate("/userInfo", {
        state: {
          type: userType,
          school: university,
          year: year,
        },
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          height: "calc(100vh - 110px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          flexDirection="column"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          sx={{
            flex: "1 1 auto",
          }}
        >
          <Stack
            style={{
              marginTop: 38,
              width: 350,
            }}
          >
            <Typography
              fontSize={32}
              fontFamily="AvertaStd-Bold"
              color={appColors.primaryTextColor}
            >
              Create an account
            </Typography>
            <Typography fontSize={14} color="#A0A0A0">
              All fields are required
            </Typography>
            <Typography
              fontSize={16}
              fontFamily="AvertaStd-semibold"
              color={appColors.primaryTextColor}
              style={{
                marginTop: 32,
              }}
            >
              I am a...
            </Typography>
            <Select
              style={{
                marginTop: 16,
                paddingRight: 10,
                fontSize: 14,
                fontFamily: "AvertaStd-semibold",
                color: appColors.primaryTextColor,
              }}
              sx={{
                border: 1,
                borderColor: "#9490DB",
                borderRadius: 30,
              }}
              displayEmpty
              value={userType}
              onChange={(event) => {
                setUserType(event.target.value);
              }}
              renderValue={userType !== "" ? undefined : () => "Select a type"}
            >
              {types.map((item, index) => {
                return (
                  <MenuItem value={item} key={index}>
                    <Typography>{item}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
            <Typography
              fontSize={16}
              fontFamily="AvertaStd-semibold"
              color={appColors.primaryTextColor}
              style={{
                marginTop: 32,
              }}
            >
              Select university
            </Typography>
            <Select
              style={{
                marginTop: 16,
                paddingRight: 10,
                fontSize: 14,
                fontFamily: "AvertaStd-semibold",
                color: appColors.primaryTextColor,
              }}
              sx={{
                border: 1,
                borderColor: "#9490DB",
                borderRadius: 30,
              }}
              displayEmpty
              value={university}
              onChange={(event) => {
                setUniversity(event.target.value);
              }}
              renderValue={
                university !== "" ? undefined : () => "Select a university..."
              }
            >
              {colleges.map((item, index) => {
                return (
                  <MenuItem value={item.name} key={index}>
                    <Typography>{item.name}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
            <Typography
              fontSize={16}
              fontFamily="AvertaStd-semibold"
              color={appColors.primaryTextColor}
              style={{
                marginTop: 32,
              }}
            >
              Graduation year
            </Typography>
            <Select
              style={{
                marginTop: 16,
                paddingRight: 10,
                fontSize: 14,
                fontFamily: "AvertaStd-semibold",
                color: appColors.primaryTextColor,
              }}
              sx={{
                border: 1,
                borderColor: "#9490DB",
                borderRadius: 30,
              }}
              displayEmpty
              value={year}
              onChange={(event) => {
                setYear(event.target.value);
              }}
              renderValue={year !== "" ? undefined : () => "Select a year"}
            >
              {years.map((item, index) => {
                return (
                  <MenuItem value={item} key={index}>
                    <Typography>{item}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </Stack>
        </Box>
        <Box
          style={{
            width: "100%",
            textAlign: "center",
            marginTop: 30,
          }}
          sx={{
            flex: "0 1 auto",
          }}
        >
          <UserFooter buttonTitle="Next" handleClick={handleFooterClick} />
        </Box>
      </Box>
    </>
  );
};

export default UserSignUp;
