import {
  Box,
  ButtonBase,
  Card,
  ClickAwayListener,
  Divider,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import FlexBetween from "../../components/FlexBetween";
import React, { useEffect, useRef, useState } from "react";
import ListingItem from "../../components/ListingItem";
import { useDispatch, useSelector } from "../../state/store";
import {
  filterPopular,
  getHousings,
  getPopular,
  getSchools,
} from "../../state/slices/froomie";
import { Link, useNavigate } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../utils/constants";

const cities = [
  {
    id: 1,
    name: "Austin",
    school: "",
    image: "/assets/city/austin.png",
  },
  {
    id: 2,
    name: "College Station",
    image: "/assets/city/collegeStation.png",
  },
  {
    id: 3,
    name: "Oxford",
    image: "/assets/Oxford.jpeg",
  },
  {
    id: 4,
    name: "Fayetteville",
    image: "/assets/Fayetteville.jpeg",
  },
];

const Home = () => {
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const dispatch = useDispatch();
  const { housings, popular, popularHousings, schools } = useSelector(
    (state) => state.froomie
  );
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const ref = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [schoolOpen, setSchoolOpen] = useState(false);
  const schoolAnchorRef = useRef(null);
  const [school, setSchool] = useState("");

  const handleSchoolToggle = () => {
    setSchoolOpen((prevOpen) => !prevOpen);
  };

  const handleSchoolClose = (event) => {
    if (
      schoolAnchorRef.current &&
      schoolAnchorRef.current.contains(event.target)
    ) {
      return;
    }

    setSchoolOpen(false);
  };

  useEffect(() => {
    const params = {
      page: 1,
      limit: 7,
    };
    dispatch(getHousings(params));
    dispatch(getPopular("The University of Texas at Austin"));
    dispatch(getSchools());
  }, [dispatch]);

  function a11Props(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const tabChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      dispatch(filterPopular("The University of Texas at Austin", popular));
    } else if (newValue === 1) {
      dispatch(filterPopular("Texas A&M University", popular));
    } else if (newValue === 2) {
      dispatch(
        filterPopular("The University of Mississippi (Ole Miss)", popular)
      );
    } else if (newValue === 3) {
      dispatch(filterPopular("The University of Arkansas", popular));
    } else if (newValue === 4) {
      dispatch(filterPopular("Mississippi State", popular));
    }
  };

  const handleLearnClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <Box
        margin="1rem 6%"
        style={{
          marginTop: 24,
          marginBottom: 24,
          height: 600,
          position: "relative",
        }}
      >
        <img
          width="100%"
          height="100%"
          alt="cover"
          src="/assets/cover.svg"
          style={{
            objectFit: "cover",
            marginTop: 20,
            borderRadius: 30,
          }}
        />
        <Stack
          style={{
            position: "absolute",
            left: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
          }}
        >
          <Typography
            style={{
              color: "black",
              marginLeft: isNonMobile ? 100 : 20,
              marginBottom: -20,
            }}
            fontSize={isNonMobile ? 64 : 32}
            fontFamily="AvertaStd-Bold"
          >
            Find student rentals
          </Typography>
          <Typography
            style={{
              color: "black",
              marginLeft: isNonMobile ? 100 : 20,
            }}
            fontSize={isNonMobile ? 64 : 32}
            fontFamily="AvertaStd-Bold"
          >
            and roommates
          </Typography>
          <Typography
            style={{
              color: "black",
              borderRadius: 25,
              marginLeft: isNonMobile ? 100 : 20,
              marginBottom: 45,
              marginRight: isNonMobile ? 0 : 10,
            }}
          >
            Find home rentals, apartments and roommates for on and off-campus.
          </Typography>
          <Stack
            style={{
              marginLeft: isNonMobile ? 100 : 20,
              marginBottom: 45,
            }}
            direction="row"
          >
            <Link to={"https://apps.apple.com/us/app/froomie/id1570687219"}>
              <ButtonBase>
                <img
                  width={160}
                  height={48}
                  alt="appstore"
                  src="/assets/appstore.svg"
                />
              </ButtonBase>
            </Link>
            <ButtonBase
              style={{
                marginLeft: 24,
                borderRadius: 30,
              }}
              onClick={handleLearnClick}
            >
              <Typography
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 16,
                  paddingBottom: 16,
                  backgroundColor: "#9490DB",
                  borderRadius: 30,
                  color: "white",
                }}
                fontSize={14}
              >
                Learn more
              </Typography>
            </ButtonBase>
          </Stack>
          <Box
            style={{
              backgroundColor: "white",
              width: isNonMobile ? 486 : 300,
              height: 112,
              borderRadius: 25,
              marginLeft: isNonMobile ? 100 : 20,
              marginBottom: 80,
            }}
          >
            <FlexBetween
              style={{
                marginLeft: 20,
                marginRight: 20,
                marginTop: 24,
              }}
            >
              <Stack>
                <Typography fontFamily="AvertaStd-Semibold">
                  University or City
                </Typography>
                <Typography
                  style={{
                    width: isNonMobile ? 300 : 180,
                    marginTop: 4,
                  }}
                >
                  {searchText}
                </Typography>
                <Divider
                  style={{
                    marginTop: 4,
                  }}
                />
              </Stack>
              <div>
                <ButtonBase
                  sx={{
                    borderRadius: 10,
                  }}
                  onClick={handleSchoolToggle}
                  ref={schoolAnchorRef}
                  id="school-button"
                  aria-controls={schoolOpen ? "school-menu" : undefined}
                  aria-expanded={schoolOpen ? "true" : undefined}
                  aria-haspopup="true"
                >
                  <img
                    style={{
                      marginTop: 30,
                    }}
                    width="20"
                    height="20"
                    alt="chevron"
                    src="/assets/fi_chevron-down.svg"
                  />
                </ButtonBase>
                <Popper
                  open={schoolOpen}
                  anchorEl={schoolAnchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                  style={{
                    zIndex: 100,
                  }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleSchoolClose}>
                          <MenuList
                            autoFocusItem={schoolOpen}
                            id="school-menu"
                            aria-labelledby="school-button"
                          >
                            {schools.map((item, index) => {
                              return (
                                <MenuItem
                                  selected={school === item ? true : false}
                                  value={item}
                                  key={index}
                                  onClick={() => {
                                    setSchool(item);
                                    setSearchText(item);
                                    handleSchoolClose(item);
                                  }}
                                >
                                  <Typography>{item}</Typography>
                                </MenuItem>
                              );
                            })}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
              <ButtonBase
                style={{
                  width: isNonMobile ? 64 : 32,
                  height: isNonMobile ? 64 : 32,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  backgroundColor: "#9490DB",
                  borderRadius: isNonMobile ? 15 : 10,
                }}
                onClick={() => {
                  if (searchText !== "") {
                    navigate("/rentals", {
                      state: {
                        search: searchText,
                      },
                    });
                  }
                }}
              >
                <img alt="send" src="/assets/search-line.svg" />
              </ButtonBase>
            </FlexBetween>
          </Box>
        </Stack>
      </Box>
      <Typography
        style={{
          marginTop: 90,
        }}
        align="center"
        fontSize={24}
        fontFamily="AvertaStd-semibold"
      >
        As seen in
      </Typography>
      <Stack
        align="center"
        direction={isNonMobile ? "row" : "column"}
        spacing={8}
        style={{
          marginTop: 40,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img alt="google" src="/assets/brand/usNews.svg" />
        <img alt="google" src="/assets/brand/superTalk.svg" />
        <img alt="google" src="/assets/brand/oxfordEagle.svg" />
        <img alt="google" src="/assets/brand/dallasInnovates.svg" />
      </Stack>
      <Stack
        ref={ref}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 120,
        }}
      >
        <Box
          style={{
            width: 150,
            height: 35,
            borderRadius: 27,
            backgroundColor: "#EBEAFA",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography color="#9490DB" fontSize={14} fontFamily="AvertaStd-Bold">
            How it Works
          </Typography>
        </Box>
        <Typography
          style={{
            marginTop: 20,
          }}
          fontSize={20}
          fontFamily="AvertaStd-semibold"
        >
          How it works for Students:
        </Typography>
        <Stack
          style={{
            marginTop: 40,
          }}
        >
          <Stack direction={isNonMobile ? "row" : "column"} spacing={8}>
            <Stack
              style={{
                width: 210,
              }}
            >
              <img
                width={195}
                height={195}
                alt="findPlace"
                src="/assets/findPlace.svg"
              />
              <Typography
                style={{
                  marginTop: 30,
                }}
                fontSize={22}
                fontFamily="AvertaStd-Bold"
              >
                Find a place to live
              </Typography>
              <Typography
                style={{
                  marginTop: 10,
                }}
                fontSize={14}
              >
                Search for rental properties near your university
              </Typography>
            </Stack>
            <Stack
              style={{
                width: 210,
              }}
            >
              <img
                width={195}
                height={195}
                alt="findroommate"
                src="/assets/findroommate.svg"
              />
              <Typography
                style={{
                  marginTop: 30,
                }}
                fontSize={22}
                fontFamily="AvertaStd-Bold"
              >
                Find a Roommate
              </Typography>
              <Typography
                style={{
                  marginTop: 10,
                }}
                fontSize={14}
              >
                Create an account to find roommates for on and off-campus.
                Available at every college in the U.S.!
              </Typography>
            </Stack>
            <Stack
              style={{
                width: 240,
              }}
            >
              <img
                width={195}
                height={195}
                alt="connectLandlords"
                src="/assets/connectLandlords.svg"
              />
              <Typography
                style={{
                  marginTop: 30,
                }}
                fontSize={22}
                fontFamily="AvertaStd-Bold"
              >
                Connect with landlords
              </Typography>
              <Typography
                style={{
                  marginTop: 10,
                }}
                fontSize={14}
              >
                Instantly message landlords to get a lease application started
                through our apps.
              </Typography>
            </Stack>
            <Stack
              style={{
                width: 220,
              }}
            >
              <img
                width={195}
                height={195}
                alt="signLease"
                src="/assets/signLease.svg"
              />
              <Typography
                style={{
                  marginTop: 30,
                }}
                fontSize={22}
                fontFamily="AvertaStd-Bold"
              >
                Sign your lease
              </Typography>
              <Typography
                style={{
                  marginTop: 10,
                }}
                fontSize={14}
              >
                Once you’ve found your dream rental through Froomie, sign your
                lease!
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <ButtonBase
          style={{
            width: 180,
            height: 48,
            borderRadius: 34,
            backgroundColor: "#9490DB",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 70,
          }}
          onClick={() => {
            navigate("/userSignUp");
          }}
        >
          <Typography
            color="white"
            fontSize={14}
            fontFamily="AvertaStd-semibold"
          >
            Create an account
          </Typography>
        </ButtonBase>
      </Stack>
      <Stack
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 100,
        }}
      >
        <Typography
          style={{
            marginTop: 30,
          }}
          fontSize={36}
          fontFamily="AvertaStd-Bold"
          align="center"
        >
          Popular locations in USA
        </Typography>
        <Box
          sx={{ maxWidth: { xs: 320, sm: 680 } }}
          style={{
            marginTop: 24,
          }}
        >
          <Tabs
            value={value}
            onChange={tabChange}
            textColor="primary"
            variant="scrollable"
            scrollButtons={false}
            //indicatorColor="primary"
            aria-label="basic tabs example"
            TabIndicatorProps={{
              sx: { backgroundColor: "primary", height: 6, borderRadius: 6 },
            }}
            sx={{
              "& .MuiTab-root": {
                fontSize: 16,
                fontFamily: "AvertaStd-semibold",
                color: "#000000",
              },
              "& .Mui-selected": {
                fontSize: 16,
                fontFamily: "AvertaStd-Bold",
                color: "#9490DB",
              },
            }}
          >
            <Tab label="Austin" {...a11Props(0)} />
            <Tab label="College Station" {...a11Props(1)} />
            <Tab label="Oxford" {...a11Props(2)} />
            <Tab label="Fayetteville" {...a11Props(3)} />
            <Tab label="Starkville" {...a11Props(4)} />
          </Tabs>
        </Box>
        <Stack
          direction={isNonMobile ? "row" : "column"}
          spacing={8}
          style={{
            marginTop: 50,
          }}
        >
          {popularHousings?.map((item) => {
            return (
              <Box
                key={item.id}
                style={{
                  width: 341,
                  height: 237,
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/listing/${item._id}`);
                }}
              >
                <img
                  width="100%"
                  height="100%"
                  alt={item.id}
                  src={IMAGE_BASE_URL + item.photos[0]}
                  style={{
                    borderRadius: 20,
                  }}
                />
                <Box
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    style={{
                      background: "#9490DB",
                      width: 131,
                      height: 30,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 15,
                      margin: 13,
                    }}
                  >
                    <img alt="love" src="/assets/line.svg" />
                    <Typography
                      fontSize={12}
                      fontFamily="AvertaStd-semibold"
                      align="center"
                      color="#FFFFFF"
                    >
                      Trusted landlord
                    </Typography>
                  </Stack>
                </Box>
                <img
                  style={{
                    position: "absolute",
                    top: 13,
                    right: 13,
                  }}
                  alt="love"
                  src="/assets/love.svg"
                />
              </Box>
            );
          })}
        </Stack>
      </Stack>
      <Box
        margin="1rem 6%"
        style={{
          height: isNonMobile ? 820 : 1400,
          backgroundColor: "#F2F1F9",
          borderRadius: 30,
          marginTop: 100,
        }}
      >
        <Stack
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              marginTop: 58,
            }}
            fontSize={36}
            fontFamily="AvertaStd-Bold"
            align="center"
          >
            Student-first Renting
          </Typography>
          <Typography
            style={{
              marginTop: 10,
            }}
            fontSize={16}
            align="center"
          >
            We give power back to the students when it comes to renting, no more
            dealing with traditional
          </Typography>
          <Typography fontSize={16} align="center">
            agents who are landlord-first. We are here to help you find your
            next dream place.
          </Typography>
          <Grid
            container
            style={{
              margin: 50,
            }}
          >
            <Grid
              item
              xs={12}
              md={6}
              style={{
                padding: 50,
              }}
            >
              <Stack
                style={{
                  backgroundColor: "white",
                  width: "100%",
                  height: "100%",
                  borderRadius: 27,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  height="33px"
                  alt="advert"
                  src="/assets/logo.svg"
                  style={{ margin: "0.75rem 0" }}
                />
                <Typography
                  fontSize={14}
                  fontFamily="AvertaStd-semibold"
                  style={{
                    marginTop: 14,
                    backgroundColor: "#EBEAFA",
                    color: "#9490DB",
                    padding: 8,
                    borderRadius: 27,
                  }}
                >
                  10 in 10 would recommended
                </Typography>
                <Stack
                  style={{
                    marginLeft: isNonMobile ? 0 : 10,
                  }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    style={{
                      marginTop: 50,
                    }}
                  >
                    <img alt="checkFill" src="/assets/u_check_fill.svg" />
                    <Typography
                      color="#9490DB"
                      fontSize={18}
                      fontFamily="AvertaStd-semibold"
                    >
                      No Hidden Fees
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={1}
                    style={{
                      marginTop: 24,
                    }}
                  >
                    <img alt="checkFill" src="/assets/u_check_fill.svg" />
                    <Typography
                      color="#9490DB"
                      fontSize={18}
                      fontFamily="AvertaStd-semibold"
                    >
                      Completely free for students and parents
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={1}
                    style={{
                      marginTop: 24,
                    }}
                  >
                    <img alt="checkFill" src="/assets/u_check_fill.svg" />
                    <Typography
                      color="#9490DB"
                      fontSize={18}
                      fontFamily="AvertaStd-semibold"
                    >
                      Verified Landlords
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={1}
                    style={{
                      marginTop: 24,
                      marginBottom: 46,
                    }}
                  >
                    <img alt="checkFill" src="/assets/u_check_fill.svg" />
                    <Typography
                      color="#9490DB"
                      fontSize={18}
                      fontFamily="AvertaStd-semibold"
                    >
                      Wide variety of properties
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                padding: 50,
              }}
            >
              <Stack
                style={{
                  backgroundColor: "white",
                  width: "100%",
                  height: "100%",
                  borderRadius: 27,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    marginTop: 58,
                  }}
                  fontSize={24}
                  fontFamily="AvertaStd-Bold"
                  align="center"
                >
                  Conventional Agents
                </Typography>
                <Typography
                  fontSize={14}
                  fontFamily="AvertaStd-semibold"
                  style={{
                    marginTop: 14,
                    backgroundColor: "#EBEAFA",
                    color: "#9490DB",
                    padding: 8,
                    borderRadius: 27,
                    marginBottom: 20,
                  }}
                >
                  3 in 10 would recommended
                </Typography>
                <Stack
                  style={{
                    marginLeft: isNonMobile ? 0 : 10,
                    marginBottom: 100,
                  }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    style={{
                      marginTop: 24,
                    }}
                  >
                    <img alt="checkFill" src="/assets/icons/subtract1.svg" />
                    <Typography
                      color="#A0A0A0"
                      fontSize={18}
                      fontFamily="AvertaStd-semibold"
                    >
                      No hidden fees
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={1}
                    style={{
                      marginTop: 24,
                    }}
                  >
                    <img alt="checkFill" src="/assets/icons/subtract1.svg" />
                    <Typography
                      color="#A0A0A0"
                      fontSize={18}
                      fontFamily="AvertaStd-semibold"
                    >
                      Completely free for students and parents
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={1}
                    style={{
                      marginTop: 24,
                    }}
                  >
                    <img alt="checkFill" src="/assets/icons/subtract1.svg" />
                    <Typography
                      color="#A0A0A0"
                      fontSize={18}
                      fontFamily="AvertaStd-semibold"
                    >
                      Verified Landlords
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={1}
                    style={{
                      marginTop: 24,
                      marginBottom: 46,
                    }}
                  >
                    <img alt="checkFill" src="/assets/icons/subtract1.svg" />
                    <Typography
                      color="#A0A0A0"
                      fontSize={18}
                      fontFamily="AvertaStd-semibold"
                    >
                      Wide variety of properties
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Box>
      <Stack
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 100,
        }}
      >
        <Typography
          style={{
            marginTop: 30,
          }}
          fontSize={36}
          fontFamily="AvertaStd-Bold"
          align="center"
        >
          Cities where we’re available
        </Typography>
        <Typography
          style={{
            marginTop: 8,
          }}
          fontSize={16}
          align="center"
        >
          (Roommates are available at every college)
        </Typography>
        <Stack
          direction={isNonMobile ? "row" : "column"}
          spacing={8}
          style={{
            marginTop: 50,
          }}
        >
          {cities.map((item) => {
            return (
              <Stack
                key={item.id}
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/rentals");
                }}
              >
                <img
                  style={{
                    objectFit: "cover",
                    borderRadius: 20,
                  }}
                  width={240}
                  height={300}
                  alt={item.name}
                  src={item.image}
                />
                <Typography
                  fontSize={24}
                  fontFamily="AvertaStd-semibold"
                  style={{
                    marginTop: 20,
                  }}
                  align="center"
                >
                  {item.name}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
      <Stack
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 100,
        }}
      >
        <Typography
          style={{
            marginTop: 30,
          }}
          fontSize={36}
          fontFamily="AvertaStd-Bold"
        >
          Recent Listings
        </Typography>
        <Grid
          container
          justifyContent="center"
          spacing={2}
          maxWidth={1200}
          style={{
            marginTop: 30,
          }}
        >
          {housings.map((item) => {
            return (
              <Grid
                key={item.id}
                item
                style={{
                  marginBottom: 10,
                }}
              >
                <ListingItem item={item} />
                {/* <Card
                  sx={{
                    border: 1,
                    borderColor: "#C2C2C2",
                    borderRadius: 5,
                    width: 280,
                    height: 340,
                  }}
                >
                  <Stack>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                      }}
                    >
                      <ButtonBase>
                        <img
                          style={{
                            width: 268,
                            margin: 7,
                            borderRadius: 12,
                          }}
                          alt="listing"
                          src="/src/assets/test.jpg"
                          onClick={() => {
                            navigate(`/listing/${item.id}`);
                          }}
                        />
                      </ButtonBase>
                      <ButtonBase
                        onClick={() => {
                          console.log("Love Clicked");
                        }}
                      >
                        <img
                          style={{
                            position: "absolute",
                            top: 12,
                            right: 12,
                          }}
                          alt="love"
                          src="/src/assets/love.svg"
                        />
                      </ButtonBase>
                    </Box>
                    <Stack
                      style={{
                        marginLeft: 12,
                        marginRight: 12,
                      }}
                      onClick={() => {
                        console.log(item.id);
                      }}
                    >
                      <Stack direction="row">
                        <img width={16} alt="star" src="/src/assets/star.svg" />
                        <Typography
                          align="center"
                          fontSize={12}
                          color="#7F7F7F"
                          style={{
                            marginLeft: 4,
                          }}
                        >
                          4.9 (207)
                        </Typography>
                      </Stack>
                      <Typography
                        fontSize={16}
                        fontFamily="AvertaStd-Bold"
                        noWrap
                        style={{
                          marginTop: 6,
                        }}
                      >
                        Oxford, Ms Student complex, Oxford, Ms
                      </Typography>
                      <Stack
                        direction="row"
                        style={{
                          height: 24,
                          width: 82,
                          backgroundColor: "#D4D3EB",
                          borderRadius: 6,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img alt="usd" src="/src/assets/usd.svg" />
                        <Typography
                          fontSize={11}
                          color="#9490DB"
                          style={{
                            marginLeft: 4,
                          }}
                        >
                          Promotion
                        </Typography>
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={0.3}
                        style={{
                          marginTop: 10,
                        }}
                      >
                        <img alt="subway" src="/src/assets/icons/subway.svg" />
                        <img
                          alt="utensils"
                          src="/src/assets/icons/utensils.svg"
                        />
                      </Stack>
                      <Stack
                        direction="row"
                        style={{
                          marginTop: 10,
                        }}
                      >
                        <Typography
                          fontSize={18}
                          fontFamily="AvertaStd-Bold"
                          color="#231F20"
                        >
                          Starting at $599
                        </Typography>
                        <Typography
                          fontSize={12}
                          color="#949494"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          /month
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Card> */}
              </Grid>
            );
          })}
          <Grid item>
            <Card
              color="#F3F3F3"
              style={{
                width: 280,
                height: 340,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              sx={{
                borderRadius: 5,
              }}
            >
              <Stack>
                <Typography
                  color="#231F20"
                  align="center"
                  width={200}
                  fontSize={20}
                  fontFamily="AvertaStd-Bold"
                >
                  Want more Listings like this.?
                </Typography>
                <ButtonBase
                  style={{
                    marginTop: 16,
                    width: 135,
                    height: 42,
                    borderRadius: 20,
                    marginLeft: 30,
                  }}
                  onClick={() => {
                    navigate("/rentals");
                  }}
                >
                  <Box
                    style={{
                      width: 135,
                      height: 42,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    sx={{
                      border: 1,
                      borderColor: "#9490DB",
                      borderRadius: 10,
                    }}
                  >
                    <Typography
                      fontSize={14}
                      fontFamily="AvertaStd-semibold"
                      color="primary"
                    >
                      Explore More
                    </Typography>
                  </Box>
                </ButtonBase>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Stack>
      <Box
        margin="1rem 6%"
        style={{
          marginTop: 80,
          marginBottom: 40,
          height: 450,
          borderRadius: 30,
        }}
        backgroundColor="#EFEDF7"
      >
        <Grid container>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Stack
              style={{
                marginLeft: 80,
                marginTop: isNonMobile ? 100 : 80,
              }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "left",
                }}
              >
                <Box
                  style={{
                    width: 230,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  sx={{
                    backgroundColor: "white",
                    borderRadius: 10,
                  }}
                >
                  <Typography
                    fontSize={14}
                    fontFamily="AvertaStd-semibold"
                    color="primary"
                    style={{
                      marginLeft: 16,
                      marginRight: 16,
                      marginTop: 8,
                      marginBottom: 8,
                    }}
                  >
                    LOOKING FOR SOME HELP?
                  </Typography>
                </Box>
              </Box>
              <Typography
                fontSize={isNonMobile ? 48 : 30}
                fontFamily="AvertaStd-Bold"
              >
                Have a question or feedback?
              </Typography>
              <Typography fontSize={16}>
                Have questions or want help creating your account or listing?
              </Typography>
              <ButtonBase
                style={{
                  marginTop: 26,
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "left",
                }}
              >
                <Box
                  style={{
                    width: 140,
                    height: 48,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  sx={{
                    backgroundColor: "#9490DB",
                    borderRadius: 10,
                  }}
                >
                  <Typography
                    fontSize={14}
                    fontFamily="AvertaStd-semibold"
                    color="white"
                  >
                    Contact us
                  </Typography>
                </Box>
              </ButtonBase>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <img
              style={{
                height: 450,
                width: "100%",
                marginTop: isNonMobile ? 70 : 180,
              }}
              alt="contactUs"
              src="/assets/ContactUs.svg"
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Home;
