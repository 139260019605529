import {
  Avatar,
  Box,
  ButtonBase,
  Card,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  Paper,
  Rating,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import FlexBetween from "../../components/FlexBetween";
import React, { useEffect, useMemo, useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { appColors } from "../../theme";
import { useDispatch, useSelector } from "../../state/store";
import { getHousingReview, getSingleHousing } from "../../state/slices/froomie";
import { IMAGE_BASE_URL, MAP_KEY } from "../../utils/constants";
import { format } from "date-fns";
import axiosInstance from "../../utils/axios";
import { useSnackbar } from "notistack";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import useAuth from "../../hooks/useAuth";
import Popup from "../../components/Popup";
import ListingItem from "../../components/ListingItem";

const ListingDetails = () => {
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { housing, similarHousings } = useSelector((state) => state.froomie);
  const { reviews } = useSelector((state) => state.froomie);
  const { id } = useParams();
  const [isPromotion, setIsPromotion] = useState(false);
  const [isReview, setIsReview] = useState(false);
  const [rating, setRating] = useState(0);
  const [headline, setHeadline] = useState("");
  const [reviewDetails, setReviewDetails] = useState("");
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);

  function get_youtube_thumbnail(url, quality) {
    if (url) {
      var video_id, thumbnail, result;
      if ((result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/))) {
        video_id = result.pop();
      } else if ((result = url.match(/youtu.be\/(.{11})/))) {
        video_id = result.pop();
      }

      if (video_id) {
        if (typeof quality == "undefined") {
          quality = "high";
        }

        var quality_key = "maxresdefault"; // Max quality
        if (quality === "low") {
          quality_key = "sddefault";
        } else if (quality === "medium") {
          quality_key = "mqdefault";
        } else if (quality === "high") {
          quality_key = "hqdefault";
        }

        thumbnail =
          "http://img.youtube.com/vi/" + video_id + "/" + quality_key + ".jpg";
        return thumbnail;
      }
    }
    return false;
  }

  useEffect(() => {
    dispatch(getSingleHousing(id));
    dispatch(getHousingReview(id));
  }, [dispatch, id]);

  const favoriteClick = async () => {
    if (!isAuthenticated) {
      enqueueSnackbar("You need to Login.", { variant: "error" });
      return;
    }
    const data = {
      housing: id,
    };

    if (housing?.isFavorite) {
      try {
        const options = {
          method: "POST",
          url: "/favorite/unFavoriteHousing",
          data,
        };
        const response = await axiosInstance.request(options);
        if (response.data.status) {
          dispatch(getSingleHousing(id));
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar("Server Error", { variant: "error" });
      }
    } else {
      console.log(data);
      try {
        const options = {
          method: "POST",
          url: "/favorite/favoriteHousing",
          data,
        };
        const response = await axiosInstance.request(options);
        if (response.data.status) {
          dispatch(getSingleHousing(id));
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar("Server Error", { variant: "error" });
      }
    }
  };

  const postReview = async () => {
    let data = {
      housing: id,
      reviewTitle: headline,
      reviewDescription: reviewDetails,
      rating: rating,
    };

    try {
      const options = {
        method: "POST",
        url: "/review/postReviewForHousing",
        data,
      };
      const response = await axiosInstance.request(options);
      if (response.data.status) {
        setIsReview(false);
        reloadReview();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Server Error", { variant: "error" });
    }
  };

  const reloadReview = () => {
    dispatch(getHousingReview(id));
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: MAP_KEY,
  });

  const center = useMemo(
    () => ({ lat: housing?.lat, lng: housing?.lng }),
    [housing?.lat, housing?.lng]
  );

  return (
    <div
      style={{
        backgroundColor: "white",
      }}
    >
      <Stack
        padding="1rem 6%"
        style={{
          paddingTop: 24,
        }}
      >
        <Grid
          container
          spacing={isNonMobile ? 5 : 2}
          style={{
            height: {
              md: 650,
            },
          }}
        >
          <Grid item xs={12} md={5}>
            <Box
              style={{
                width: "100%",
              }}
              sx={{
                height: {
                  xs: 300,
                  md: 650,
                },
              }}
            >
              <img
                style={{
                  objectFit: "cover",
                  borderTopLeftRadius: 15,
                  borderBottomLeftRadius: isNonMobile ? 15 : 0,
                  borderTopRightRadius: isNonMobile ? 0 : 15,
                  width: "100%",
                  height: "100%",
                }}
                alt="test"
                src={IMAGE_BASE_URL + housing?.photos[0]}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box
              style={{
                width: "100%",
              }}
            >
              <Grid
                container
                style={{
                  height: "100%",
                  width: "100%",
                }}
              >
                <Grid
                  item
                  xs={6}
                  style={{
                    paddingRight: isNonMobile ? 20 : 5,
                  }}
                >
                  <Box
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <Grid
                      container
                      direction="column"
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingBottom: 20,
                        }}
                      >
                        <Box
                          style={{
                            height: isNonMobile ? 305 : 152,
                            width: "100%",
                          }}
                        >
                          <img
                            style={{
                              objectFit: "cover",
                              width: "100%",
                              height: "100%",
                            }}
                            alt="test"
                            src={IMAGE_BASE_URL + housing?.photos[1]}
                          />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingTop: isNonMobile ? 20 : 0,
                        }}
                      >
                        <Box
                          style={{
                            height: isNonMobile ? 305 : 152,
                            width: "100%",
                            marginBottom: isNonMobile ? 0 : 20,
                          }}
                        >
                          <img
                            style={{
                              objectFit: "cover",
                              width: "100%",
                              height: "100%",
                              borderBottomLeftRadius: isNonMobile ? 0 : 15,
                            }}
                            alt="test"
                            src={IMAGE_BASE_URL + housing?.photos[2]}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    paddingLeft: 20,
                  }}
                >
                  <Box
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <Grid
                      container
                      direction="column"
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingBottom: 20,
                        }}
                      >
                        <Box
                          style={{
                            height: isNonMobile ? 305 : 152,
                            width: "100%",
                          }}
                        >
                          <img
                            style={{
                              objectFit: "cover",
                              width: "100%",
                              height: "100%",
                              borderTopRightRadius: isNonMobile ? 15 : 0,
                            }}
                            alt="test"
                            src={IMAGE_BASE_URL + housing?.photos[3]}
                          />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingTop: isNonMobile ? 20 : 0,
                        }}
                      >
                        <Box
                          style={{
                            height: isNonMobile ? 305 : 152,
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            position: "relative",
                          }}
                        >
                          <img
                            style={{
                              objectFit: "cover",
                              borderBottomRightRadius: 15,
                              width: "100%",
                              height: "100%",
                            }}
                            alt="test"
                            src={IMAGE_BASE_URL + housing?.photos[4]}
                          />
                          <ButtonBase
                            style={{
                              position: "absolute",
                              bottom: 12,
                              right: 12,
                              width: 154,
                              height: 42,
                              borderRadius: 21,
                            }}
                            onClick={() => setOpenSuccessPopup(true)}
                          >
                            <Box
                              style={{
                                width: 154,
                                height: 42,
                                backgroundColor: "white",
                                borderRadius: 21,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Stack direction="row">
                                <img
                                  alt="flower"
                                  src="/assets/icons/u_flower.svg"
                                />
                                <Typography
                                  fontSize={12}
                                  fontFamily="AvertaStd-semibold"
                                  color="#9490DB"
                                  align="center"
                                  style={{
                                    marginLeft: 4,
                                    marginTop: 2,
                                  }}
                                >
                                  See more photos
                                </Typography>
                              </Stack>
                            </Box>
                          </ButtonBase>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={20}
          style={{
            marginTop: 44,
          }}
        >
          <Grid item xs={12} md={7}>
            <Stack spacing={1}>
              <Stack direction="row" onClick={() => setIsPromotion(true)}>
                <img width={16} alt="star" src="/assets/star.svg" />
                <Typography
                  align="center"
                  fontSize={16}
                  fontFamily="AvertaStd-semibold"
                  color="#231F20"
                  style={{
                    marginLeft: 4,
                  }}
                >
                  {housing?.reviewsAverage?.toFixed(2)} (
                  {housing?.numberOfReviews} Reviews)
                </Typography>
              </Stack>
              <Typography
                fontSize={32}
                fontFamily="AvertaStd-Bold"
                color="#231F20"
              >
                {housing?.title}
              </Typography>
              <Typography fontSize={20} color="#4E4547">
                {housing?.address}
              </Typography>
              <Divider color="#E0E0E0" />
              <Typography
                style={{
                  marginTop: 32,
                }}
                fontSize={16}
                color="#4E4547"
              >
                {housing?.description}
              </Typography>
              <Link underline="none" component={RouterLink} to="">
                <Typography
                  color="primary"
                  fontSize={14}
                  fontFamily="AvertaStd-semibold"
                  style={{
                    marginTop: 20,
                  }}
                >
                  Learn more
                </Typography>
              </Link>
              <Divider
                color="#E0E0E0"
                style={{
                  marginTop: 32,
                }}
              />
              <Typography
                fontSize={20}
                fontFamily="AvertaStd-Bold"
                color={appColors.primaryTextColor}
                style={{
                  marginTop: 32,
                }}
              >
                Location
              </Typography>
              <Stack direction="row">
                <img
                  alt="location"
                  src="/assets/icons/u_location-pin-alt.svg"
                />
                <Typography
                  fontSize={14}
                  color={appColors.primaryTextColor}
                  style={{
                    marginLeft: 4,
                  }}
                >
                  3 Mi from campus
                </Typography>
              </Stack>
              <Box
                style={{
                  height: 400,
                  width: "100%",
                  borderRadius: 20,
                }}
              >
                {!isLoaded ? (
                  <h1>Loading...</h1>
                ) : (
                  <GoogleMap
                    mapContainerClassName="map-container"
                    center={center}
                    zoom={10}
                  >
                    <MarkerF
                      position={{
                        lat: housing?.lat,
                        lng: housing?.lng,
                      }}
                      icon={"/assets/mapMarker.svg"}
                    />
                  </GoogleMap>
                )}
              </Box>
              <Divider
                color="#E0E0E0"
                style={{
                  marginTop: 32,
                }}
              />
              <Typography
                fontSize={20}
                fontFamily="AvertaStd-Bold"
                color={appColors.primaryTextColor}
                style={{
                  marginTop: 32,
                }}
              >
                Floor plans
              </Typography>
              <List
                style={{
                  display: "flex",
                  flexDirection: "column",
                  maxHeight: "100%",
                  overflow: "auto",
                }}
              >
                {housing?.floorPlans.map((floorPlan) => {
                  return (
                    <ListItem
                      key={floorPlan}
                      style={{
                        marginRight: 8,
                      }}
                    >
                      <Box
                        style={{
                          width: "100%",
                        }}
                        sx={{
                          overflow: "auto",
                        }}
                      >
                        <List
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            maxHeight: "100%",
                            overflow: "auto",
                          }}
                        >
                          {floorPlan?.floorImage?.map((item, index) => {
                            return (
                              <ListItem
                                key={item}
                                style={{
                                  marginRight: 8,
                                }}
                              >
                                <Paper
                                  elevation={2}
                                  style={{
                                    height: 135,
                                    width: 235,
                                    borderRadius: 15,
                                    paddingTop: 15,
                                  }}
                                >
                                  <Stack>
                                    <img
                                      style={{
                                        objectFit: "cover",
                                        height: 72,
                                      }}
                                      alt={`plan${index}`}
                                      src={`${IMAGE_BASE_URL}${item}`}
                                    />
                                    <FlexBetween>
                                      <Stack
                                        direction="row"
                                        style={{
                                          marginLeft: 12,
                                          marginRight: 12,
                                        }}
                                      >
                                        <img
                                          alt="pdf"
                                          src="/assets/icons/pdf.svg"
                                        />
                                        <Typography
                                          color="black"
                                          fontSize={14}
                                          fontFamily="AvertaStd-semibold"
                                          style={{
                                            marginLeft: 4,
                                          }}
                                        >
                                          Floor plan
                                        </Typography>
                                      </Stack>
                                      <ButtonBase>
                                        <img
                                          alt="save"
                                          src="/assets/icons/save.svg"
                                        />
                                      </ButtonBase>
                                    </FlexBetween>
                                  </Stack>
                                </Paper>
                              </ListItem>
                            );
                          })}
                        </List>
                      </Box>
                      ;
                    </ListItem>
                  );
                })}
              </List>
              ;
              <Divider
                color="#E0E0E0"
                style={{
                  marginTop: 32,
                }}
              />
              <Typography
                fontSize={20}
                fontFamily="AvertaStd-Bold"
                color={appColors.primaryTextColor}
                style={{
                  marginTop: 32,
                }}
              >
                What this place offers
              </Typography>
              {(() => {
                const offers = housing?.amenities?.split(",");
                return (
                  <Grid
                    container
                    style={{
                      marginTop: 22,
                    }}
                  >
                    {offers?.map((item, index) => {
                      return (
                        <Grid item xs={6} key={index}>
                          <Stack
                            direction="row"
                            style={{
                              height: 40,
                            }}
                          >
                            {/* <img
                          alt="wifi"
                          src="/assets/icons/u_wifi.svg"
                          style={{
                            width: 20,
                            height: 20,
                          }}
                        /> */}
                            <Typography color={appColors.primaryTextColor}>
                              {item}
                            </Typography>
                          </Stack>
                        </Grid>
                      );
                    })}
                  </Grid>
                );
              })()}
              {housing?.videoUrl && (
                <Stack>
                  <Divider
                    color="#E0E0E0"
                    style={{
                      marginTop: 32,
                    }}
                  />
                  <Typography
                    fontSize={20}
                    fontFamily="AvertaStd-Bold"
                    color={appColors.primaryTextColor}
                    style={{
                      marginTop: 32,
                    }}
                  >
                    Video tour
                  </Typography>
                  <Typography fontSize={16} color={appColors.darkGrayTexColor}>
                    {housing?.videoUrl}
                  </Typography>
                  <ButtonBase
                    onClick={() => {
                      window.location.href = housing?.videoUrl;
                    }}
                  >
                    <Box
                      style={{
                        height: 350,
                        width: "100%",
                        borderRadius: 20,
                        marginTop: 24,
                      }}
                    >
                      <img
                        style={{
                          objectFit: "cover",
                          borderRadius: 15,
                          width: "100%",
                          height: "100%",
                        }}
                        alt="videoThumb"
                        src={get_youtube_thumbnail(
                          `${housing?.videoUrl}`,
                          "max"
                        )}
                      />
                      <img
                        style={{
                          position: "absolute",
                          left: "44%",
                          top: 160,
                        }}
                        alt="videoPlay"
                        src="/assets/videoPlay.svg"
                      />
                    </Box>
                  </ButtonBase>
                </Stack>
              )}
              <Divider
                color="#E0E0E0"
                style={{
                  marginTop: 32,
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={5}>
            <Paper
              style={{
                borderRadius: 20,
                marginTop: isNonMobile ? 0 : 32,
              }}
              sx={{
                border: 1,
                borderColor: "#E0E0E0",
              }}
            >
              <Stack
                style={{
                  margin: 25,
                }}
              >
                <FlexBetween>
                  <Stack>
                    <Typography
                      fontSize={16}
                      color={appColors.primaryTextColor}
                    >
                      Starting from
                    </Typography>
                    <Stack direction="row">
                      <Typography
                        fontSize={24}
                        fontFamily="AvertaStd-Bold"
                        color={appColors.primaryTextColor}
                      >
                        $
                        {housing?.minRent === undefined
                          ? housing?.floorPlans[0]?.minRent
                          : housing?.minRent}
                      </Typography>
                      <Typography
                        fontSize={14}
                        color={appColors.grayTextColor}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        /month
                      </Typography>
                    </Stack>
                  </Stack>
                  <Avatar
                    sx={{ width: 50, height: 50 }}
                    alt="profile"
                    src={IMAGE_BASE_URL + housing?.photos[0]}
                  />
                </FlexBetween>
                <Divider
                  color="#E0E0E0"
                  style={{
                    marginTop: 22,
                  }}
                />
                <Typography
                  fontSize={14}
                  color={appColors.primaryTextColor}
                  style={{
                    marginTop: 22,
                  }}
                >
                  Managed by:
                </Typography>
                <Stack
                  direction="row"
                  style={{
                    marginTop: 12,
                  }}
                >
                  <Avatar
                    sx={{ width: 50, height: 50 }}
                    alt="profile"
                    src={IMAGE_BASE_URL + housing?.host?.photo}
                  />
                  <Stack
                    style={{
                      marginLeft: 10,
                    }}
                  >
                    <Typography
                      fontSize={20}
                      fontFamily="AvertaStd-Bold"
                      color={appColors.primaryTextColor}
                    >
                      {housing?.host?.name}
                    </Typography>
                    <Typography
                      fontSize={14}
                      color={appColors.darkGrayTexColor}
                    >
                      Listing Manager
                    </Typography>
                  </Stack>
                </Stack>
                <Typography
                  fontSize={16}
                  color={appColors.darkGrayTexColor}
                  style={{
                    marginTop: 18,
                  }}
                >
                  leasing@froomie.io
                </Typography>
                <Divider
                  color="#E0E0E0"
                  style={{
                    marginTop: 22,
                  }}
                />
                <ButtonBase
                  style={{
                    marginTop: 20,
                    borderRadius: 24,
                  }}
                  onClick={() => {
                    navigate("/personalinformation", {
                      state: {
                        id: housing.id,
                      },
                    });
                  }}
                >
                  <Box
                    style={{
                      height: 48,
                      width: "100%",
                      borderRadius: 24,
                      backgroundColor: "#9490DB",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      color="white"
                      fontSize={14}
                      fontFamily="AvertaStd-semibold"
                    >
                      Contact
                    </Typography>
                  </Box>
                </ButtonBase>
                <ButtonBase
                  style={{
                    marginTop: 20,
                    borderRadius: 24,
                  }}
                  onClick={favoriteClick}
                >
                  <Box
                    style={{
                      height: 48,
                      width: "100%",
                      borderRadius: 24,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: housing?.isFavorite
                        ? "#9490DB"
                        : "#FFFFFF",
                    }}
                    sx={{
                      border: 1,
                      borderColor: "#9490DB",
                    }}
                  >
                    <Stack direction="row" spacing={1}>
                      <img alt="love" src="/assets/icons/lovePurple.svg" />
                      <Typography
                        color={housing?.isFavorite ? "#FFFFFF" : "primary"}
                        fontSize={14}
                        fontFamily="AvertaStd-semibold"
                      >
                        {housing?.isFavorite
                          ? "Remove from Favorites"
                          : "Add to Favorites"}
                      </Typography>
                    </Stack>
                  </Box>
                </ButtonBase>
              </Stack>
            </Paper>
            <Paper
              style={{
                borderRadius: 20,
                marginTop: 20,
              }}
              sx={{
                border: 1,
                borderColor: "#E0E0E0",
              }}
            >
              <Stack
                direction="row"
                spacing={3}
                style={{
                  margin: 25,
                }}
              >
                <img alt="mike" src="/assets/icons/mike.svg" />
                <Stack>
                  <Typography
                    fontSize={18}
                    fontFamily="AvertaStd-Bold"
                    color={appColors.primaryTextColor}
                  >
                    Promotion:
                  </Typography>
                  <Typography fontSize={14} color={appColors.darkGrayTexColor}>
                    {housing?.promotions}
                  </Typography>
                </Stack>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
        <Typography
          fontSize={20}
          fontFamily="AvertaStd-Bold"
          color={appColors.primaryTextColor}
          style={{
            marginTop: 32,
          }}
        >
          More places like this
        </Typography>
        <Grid
          container
          justifyContent="center"
          spacing={2}
          maxWidth={1200}
          style={{
            marginTop: 30,
            marginBottom: 20,
          }}
        >
          {similarHousings?.map((item) => {
            return (
              <Grid
                key={item?.id}
                item
                style={{
                  marginBottom: 10,
                }}
              >
                <ListingItem item={item} />
              </Grid>
            );
          })}
          <Grid item>
            <Card
              color="#F3F3F3"
              style={{
                width: 280,
                height: 340,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              sx={{
                borderRadius: 5,
              }}
            >
              <Stack
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  color="#231F20"
                  align="center"
                  width={200}
                  fontSize={20}
                  fontFamily="AvertaStd-Bold"
                >
                  Want more Listings like this.?
                </Typography>
                <Box
                  style={{
                    marginTop: 16,
                    width: 135,
                    height: 42,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  sx={{
                    border: 1,
                    borderColor: "#9490DB",
                    borderRadius: 10,
                  }}
                >
                  <ButtonBase
                    style={{
                      width: 135,
                      height: 42,
                      borderRadius: 25,
                    }}
                    onClick={() => {
                      navigate("/rentals");
                    }}
                  >
                    <Typography
                      fontSize={14}
                      fontFamily="AvertaStd-semibold"
                      color="primary"
                    >
                      Explore More
                    </Typography>
                  </ButtonBase>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Stack>
      {isPromotion && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            top: 0,
            right: 0,
            width: "100%",
            textAlign: "center",
          }}
        >
          <Stack
            direction="row"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <Box
              style={{
                width: "100%",
                backgroundColor: appColors.primaryTextColor,
                opacity: 0.3,
              }}
            />
            <Box
              style={{
                width: 650,
                backgroundColor: "white",
                height: "100%",
              }}
            >
              <ButtonBase
                style={{
                  width: 32,
                  height: 32,
                  position: "absolute",
                  top: 40,
                  right: 30,
                }}
                onClick={() => setIsPromotion(false)}
              >
                <img
                  width={32}
                  height={32}
                  alt="x-Circle"
                  src="/assets/icons/fi_x-circle.svg"
                />
              </ButtonBase>
              {isReview ? (
                <Box>
                  <ButtonBase
                    style={{
                      marginTop: 36,
                      height: 50,
                      borderRadius: 25,
                      paddingLeft: 20,
                      paddingRight: 20,
                      position: "absolute",
                      bottom: 50,
                      right: 150,
                      width: 160,
                      backgroundColor: "#9490DB",
                    }}
                    onClick={() => {
                      postReview();
                    }}
                  >
                    <Typography
                      fontSize={14}
                      fontFamily="AvertaStd-semibold"
                      color="white"
                    >
                      Submit review
                    </Typography>
                  </ButtonBase>
                  <Stack
                    style={{
                      marginLeft: 40,
                      marginRight: 40,
                      marginTop: 90,
                    }}
                    align="left"
                  >
                    <Typography
                      align="left"
                      fontSize={32}
                      fontFamily="AvertaStd-Bold"
                      color={appColors.primaryTextColor}
                    >
                      Property Ratings
                    </Typography>
                    <Typography
                      fontSize={16}
                      fontFamily="AvertaStd-semibold"
                      color="#231F20"
                      style={{
                        marginLeft: 4,
                      }}
                    >
                      Leave an anonymous review for the standard apartments
                    </Typography>
                    <Stack
                      direction="row"
                      style={{
                        marginTop: 16,
                      }}
                    >
                      <Rating
                        style={{
                          height: 50,
                          marginTop: 10,
                        }}
                        name="size-large"
                        defaultValue={0}
                        size="large"
                        onChange={(event) => {
                          setRating(event.target.value);
                        }}
                        value={rating}
                      />
                      <Box
                        style={{
                          height: 50,
                          width: 100,
                          borderRadius: 25,
                          marginLeft: 12,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        sx={{
                          border: 1,
                          borderColor: "#E0E0E0",
                        }}
                      >
                        <TextField
                          style={{
                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                          fullWidth
                          hiddenLabel
                          id="standard-basic"
                          placeholder="i.g: 0.00"
                          variant="standard"
                          type="number"
                          InputProps={{
                            disableUnderline: true,
                            inputProps: { min: 0, max: 5 },
                          }}
                          fontSize={14}
                          color={appColors.primaryTextColor}
                          value={rating}
                          onChange={(event) => {
                            setRating(event.target.value);
                          }}
                        />
                      </Box>
                    </Stack>
                    <Typography
                      fontSize={16}
                      fontFamily="AvertaStd-semibold"
                      color={appColors.primaryTextColor}
                      style={{
                        marginTop: 30,
                      }}
                    >
                      Headline
                    </Typography>
                    <Box
                      style={{
                        height: 50,
                        marginTop: 16,
                        borderRadius: 25,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      sx={{
                        border: 1,
                        borderColor: "#E0E0E0",
                      }}
                    >
                      <TextField
                        style={{
                          paddingLeft: 20,
                          paddingRight: 20,
                        }}
                        fullWidth
                        hiddenLabel
                        id="standard-basic"
                        placeholder="Headline"
                        variant="standard"
                        InputProps={{ disableUnderline: true }}
                        fontSize={14}
                        color={appColors.primaryTextColor}
                        value={headline}
                        onChange={(event) => {
                          setHeadline(event.target.value);
                        }}
                      />
                    </Box>
                    <Typography
                      fontSize={16}
                      fontFamily="AvertaStd-semibold"
                      color={appColors.primaryTextColor}
                      style={{
                        marginTop: 30,
                      }}
                    >
                      Your Review
                    </Typography>
                    <Paper
                      style={{
                        height: 170,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "white",
                        borderRadius: 16,
                        marginTop: 16,
                      }}
                    >
                      <TextField
                        style={{
                          padding: 20,
                        }}
                        fullWidth
                        hiddenLabel
                        id="standard-basic"
                        placeholder="Your Review"
                        variant="standard"
                        InputProps={{ disableUnderline: true }}
                        fontSize={14}
                        color={appColors.primaryTextColor}
                        multiline
                        minRows={7}
                        value={reviewDetails}
                        onChange={(event) => {
                          setReviewDetails(event.target.value);
                        }}
                      />
                    </Paper>
                  </Stack>
                </Box>
              ) : (
                <Box>
                  <ButtonBase
                    style={{
                      marginTop: 36,
                      height: 50,
                      borderRadius: 25,
                      paddingLeft: 20,
                      paddingRight: 20,
                      position: "absolute",
                      bottom: 50,
                      right: 150,
                      width: 160,
                      backgroundColor: "#9490DB",
                    }}
                    onClick={() => setIsReview(true)}
                  >
                    <Typography
                      fontSize={14}
                      fontFamily="AvertaStd-semibold"
                      color="white"
                    >
                      Write a review
                    </Typography>
                  </ButtonBase>
                  <Stack
                    style={{
                      marginLeft: 40,
                      marginRight: 40,
                      marginTop: 90,
                    }}
                    align="left"
                  >
                    <Typography
                      align="left"
                      fontSize={32}
                      fontFamily="AvertaStd-Bold"
                      color={appColors.primaryTextColor}
                    >
                      Property Ratings
                    </Typography>
                    <Stack direction="row">
                      <Typography
                        fontSize={56}
                        fontFamily="AvertaStd-Bold"
                        color={appColors.primaryTextColor}
                      >
                        {housing?.reviewsAverage?.toFixed(2)}
                      </Typography>
                      {(() => {
                        if (housing?.reviewsAverage > 4.99) {
                          return (
                            <Stack
                              direction="row"
                              style={{
                                marginLeft: 12,
                              }}
                            >
                              <img
                                width={24}
                                alt="star"
                                src="/assets/star.svg"
                              />
                              <img
                                width={24}
                                alt="star"
                                src="/assets/star.svg"
                              />
                              <img
                                width={24}
                                alt="star"
                                src="/assets/star.svg"
                              />
                              <img
                                width={24}
                                alt="star"
                                src="/assets/star.svg"
                              />
                              <img
                                width={24}
                                alt="star"
                                src="/assets/star.svg"
                              />
                            </Stack>
                          );
                        } else if (
                          housing?.reviewsAverage > 4.49 &&
                          housing?.reviewsAverage < 5.0
                        ) {
                          return (
                            <Stack
                              direction="row"
                              style={{
                                marginLeft: 12,
                              }}
                            >
                              <img
                                width={24}
                                alt="star"
                                src="/assets/star.svg"
                              />
                              <img
                                width={24}
                                alt="star"
                                src="/assets/star.svg"
                              />
                              <img
                                width={24}
                                alt="star"
                                src="/assets/star.svg"
                              />
                              <img
                                width={24}
                                alt="star"
                                src="/assets/star.svg"
                              />
                              <img
                                width={24}
                                alt="star"
                                src="/assets/icons/u_star-half.svg"
                              />
                            </Stack>
                          );
                        } else if (
                          housing?.reviewsAverage > 3.99 &&
                          housing?.reviewsAverage < 4.5
                        ) {
                          return (
                            <Stack
                              direction="row"
                              style={{
                                marginLeft: 12,
                              }}
                            >
                              <img
                                width={24}
                                alt="star"
                                src="/assets/star.svg"
                              />
                              <img
                                width={24}
                                alt="star"
                                src="/assets/star.svg"
                              />
                              <img
                                width={24}
                                alt="star"
                                src="/assets/star.svg"
                              />
                              <img
                                width={24}
                                alt="star"
                                src="/assets/star.svg"
                              />
                            </Stack>
                          );
                        } else if (
                          housing?.reviewsAverage > 3.49 &&
                          housing?.reviewsAverage < 4.0
                        ) {
                          return (
                            <Stack
                              direction="row"
                              style={{
                                marginLeft: 12,
                              }}
                            >
                              <img
                                width={24}
                                alt="star"
                                src="/assets/star.svg"
                              />
                              <img
                                width={24}
                                alt="star"
                                src="/assets/star.svg"
                              />
                              <img
                                width={24}
                                alt="star"
                                src="/assets/star.svg"
                              />
                              <img
                                width={24}
                                alt="star"
                                src="/assets/icons/u_star-half.svg"
                              />
                            </Stack>
                          );
                        } else if (
                          housing?.reviewsAverage > 2.99 &&
                          housing?.reviewsAverage < 3.5
                        ) {
                          return (
                            <Stack
                              direction="row"
                              style={{
                                marginLeft: 12,
                              }}
                            >
                              <img
                                width={24}
                                alt="star"
                                src="/assets/star.svg"
                              />
                              <img
                                width={24}
                                alt="star"
                                src="/assets/star.svg"
                              />
                              <img
                                width={24}
                                alt="star"
                                src="/assets/star.svg"
                              />
                            </Stack>
                          );
                        } else if (
                          housing?.reviewsAverage > 2.49 &&
                          housing?.reviewsAverage < 3.0
                        ) {
                          return (
                            <Stack
                              direction="row"
                              style={{
                                marginLeft: 12,
                              }}
                            >
                              <img
                                width={24}
                                alt="star"
                                src="/assets/star.svg"
                              />
                              <img
                                width={24}
                                alt="star"
                                src="/assets/star.svg"
                              />
                              <img
                                width={24}
                                alt="star"
                                src="/assets/icons/u_star-half.svg"
                              />
                            </Stack>
                          );
                        } else if (
                          housing?.reviewsAverage > 1.99 &&
                          housing?.reviewsAverage < 2.5
                        ) {
                          return (
                            <Stack
                              direction="row"
                              style={{
                                marginLeft: 12,
                              }}
                            >
                              <img
                                width={24}
                                alt="star"
                                src="/assets/star.svg"
                              />
                              <img
                                width={24}
                                alt="star"
                                src="/assets/star.svg"
                              />
                            </Stack>
                          );
                        } else if (
                          housing?.reviewsAverage > 1.49 &&
                          housing?.reviewsAverage < 2.0
                        ) {
                          return (
                            <Stack
                              direction="row"
                              style={{
                                marginLeft: 12,
                              }}
                            >
                              <img
                                width={24}
                                alt="star"
                                src="/assets/star.svg"
                              />
                              <img
                                width={24}
                                alt="star"
                                src="/assets/icons/u_star-half.svg"
                              />
                            </Stack>
                          );
                        } else if (
                          housing?.reviewsAverage > 0.99 &&
                          housing?.reviewsAverage < 1.5
                        ) {
                          return (
                            <Stack
                              direction="row"
                              style={{
                                marginLeft: 12,
                              }}
                            >
                              <img
                                width={24}
                                alt="star"
                                src="/assets/star.svg"
                              />
                            </Stack>
                          );
                        } else if (
                          housing?.reviewsAverage > 0.49 &&
                          housing?.reviewsAverage < 1.0
                        ) {
                          return (
                            <Stack
                              direction="row"
                              style={{
                                marginLeft: 12,
                              }}
                            >
                              <img
                                width={24}
                                alt="star"
                                src="/assets/icons/u_star-half.svg"
                              />
                            </Stack>
                          );
                        }
                      })()}
                    </Stack>
                    <Typography
                      fontSize={20}
                      fontFamily="AvertaStd-semibold"
                      color="#231F20"
                      style={{
                        marginLeft: 4,
                      }}
                    >
                      {housing?.numberOfReviews} Reviews
                    </Typography>
                  </Stack>
                  <Divider
                    style={{
                      marginTop: 16,
                    }}
                    color="#E0E0E0"
                  />
                  <List
                    style={{
                      overflow: "auto",
                      height: 570,
                    }}
                  >
                    {reviews?.map((item) => {
                      return (
                        <Box key={item}>
                          <Stack
                            direction="row"
                            style={{
                              marginTop: 24,
                              marginLeft: 40,
                            }}
                          >
                            <Avatar
                              sx={{ width: 48, height: 48 }}
                              alt="profile"
                              src={IMAGE_BASE_URL + item?.user?.photo}
                            />
                            <Stack
                              style={{
                                marginLeft: 14,
                              }}
                            >
                              <Typography
                                align="left"
                                fontSize={18}
                                fontFamily="AvertaStd-semibold"
                                color="#231F20"
                              >
                                {item?.user?.name}
                              </Typography>
                              <Typography
                                align="left"
                                fontSize={14}
                                color="#A0A0A0"
                                fontWeight="regular"
                              >
                                {format(new Date(item?.createdAt), "MMMM yyyy")}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Typography
                            align="left"
                            fontSize={14}
                            color="#231F20"
                            fontWeight="regular"
                            style={{
                              marginLeft: 40,
                              marginRight: 40,
                              marginTop: 16,
                            }}
                          >
                            {item?.reviewDescription}
                          </Typography>
                        </Box>
                      );
                    })}
                  </List>
                </Box>
              )}
            </Box>
          </Stack>
        </div>
      )}
      <Popup openPopup={openSuccessPopup} setOpenPopup={setOpenSuccessPopup}>
        <div
          style={{
            width: 600,
          }}
        >
          <Stack>
            <ButtonBase
              style={{
                width: 40,
                height: 40,
                position: "absolute",
                top: 16,
                right: 16,
              }}
              onClick={() => setOpenSuccessPopup(false)}
            >
              <img alt="x_circle" src="/assets/icons/fi_x-circle.svg" />
            </ButtonBase>
            <Grid
              container
              justifyContent="center"
              spacing={2}
              maxWidth={600}
              style={{
                marginTop: 30,
              }}
            >
              {housing?.photos.map((photo, index) => {
                return (
                  <Grid
                    key={index}
                    item
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    <Box
                      style={{
                        width: "100%",
                      }}
                      sx={{
                        height: {
                          xs: 300,
                          md: 650,
                        },
                      }}
                    >
                      <img
                        style={{
                          objectFit: "cover",
                          borderRadius: 15,
                          margin: 10,
                          width: "100%",
                          height: "100%",
                        }}
                        alt={`photos${index}`}
                        src={IMAGE_BASE_URL + photo}
                      />
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Stack>
        </div>
      </Popup>
    </div>
  );
};

export default ListingDetails;
