import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const Terms = () => {
  return (
    <>
      <Box
        padding="1rem 6%"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack>
          <Typography
            align="center"
            fontSize={40}
            fontFamily="AvertaStd-Bold"
            style={{
              color: "#000000",
              marginTop: 40,
            }}
          >
            END USER LICENSE AGREEMENT
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            Last updated July 25, 2023
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            Froomie is licensed to You (End-User) by Froomie LLC, located at
            __________, Austin, Texas __________, United States (hereinafter:
            Licensor), for use only under the terms of this License Agreement.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            By downloading the Application from the Apple AppStore or using the
            application via our website, and any update thereto (as permitted by
            this License Agreement), You indicate that You agree to be bound by
            all of the terms and conditions of this License Agreement, and that
            You accept this License Agreement.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            The parties of this License Agreement acknowledge that Apple is not
            a Party to this License Agreement and is not bound by any provisions
            or obligations with regard to the Application, such as warranty,
            liability, maintenance and support thereof. Froomie LLC, not Apple,
            is solely responsible for the licensed Application and the content
            thereof.
          </Typography>
          <Typography
            component="div"
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            This License Agreement may not provide for usage rules for the
            Application that are in conflict with the latest{" "}
            <Link
              to={
                "https://www.apple.com/legal/internet-services/itunes/us/terms.html"
              }
              display="inline"
            >
              App Store Terms of Service
            </Link>{" "}
            Froomie LLC acknowledges that it had the opportunity to review said
            terms and this License Agreement is not conflicting with them.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            All rights not expressly granted to You are reserved.
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={24}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            1. THE APPLICATION
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            Froomie (hereinafter: Application) is a piece of software created to
            facilitate real estate leasing and roommate searching. - and
            customized for Apple mobile devices, Android mobile devices, and
            desktops, It is used to view real estate listings, post real estate
            listings, and find roommates.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            The Application is not tailored to comply with industry-specific
            regulations (Health Insurance Portability and Accountability Act
            (HIPAA), Federal Information Security Management Act (FISMA), etc.),
            so if your interactions would be subjected to such laws, you may not
            use this Application. You may not use the Application in a way that
            would violate the Gramm-Leach-Bliley Act (GLBA).
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={24}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            2. SCOPE OF LICENSE
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            2.1 You are given a non-transferable, non-exclusive,
            non-sublicensable license to install and use the Licensed
            Application on any Apple-branded Products that You (End-User) own or
            control and as permitted by the Usage Rules set forth in this
            section and the App Store Terms of Service, with the exception that
            such licensed Application may be accessed and used by other accounts
            associated with You (End-User, The Purchaser) via Family Sharing or
            volume purchasing.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            2.2 This license will also govern any updates of the Application
            provided by Licensor that replace, repair, and/or supplement the
            first Application, unless a separate license is provided for such
            update in which case the terms of that new license will govern.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            2.3 You may not share or make the Application available to third
            parties (unless to the degree allowed by the Apple Terms and
            Conditions, and with Froomie LLC's prior written consent), sell,
            rent, lend, lease or otherwise redistribute the Application.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            2.4 You may not reverse engineer, translate, disassemble, integrate,
            decompile, integrate, remove, modify, combine, create derivative
            works or updates of, adapt, or attempt to derive the source code of
            the Application, or any part thereof (except with Froomie LLC's
            prior written consent).
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            2.5 You may not copy (excluding when expressly authorized by this
            license and the Usage Rules) or alter the Application or portions
            thereof. You may create and store copies only on devices that You
            own or control for backup keeping under the terms of this license,
            the App Store Terms of Service, and any other terms and conditions
            that apply to the device or software used. You may not remove any
            intellectual property notices. You acknowledge that no unauthorized
            third parties may gain access to these copies at any time.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            2.6 Violations of the obligations mentioned above, as well as the
            attempt of such infringement, may be subject to prosecution and
            damages.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            2.7 Licensor reserves the right to modify the terms and conditions
            of licensing.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            2.8 Nothing in this license should be interpreted to restrict
            third-party terms. When using the Application, You must ensure that
            You comply with applicable third-party terms and conditions.
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={24}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            3. TECHNICAL REQUIREMENTS
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            3.1 Licensor attempts to keep the Application updated so that it
            complies with modified/new versions of the firmware and new
            hardware. You are not granted rights to claim such an update.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            3.2 You acknowledge that it is Your responsibility to confirm and
            determine that the app end-user device on which You intend to use
            the Application satisfies the technical specifications mentioned
            above.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            3.3 Licensor reserves the right to modify the technical
            specifications as it sees appropriate at any time.
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={24}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            4. MAINTENANCE AND SUPPORT
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            4.1 The Licensor is solely responsible for providing any maintenance
            and support services for this licensed Application. You can reach
            the Licensor at the email address listed in the App Store Overview
            and website for this licensed Application.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            4.2 Froomie LLC and the End-User acknowledge that Apple has no
            obligation whatsoever to furnish any maintenance and support
            services with respect to the licensed Application.
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={24}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            5. USE OF DATA
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            You acknowledge that Licensor will be able to access and adjust Your
            downloaded licensed Application content and Your personal
            information, and that Licensor's use of such material and
            information is subject to Your legal agreements with Licensor and
            Licensor's privacy policy:
          </Typography>
          <Link
            to={
              "https://app.termly.io/document/privacy-policy/b672b56c-2437-44af-a2cd-a0564b79ed43"
            }
          >
            https://app.termly.io/document/privacy-policy/b672b56c-2437-44af-a2cd-a0564b79ed43
          </Link>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={24}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            6. USER GENERATED CONTRIBUTIONS
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            The Application may invite you to chat, contribute to, or
            participate in blogs, message boards, online forums, and other
            functionality, and may provide you with the opportunity to create,
            submit, post, display, transmit, perform, publish, distribute, or
            broadcast content and materials to us or in the Application,
            including but not limited to text, writings, video, audio,
            photographs, graphics, comments, suggestions, or personal
            information or other material (collectively, "Contributions").
            Contributions may be viewable by other users of the Application and
            through third-party websites or applications. As such, any
            Contributions you transmit may be treated as non-confidential and
            non-proprietary. When you create or make available any
            Contributions, you thereby represent and warrant that:
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
              marginLeft: 12,
            }}
          >
            1. The creation, distribution, transmission, public display, or
            performance, and the accessing, downloading, or copying of your
            Contributions do not and will not infringe the proprietary rights,
            including but not limited to the copyright, patent, trademark, trade
            secret, or moral rights of any third party.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
              marginLeft: 12,
            }}
          >
            2. You are the creator and owner of or have the necessary licenses,
            rights, consents, releases, and permissions to use and to authorize
            us, the Application, and other users of the Application to use your
            Contributions in any manner contemplated by the Application and
            these Terms of Use.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
              marginLeft: 12,
            }}
          >
            3. You have the written consent, release, and/or permission of each
            and every identifiable individual person in your Contributions to
            use the name or likeness or each and every such identifiable
            individual person to enable inclusion and use of your Contributions
            in any manner contemplated by the Application and these Terms of
            Use.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
              marginLeft: 12,
            }}
          >
            4. Your Contributions are not false, inaccurate, or misleading.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
              marginLeft: 12,
            }}
          >
            5. Your Contributions are not unsolicited or unauthorized
            advertising, promotional materials, pyramid schemes, chain letters,
            spam, mass mailings, or other forms of solicitation.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
              marginLeft: 12,
            }}
          >
            6. Your Contributions are not obscene, lewd, lascivious, filthy,
            violent, harassing, libelous, slanderous, or otherwise objectionable
            (as determined by us).
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
              marginLeft: 12,
            }}
          >
            7. Your Contributions do not ridicule, mock, disparage, intimidate,
            or abuse anyone.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
              marginLeft: 12,
            }}
          >
            8. Your Contributions are not used to harass or threaten (in the
            legal sense of those terms) any other person and to promote violence
            against a specific person or class of people.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
              marginLeft: 12,
            }}
          >
            9. Your Contributions do not violate any applicable law, regulation,
            or rule.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
              marginLeft: 12,
            }}
          >
            10. Your Contributions do not violate the privacy or publicity
            rights of any third party.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
              marginLeft: 12,
            }}
          >
            11. Your Contributions do not contain any material that solicits
            personal information from anyone under the age of 18 or exploits
            people under the age of 18 in a sexual or violent manner.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
              marginLeft: 12,
            }}
          >
            12. Your Contributions do not violate any applicable law concerning
            child pornography, or otherwise intended to protect the health or
            well-being of minors.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
              marginLeft: 12,
            }}
          >
            13. Your Contributions do not include any offensive comments that
            are connected to race, national origin, gender, sexual preference,
            or physical handicap.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
              marginLeft: 12,
            }}
          >
            14. Your Contributions do not otherwise violate, or link to material
            that violates, any provision of these Terms of Use, or any
            applicable law or regulation.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            Any use of the Application in violation of the foregoing violates
            these Terms of Use and may result in, among other things,
            termination or suspension of your rights to use the Application.
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={24}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            7. CONTRIBUTION LICENSE
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            By posting your Contributions to any part of the Application or
            making Contributions accessible to the Application by linking your
            account from the Application to any of your social networking
            accounts, you automatically grant, and you represent and warrant
            that you have the right to grant, to us an unrestricted, unlimited,
            irrevocable, perpetual, non-exclusive, transferable, royalty-free,
            fully-paid, worldwide right, and license to host, use copy,
            reproduce, disclose, sell, resell, publish, broad cast, retitle,
            archive, store, cache, publicly display, reformat, translate,
            transmit, excerpt (in whole or in part), and distribute such
            Contributions (including, without limitation, your image and voice)
            for any purpose, commercial advertising, or otherwise, and to
            prepare derivative works of, or incorporate in other works, such as
            Contributions, and grant and authorize sublicenses of the foregoing.
            The use and distribution may occur in any media formats and through
            any media channels.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            This license will apply to any form, media, or technology now known
            or hereafter developed, and includes our use of your name, company
            name, and franchise name, as applicable, and any of the trademarks,
            service marks, trade names, logos, and personal and commercial
            images you provide. You waive all moral rights in your
            Contributions, and you warrant that moral rights have not otherwise
            been asserted in your Contributions.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            We do not assert any ownership over your Contributions. You retain
            full ownership of all of your Contributions and any intellectual
            property rights or other proprietary rights associated with your
            Contributions. We are not liable for any statements or
            representations in your Contributions provided by you in any area in
            the Application. You are solely responsible for your Contributions
            to the Application and you expressly agree to exonerate us from any
            and all responsibility and to refrain from any legal action against
            us regarding your Contributions.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            We have the right, in our sole and absolute discretion, (1) to edit,
            redact, or otherwise change any Contributions; (2) to re-categorize
            any Contributions to place them in more appropriate locations in the
            Application; and (3) to pre-screen or delete any Contributions at
            any time and for any reason, without notice. We have no obligation
            to monitor your Contributions.
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={24}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            8. LIABILITY
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            8.1 Licensor's responsibility in the case of violation of
            obligations and tort shall be limited to intent and gross
            negligence. Only in case of a breach of essential contractual duties
            (cardinal obligations), Licensor shall also be liable in case of
            slight negligence. In any case, liability shall be limited to the
            foreseeable, contractually typical damages. The limitation mentioned
            above does not apply to injuries to life, limb, or health.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            8.2 Licensor takes no accountability or responsibility for any
            damages caused due to a breach of duties according to Section 2 of
            this Agreement. To avoid data loss, You are required to make use of
            backup functions of the Application to the extent allowed by
            applicable third-party terms and conditions of use. You are aware
            that in case of alterations or manipulations of the Application, You
            will not have access to licensed Application.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            8.3 Licensor takes no accountability and responsibility in case of
            The Company, Property Managers, and Universities are not responsible
            for any misinformation through the application such as inaccurate
            pricing, deals, and general listing information. Users must verify
            all information via property management before signing any leasing
            contracts.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            8.4 Licensor takes no accountability and responsibility in case of
            The Company does not represent university administration nor is
            affiliated with universities unless explicitly stated.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            8.5 Licensor takes no accountability and responsibility in case of
            conflict concerning real estate leasing agreements nor roommate, or
            general persons conflicts.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            8.6 Licensor is not a real estate brokerage or agent nor claims to
            be. Licensor does not provide the typical license necessary services
            such as: apartment tours, pricing negotiations, or tenant
            representation. Rather, Licensor is a platform that allows renters
            and property managers to connect at their own accord. We do not
            represent the buyers or sellers.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            8.7 Licensor takes no accountability and responsibility in case of
            conflict concerning falsified profiles.
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={24}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            9. WARRANTY
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            9.1 Licensor warrants that the Application is free of spyware,
            trojan horses, viruses, or any other malware at the time of Your
            download. Licensor warrants that the Application works as described
            in the user documentation.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            9.2 No warranty is provided for the Application that is not
            executable on the device, that has been unauthorizedly modified,
            handled inappropriately or culpably, combined or installed with
            inappropriate hardware or software, used with inappropriate
            accessories, regardless if by Yourself or by third parties, or if
            there are any other reasons outside of Froomie LLC's sphere of
            influence that affect the executability of the Application. nsively
            removed from all our systems (e.g. backups, etc.).
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            9.3 You are required to inspect the Application immediately after
            installing it and notify Froomie LLC about issues discovered without
            delay by e-mail provided in Product Claims. The defect report will
            be taken into consideration and further investigated if it has been
            mailed within a period of twenty one (21) days after discovery.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            9.4 If we confirm that the Application is defective, Froomie LLC
            reserves a choice to remedy the situation either by means of solving
            the defect or substitute delivery.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            9.5 In the event of any failure of the Application to conform to any
            applicable warranty, You may notify the App-Store-Operator, and Your
            Application purchase price will be refunded to You. To the maximum
            extent permitted by applicable law, the App-Store-Operator will have
            no other warranty obligation whatsoever with respect to the App, and
            any other losses, claims, damages, liabilities, expenses and costs
            attributable to any negligence to adhere to any warranty.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            9.6 If the user is an entrepreneur, any claim based on faults
            expires after a statutory period of limitation amounting to twelve
            (12) months after the Application was made available to the user.
            The statutory periods of limitation given by law apply for users who
            are consumers.
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={24}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            10. PRODUCT CLAIMS
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            Froomie LLC and the End-User acknowledge that Froomie LLC, and not
            Apple, is responsible for addressing any claims of the End-User or
            any third party relating to the licensed Application or the
            End-User’s possession and/or use of that licensed Application,
            including, but not limited to:
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
              marginLeft: 10,
            }}
          >
            (i) product liability claims;
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
              marginLeft: 10,
            }}
          >
            (ii) any claim that the licensed Application fails to conform to any
            applicable legal or regulatory requirement; and
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
              marginLeft: 10,
            }}
          >
            (iii) claims arising under consumer protection, privacy, or similar
            legislation, including in connection with Your Licensed
            Application’s use of the HealthKit and HomeKit.
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={24}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            11. LEGAL COMPLIANCE
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            You represent and warrant that You are not located in a country that
            is subject to a U.S. Government embargo, or that has been designated
            by the U.S. Government as a "terrorist supporting" country; and that
            You are not listed on any U.S. Government list of prohibited or
            restricted parties.
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={24}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            12. CONTACT INFORMATION
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            For general inquiries, complaints, questions or claims concerning
            the licensed Application, please contact:
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            Froomie Support
          </Typography>
          <Typography>__________</Typography>
          <Typography>Austin, TX __________</Typography>
          <Typography>United States</Typography>
          <Typography>hello@froomie.io</Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={24}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            13. TERMINATION
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            The license is valid until terminated by Froomie LLC or by You. Your
            rights under this license will terminate automatically and without
            notice from Froomie LLC if You fail to adhere to any term(s) of this
            license. Upon License termination, You shall stop all use of the
            Application, and destroy all copies, full or partial, of the
            Application.
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={24}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            14. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            Froomie LLC represents and warrants that Froomie LLC will comply
            with applicable third-party terms of agreement when using licensed
            Application.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            In Accordance with Section 9 of the "Instructions for Minimum Terms
            of Developer's End-User License Agreement," Apple and Apple's
            subsidiaries shall be third-party beneficiaries of this End User
            License Agreement and - upon Your acceptance of the terms and
            conditions of this license agreement, Apple will have the right (and
            will be deemed to have accepted the right) to enforce this End User
            License Agreement against You as a third-party beneficiary thereof.
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={24}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            15. INTELLECTUAL PROPERTY RIGHTS
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            Froomie LLC and the End-User acknowledge that, in the event of any
            third-party claim that the licensed Application or the End-User's
            possession and use of that licensed Application infringes on the
            third party's intellectual property rights, Froomie LLC, and not
            Apple, will be solely responsible for the investigation, defense,
            settlement and discharge or any such intellectual property
            infringement claims.
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={24}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            16. APPLICABLE LAW
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            This license agreement is governed by the laws of the State of Texas
            excluding its conflicts of law rules.
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={24}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            17. MISCELLANEOUS
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            17.1 If any of the terms of this agreement should be or become
            invalid, the validity of the remaining provisions shall not be
            affected. Invalid terms will be replaced by valid ones formulated in
            a way that will achieve the primary purpose.of the State of Texas
            excluding its conflicts of law rules.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            17.2 Collateral agreements, changes and amendments are only valid if
            laid down in writing. The preceding clause can only be waived in
            writing.
          </Typography>
          <Typography
            fontFamily="AvertaStd-Bold"
            fontSize={24}
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            18. PROPERTY MANAGERS/ LANDLORDS
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            18.1 Licensor provides an online platform that connects Landlords
            and authorized Property Managers (“Customers”) with Renters
            (“Users”). Licensor does not act as a real estate broker or agent
            for you, users, or potential tenants. Therefore, does not represent
            you or another user in the exchange of real estate property,
            including any negotiation thereof.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
            }}
          >
            18.2 Customers that accept our Terms and Conditions are subject to
            paying the agreed upon value upon confirmation of tenant placement.
            Customers who do not adhere to the accepted terms and conditions may
            be subject to account termination on our platforms.
          </Typography>
          <Typography
            style={{
              color: "#000000",
              marginTop: 24,
              marginBottom: 40,
            }}
          >
            18.3 Customers that create an account with us may delete their
            account at any time or request that any of their data be deleted.
          </Typography>
        </Stack>
      </Box>
    </>
  );
};

export default Terms;
