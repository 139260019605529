import {
  Box,
  ButtonBase,
  Divider,
  Grid,
  InputBase,
  List,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../components/useWindowDimensions";
import { appColors } from "../../theme";
import FlexBetween from "../../components/FlexBetween";
import { useDispatch, useSelector } from "../../state/store";
import { IMAGE_BASE_URL } from "../../utils/constants";
import dayjs from "dayjs";
import { getChatList } from "../../state/slices/froomie";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { height } = useWindowDimensions();
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [searchText, setSearchText] = useState("");
  const { chats } = useSelector((state) => state.froomie);
  const [selectedChat, setSelectedChat] = useState(null);

  useEffect(() => {
    dispatch(getChatList());
  }, [dispatch]);

  return (
    <div
      style={{
        background: "#F9F9F9",
      }}
    >
      <Box padding="1rem 6%">
        <Grid
          container
          sx={{
            height: {
              md: height - 240,
            },
          }}
        >
          <Grid item xs={8}>
            <Stack>
              <Typography
                fontSize={32}
                fontFamily="AvertaStd-Bold"
                color={appColors.primaryTextColor}
              >
                Welcome Back!
              </Typography>
              <Typography color="#A0A0A0" fontSize={14}>
                Student housing complex managed by Wendy*Demo Text*
              </Typography>
              <Stack
                direction="row"
                style={{
                  marginTop: 24,
                }}
              >
                <Paper
                  style={{
                    width: 234,
                    height: 100,
                    borderRadius: 16,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Stack direction="row">
                    <img alt="inquiry" src="/assets/inquiry.svg" />
                    <Stack
                      style={{
                        marginLeft: 18,
                      }}
                    >
                      <Typography
                        fontSize={14}
                        fontFamily="AvertaStd-semibold"
                        color="#A0A0A0"
                      >
                        Inquiry Messages
                      </Typography>
                      <Typography
                        color="#0C0B0B"
                        fontSize={30}
                        fontFamily="AvertaStd-semibold"
                      >
                        5
                      </Typography>
                    </Stack>
                  </Stack>
                </Paper>
                <Paper
                  style={{
                    width: 234,
                    height: 100,
                    borderRadius: 16,
                    marginLeft: 28,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Stack direction="row">
                    <img alt="confirm" src="/assets/confirm.svg" />
                    <Stack
                      style={{
                        marginLeft: 18,
                      }}
                    >
                      <Typography
                        fontSize={14}
                        fontFamily="AvertaStd-semibold"
                        color="#A0A0A0"
                      >
                        Confirm tenant
                      </Typography>
                      <Typography
                        color="#0C0B0B"
                        fontSize={30}
                        fontFamily="AvertaStd-semibold"
                      >
                        2
                      </Typography>
                    </Stack>
                  </Stack>
                </Paper>
                <Paper
                  style={{
                    width: 234,
                    height: 100,
                    borderRadius: 16,
                    marginLeft: 28,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Stack direction="row">
                    <img alt="review" src="/assets/review.svg" />
                    <Stack
                      style={{
                        marginLeft: 18,
                      }}
                    >
                      <Typography
                        fontSize={14}
                        fontFamily="AvertaStd-semibold"
                        color="#A0A0A0"
                      >
                        New review
                      </Typography>
                      <Typography
                        color="#0C0B0B"
                        fontSize={30}
                        fontFamily="AvertaStd-semibold"
                      >
                        3
                      </Typography>
                    </Stack>
                  </Stack>
                </Paper>
              </Stack>
            </Stack>
          </Grid>
          <Grid
            xs={4}
            style={{
              backgroundColor: "white",
            }}
          >
            <Stack>
              <Typography
                style={{
                  marginTop: 46,
                  marginLeft: 24,
                }}
                color="#231F20"
                fontSize={20}
                fontFamily="AvertaStd-semibold"
              >
                Recent Messages
              </Typography>
              <FlexBetween
                style={{
                  height: 50,
                  borderRadius: 24,
                  margin: 24,
                }}
                sx={{
                  border: 1,
                  borderColor: "#808080",
                }}
              >
                <Stack
                  direction="row"
                  style={{
                    width: "100%",
                  }}
                >
                  <img
                    alt="search"
                    src="/assets/search2.svg"
                    style={{
                      marginLeft: 20,
                      marginRight: 8,
                    }}
                  />
                  <InputBase
                    style={{
                      paddingRight: 8,
                    }}
                    placeholder="Search..."
                    fullWidth
                    value={searchText}
                    onChange={(event) => {
                      setSearchText(event.target.value);
                    }}
                  />
                </Stack>
                <ButtonBase
                  style={{
                    marginRight: 16,
                  }}
                  onClick={() => {}}
                >
                  <img alt="filter" src="/assets/icons/filter-line.svg" />
                </ButtonBase>
              </FlexBetween>
              <Box
                style={{
                  height: height - 250,
                  marginLeft: 24,
                  marginRight: 24,
                }}
                sx={{
                  overflow: "auto",
                }}
              >
                <List
                  style={{
                    overflow: "auto",
                  }}
                >
                  {chats.map((item) => {
                    return (
                      <Stack
                        key={item?.id}
                        style={{
                          marginTop: 24,
                        }}
                      >
                        <FlexBetween>
                          <Stack direction="row">
                            <img
                              style={{
                                width: 48,
                                height: 48,
                                borderRadius: 24,
                              }}
                              alt={`/listing/${item?.id}`}
                              src={IMAGE_BASE_URL + item?.photo}
                            />
                            <Stack
                              style={{
                                marginLeft: 16,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSelectedChat(item);
                              }}
                            >
                              <Typography
                                fontSize={16}
                                fontFamily="AvertaStd-semibold"
                                color="#9490DB"
                              >
                                {item?.name}
                              </Typography>
                              <Typography
                                fontSize={12}
                                fontFamily="AvertaStd-semibold"
                                color="#21273D"
                                noWrap
                                width={250}
                              >
                                {item?.lastMessage}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Typography
                            fontSize={10}
                            fontFamily="AvertaStd-semibold"
                            color="#9094A3"
                            style={{
                              width: 90,
                              marginLeft: 8,
                            }}
                          >
                            {dayjs(item?.lastMessageDate).format(
                              "MMM DD, YYYY"
                            )}
                          </Typography>
                        </FlexBetween>
                        <Divider
                          style={{
                            marginTop: 24,
                          }}
                        />
                      </Stack>
                    );
                  })}
                </List>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Dashboard;
