import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UserFooter from "../../../components/UserFooter";
import {
  Box,
  ButtonBase,
  Paper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { appColors } from "../../../theme";
import DragDropFiles from "../../../components/DragDropFiles";
import { useSnackbar } from "notistack";
import axiosInstance from "../../../utils/axios";
import useAuth from "../../../hooks/useAuth";

const ProfileSignUp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [files, setFiles] = useState();
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [bio, setBio] = useState("");
  const { user } = useAuth();

  const continueClick = async () => {
    if (files === undefined || files.length !== 3) {
      enqueueSnackbar("Please Upload 3 Photos", { variant: "error" });
    } else {
      const data = new FormData();
      Array.from(files).forEach((item) => {
        data.append("profilePictures", item);
      });
      data.append("livingOption", location.state.living);
      data.append("isNeatFreak", location.state.neatFreak);
      data.append("isNightOwl", location.state.nightOwl);
      data.append("isInRelationShip", location.state.relationship);
      data.append("isSmoker", location.state.weed);
      data.append("likeRoomTempCold", location.state.temperature);
      data.append("major", location.state.major);
      data.append("gendar", location.state.gender);
      data.append("instagram", location.state.instagram);
      data.append("snapchat", location.state.snapchat);
      data.append("tiktok", location.state.tiktok);
      data.append("bio", bio);
      data.append("activities", location.state.activities);
      data.append("hobbies", location.state.hobbies);
      data.append("classYear", user?.graduationYear);

      try {
        const options = {
          method: "POST",
          url: "/roommates/setUpRoommateProfile",
          data,
        };
        const response = await axiosInstance.request(options);
        if (response.data.status) {
          navigate(`/roommate/${response.data.data.roommate.id}`);
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar("Server Error", { variant: "error" });
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          height: "calc(100vh - 110px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          flexDirection="column"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          sx={{
            flex: "1 1 auto",
          }}
        >
          <Stack
            style={{
              marginTop: 38,
              width: 350,
            }}
          >
            <Typography
              fontSize={32}
              fontFamily="AvertaStd-Bold"
              color={appColors.primaryTextColor}
            >
              My roommate profile
            </Typography>
            <Typography fontSize={14} color="#A0A0A0">
              Let’s set up your roommate profile!
            </Typography>
            <Typography
              fontSize={16}
              fontFamily="AvertaStd-semibold"
              color={appColors.primaryTextColor}
              style={{
                marginTop: 32,
              }}
            >
              5. Upload 3 profile pictures: (1/3)
            </Typography>
            <Paper
              style={{
                height: 130,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
                borderRadius: 16,
                marginTop: 16,
              }}
            >
              <DragDropFiles files={files} setFiles={setFiles} />
            </Paper>
            {files && (
              <Stack
                direction="row"
                spacing={2}
                style={{
                  marginTop: 24,
                }}
              >
                {Array.from(files).map((file, index) => {
                  return (
                    <Stack direction="row" key={index}>
                      <img
                        width={50}
                        height={50}
                        alt={file.name}
                        src={URL.createObjectURL(file)}
                        style={{
                          borderRadius: 25,
                        }}
                      />
                      <ButtonBase
                        style={{
                          width: 14,
                          height: 14,
                        }}
                        onClick={() => {
                          const array = [...files];
                          if (index !== -1) {
                            array.splice(index, 1);
                            setFiles(array);
                          }
                        }}
                      >
                        <img
                          alt="x_circle"
                          src="/assets/icons/fi_x-circle.svg"
                        />
                      </ButtonBase>
                    </Stack>
                  );
                })}
              </Stack>
            )}
            <Typography
              fontSize={16}
              fontFamily="AvertaStd-semibold"
              color={appColors.primaryTextColor}
              style={{
                marginTop: 32,
              }}
            >
              Bio
            </Typography>
            <Paper
              style={{
                height: 170,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
                borderRadius: 16,
                marginTop: 16,
              }}
            >
              {" "}
              <TextField
                style={{
                  padding: 20,
                }}
                fullWidth
                hiddenLabel
                id="standard-basic"
                placeholder="Bio"
                variant="standard"
                InputProps={{ disableUnderline: true }}
                fontSize={14}
                color={appColors.primaryTextColor}
                multiline
                minRows={7}
                value={bio}
                onChange={(event) => {
                  setBio(event.target.value);
                }}
              />
            </Paper>
          </Stack>
        </Box>
        <Box
          style={{
            width: "100%",
            textAlign: "center",
            marginTop: 30,
          }}
          sx={{
            flex: "0 1 auto",
          }}
        >
          <UserFooter
            buttonTitle={isNonMobile ? "Create roommate profile" : "Create"}
            handleClick={continueClick}
          />
        </Box>
      </Box>
    </>
  );
};

export default ProfileSignUp;
