import { Box, ButtonBase, Stack, Typography } from "@mui/material";
import React from "react";
import FlexBetween from "./FlexBetween";
import { useNavigate } from "react-router-dom";
import { appColors } from "../theme";

const UserFooter = ({ buttonTitle, handleClick }) => {
  const navigate = useNavigate();

  return (
    <div>
      <Box
        padding="1rem 6%"
        style={{
          backgroundColor: "white",
          height: 100,
        }}
      >
        <FlexBetween>
          <ButtonBase
            style={{
              width: 180,
              marginTop: 24,
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <Stack direction="row">
              <img alt="back" src="/assets/icons/u_arrow-left.svg" />
              <Typography color={appColors.primaryTextColor} fontSize={14}>
                Back to previous page
              </Typography>
            </Stack>
          </ButtonBase>
          <ButtonBase
            style={{
              borderRadius: 30,
            }}
            onClick={handleClick}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 30,
                backgroundColor: "#9490DB",
              }}
            >
              <Typography
                fontSize={14}
                fontFamily="AvertaStd-semibold"
                color="white"
                style={{
                  marginTop: 18,
                  marginBottom: 18,
                  marginLeft: 44,
                  marginRight: 44,
                }}
              >
                {buttonTitle}
              </Typography>
            </Box>
          </ButtonBase>
        </FlexBetween>
      </Box>
    </div>
  );
};

export default UserFooter;
