import {
  Box,
  ButtonBase,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "../../state/store";
import { getColleges, getRoommates } from "../../state/slices/froomie";
import { IMAGE_BASE_URL } from "../../utils/constants";
import useAuth from "../../hooks/useAuth";

const FindRoommates = () => {
  const [selected, setSelected] = useState();
  const { roommates, roommatesCount } = useSelector((state) => state.froomie);
  const { colleges } = useSelector((state) => state.froomie);
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const { isAuthenticated } = useAuth();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [school, setSchool] = useState();
  const [year, setYear] = useState();
  const [yearOpen, setYearOpen] = useState();
  const yearAnchorRef = useRef(null);
  const [gender, setGender] = useState();
  const [genderOpen, setGenderOpen] = useState();
  const genderAnchorRef = useRef(null);
  const [living, setLiving] = useState();
  const [livingOpen, setLivingOpen] = useState();
  const livingAnchorRef = useRef(null);

  useEffect(() => {
    const params = {
      page: page,
      limit: 20,
    };
    if (year !== undefined) {
      params["classYear"] = year;
    }
    if (living !== undefined) {
      params["livingOption"] = living;
    }
    if (gender !== undefined) {
      params["gendar"] = gender;
    }
    if (school !== undefined) {
      params["school"] = school;
    }
    dispatch(getRoommates(params));
    dispatch(getColleges());
  }, [dispatch, page, year, living, gender, school]);

  const years = ["2028", "2027", "2026", "2025", " 2024", "Graduate"];
  const genders = ["Co - Ed", "Male", "Female"];
  const livings = ["On-Campus", "Off-Campus"];

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleYearToggle = () => {
    setYearOpen((prevOpen) => !prevOpen);
  };

  const handleGenderToggle = () => {
    setGenderOpen((prevOpen) => !prevOpen);
  };

  const handleLivingToggle = () => {
    setLivingOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
    //applyFilter();
  };

  const handleYearClose = (event) => {
    if (yearAnchorRef.current && yearAnchorRef.current.contains(event.target)) {
      return;
    }

    setYearOpen(false);
  };

  const handleGenderClose = (event) => {
    if (
      genderAnchorRef.current &&
      genderAnchorRef.current.contains(event.target)
    ) {
      return;
    }

    setGenderOpen(false);
  };

  const handleLivingClose = (event) => {
    if (
      livingAnchorRef.current &&
      livingAnchorRef.current.contains(event.target)
    ) {
      return;
    }

    setLivingOpen(false);
  };

  // function handleListKeyDown(event) {
  //   if (event.key === "Tab") {
  //     event.preventDefault();
  //     setOpen(false);
  //   } else if (event.key === "Escape") {
  //     setOpen(false);
  //   }
  // }

  return (
    <div
      style={{
        backgroundColor: "white",
      }}
    >
      <Box
        padding="1rem 6%"
        style={{
          paddingBottom: 24,
        }}
      >
        <Box
          display={isNonMobile ? "flex" : "block"}
          justifyContent={isNonMobile ? "space-between" : "normal"}
        >
          <Typography fontSize={20} fontFamily="AvertaStd-Semibold">
            {roommatesCount} Roommates match your search...
          </Typography>
          <Stack direction={isNonMobile ? "row" : "column"} spacing={2}>
            <div>
              <ButtonBase
                sx={{
                  borderRadius: 10,
                }}
                onClick={handleToggle}
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? "composition-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
              >
                <Box
                  sx={{
                    border: 1,
                    borderRadius: 10,
                    borderColor: "#9490DB",
                  }}
                >
                  <Stack
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    direction="row"
                  >
                    <img
                      style={{
                        marginTop: 15,
                        marginBottom: 15,
                        marginLeft: 15,
                      }}
                      alt="school"
                      src="/assets/icons/building.svg"
                    />
                    <Typography
                      style={{
                        marginRight: 15,
                        marginLeft: 4,
                      }}
                      fontSize={14}
                      fontFamily="AvertaStd-Semibold"
                      color="primary"
                    >
                      School
                    </Typography>
                  </Stack>
                </Box>
              </ButtonBase>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                placement="bottom-start"
                transition
                disablePortal
                style={{
                  zIndex: 100,
                }}
                preventOverFlow
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-start"
                          ? "left top"
                          : "left bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          style={{
                            maxHeight: 500,
                            overflow: "auto",
                          }}
                        >
                          {colleges.map((item) => {
                            return (
                              <MenuItem
                                selected={school === item.name ? true : false}
                                value={item.name}
                                key={item.id}
                                onClick={() => {
                                  setSchool(item.name);
                                  handleClose(item.name);
                                }}
                              >
                                <Typography>{item.name}</Typography>
                              </MenuItem>
                            );
                          })}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
            <div>
              <ButtonBase
                sx={{
                  borderRadius: 10,
                }}
                onClick={handleYearToggle}
                ref={yearAnchorRef}
                id="year-button"
                aria-controls={yearOpen ? "year-menu" : undefined}
                aria-expanded={yearOpen ? "true" : undefined}
                aria-haspopup="true"
              >
                <Box
                  sx={{
                    border: 1,
                    borderRadius: 10,
                    borderColor: "#9490DB",
                  }}
                >
                  <Stack
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    direction="row"
                  >
                    <img
                      style={{
                        marginTop: 15,
                        marginBottom: 15,
                        marginLeft: 15,
                      }}
                      alt="school"
                      src="/assets/icons/calendar.svg"
                    />
                    <Typography
                      style={{
                        marginRight: 15,
                        marginLeft: 4,
                      }}
                      fontSize={14}
                      fontFamily="AvertaStd-Semibold"
                      color="primary"
                    >
                      Year
                    </Typography>
                  </Stack>
                </Box>
              </ButtonBase>
              <Popper
                open={yearOpen}
                anchorEl={yearAnchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                style={{
                  zIndex: 100,
                }}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-start"
                          ? "left top"
                          : "left bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleYearClose}>
                        <MenuList
                          autoFocusItem={yearOpen}
                          id="year-menu"
                          aria-labelledby="year-button"
                        >
                          {years.map((item, index) => {
                            return (
                              <MenuItem
                                selected={year === item ? true : false}
                                value={item}
                                key={index}
                                onClick={() => {
                                  setYear(item);
                                  handleYearClose(item);
                                }}
                              >
                                <Typography>{item}</Typography>
                              </MenuItem>
                            );
                          })}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
            <div>
              <ButtonBase
                sx={{
                  borderRadius: 10,
                }}
                onClick={handleGenderToggle}
                ref={genderAnchorRef}
                id="gender-button"
                aria-controls={yearOpen ? "gender-menu" : undefined}
                aria-expanded={yearOpen ? "true" : undefined}
                aria-haspopup="true"
              >
                <Box
                  sx={{
                    border: 1,
                    borderRadius: 10,
                    borderColor: "#9490DB",
                  }}
                >
                  <Stack
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    direction="row"
                  >
                    <img
                      style={{
                        marginTop: 15,
                        marginBottom: 15,
                        marginLeft: 15,
                      }}
                      alt="school"
                      src="/assets/icons/circle.svg"
                    />
                    <Typography
                      style={{
                        marginRight: 15,
                        marginLeft: 4,
                      }}
                      fontSize={14}
                      fontFamily="AvertaStd-Semibold"
                      color="primary"
                    >
                      Gender
                    </Typography>
                  </Stack>
                </Box>
              </ButtonBase>
              <Popper
                open={genderOpen}
                anchorEl={genderAnchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                style={{
                  zIndex: 100,
                }}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-start"
                          ? "left top"
                          : "left bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleGenderClose}>
                        <MenuList
                          autoFocusItem={genderOpen}
                          id="year-menu"
                          aria-labelledby="year-button"
                        >
                          {genders.map((item, index) => {
                            return (
                              <MenuItem
                                selected={gender === item ? true : false}
                                value={item}
                                key={index}
                                onClick={() => {
                                  setGender(item);
                                  handleGenderClose(item);
                                }}
                              >
                                <Typography>{item}</Typography>
                              </MenuItem>
                            );
                          })}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
            <div>
              <ButtonBase
                sx={{
                  borderRadius: 10,
                }}
                onClick={handleLivingToggle}
                ref={livingAnchorRef}
                id="living-button"
                aria-controls={livingOpen ? "living-menu" : undefined}
                aria-expanded={livingOpen ? "true" : undefined}
                aria-haspopup="true"
              >
                <Box
                  sx={{
                    border: 1,
                    borderRadius: 10,
                    borderColor: "#9490DB",
                  }}
                >
                  <Stack
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    direction="row"
                  >
                    <img
                      style={{
                        marginTop: 15,
                        marginBottom: 15,
                        marginLeft: 15,
                      }}
                      alt="school"
                      src="/assets/icons/basket.svg"
                    />
                    <Typography
                      style={{
                        marginRight: 15,
                        marginLeft: 4,
                      }}
                      fontSize={14}
                      fontFamily="AvertaStd-Semibold"
                      color="primary"
                    >
                      Lifestyle
                    </Typography>
                  </Stack>
                </Box>
              </ButtonBase>
              <Popper
                open={livingOpen}
                anchorEl={livingAnchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                style={{
                  zIndex: 100,
                }}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-start"
                          ? "left top"
                          : "left bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleLivingClose}>
                        <MenuList
                          autoFocusItem={livingOpen}
                          id="living-menu"
                          aria-labelledby="living-button"
                        >
                          {livings.map((item, index) => {
                            return (
                              <MenuItem
                                selected={living === item ? true : false}
                                value={item}
                                key={index}
                                onClick={() => {
                                  setLiving(item);
                                  handleLivingClose(item);
                                }}
                              >
                                <Typography>{item}</Typography>
                              </MenuItem>
                            );
                          })}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </Stack>
        </Box>
        <Grid
          container
          spacing={6}
          style={{
            marginTop: 40,
            display: "flex",
            justifyContent: "center",
          }}
        >
          {roommates.map((item, index) => {
            return (
              <Grid
                item
                key={item.id}
                style={{
                  margin: 10,
                }}
              >
                <Paper
                  elevation={selected === index ? 4 : 1}
                  component={ButtonBase}
                  style={{
                    borderRadius: 20,
                    borderColor: "#A0A0A0",
                    height: 290,
                    width: 290,
                  }}
                  onClick={() => {
                    if (isAuthenticated) {
                      navigate(`/roommate/${item.id}`);
                    } else {
                      setSelected(index);
                    }
                  }}
                >
                  {/* <CardActionArea>
                    <CardContent>
                      
                    </CardContent>
                  </CardActionArea> */}
                  <Stack
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{
                        borderRadius: 60,
                        alignItems: "center",
                      }}
                      height={120}
                      width={120}
                      alt="profilesample"
                      src={IMAGE_BASE_URL + item?.profilePictures[0]}
                    />
                    <Stack
                      direction="row"
                      style={{
                        marginTop: 25,
                      }}
                    >
                      <Typography
                        fontSize={20}
                        fontFamily="AvertaStd-Semibold"
                        color="#231F20"
                      >
                        {item?.user?.name}
                      </Typography>
                      {item?.user?.verificationStatus === "verified" && (
                        <img
                          style={{
                            marginLeft: 4,
                          }}
                          alt="subtract"
                          src="/assets/icons/subtract.svg"
                        />
                      )}
                    </Stack>
                    <Typography
                      style={{
                        marginTop: 8,
                      }}
                      fontSize={14}
                      color="#7F7F7F"
                    >
                      {item.livingOption} ● {item.classYear}
                    </Typography>
                  </Stack>
                </Paper>
                {selected === index && (
                  <img
                    style={{
                      position: "absolute",
                      zIndex: 10,
                    }}
                    alt="error"
                    src="/assets/profileError.svg"
                  />
                )}
              </Grid>
            );
          })}
        </Grid>
        <Stack
          direction="row"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: 160,
            marginTop: 32,
          }}
        >
          <ButtonBase
            style={{
              marginRight: 20,
            }}
            onClick={() => {
              if (page > 1) {
                setPage((prevValue) => prevValue - 1);
              }
            }}
          >
            <Typography
              fontSize={14}
              fontFamily="AvertaStd-Semibold"
              color="#9490DB"
            >
              Prev
            </Typography>
          </ButtonBase>
          <Typography
            fontSize={14}
            fontFamily="AvertaStd-Semibold"
            color="#231F20"
          >
            Page: {page}
          </Typography>
          <ButtonBase
            style={{
              marginLeft: 20,
            }}
            onClick={() => {
              setPage((prevValue) => prevValue + 1);
            }}
          >
            <Typography
              fontSize={14}
              fontFamily="AvertaStd-Semibold"
              color="#9490DB"
            >
              Next
            </Typography>
          </ButtonBase>
        </Stack>
      </Box>
    </div>
  );
};

export default FindRoommates;
