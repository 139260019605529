import React, { useEffect } from "react";
import { useDispatch, useSelector } from "../../../state/store";
import useWindowDimensions from "../../../components/useWindowDimensions";
import useAuth from "../../../hooks/useAuth";
import { useState } from "react";
import * as io from "socket.io-client";
import { IMAGE_BASE_URL, MAIN_URL } from "../../../utils/constants";
import {
  Box,
  ButtonBase,
  Divider,
  Grid,
  InputBase,
  List,
  Stack,
  Typography,
} from "@mui/material";
import FlexBetween from "../../../components/FlexBetween";
import dayjs from "dayjs";
import Popup from "../../../components/Popup";
import {
  addNewMessage,
  getChatList,
  getHistory,
  sendChatMessage,
} from "../../../state/slices/froomie";

const Messages = () => {
  const dispatch = useDispatch();
  const { height } = useWindowDimensions();
  const [searchText, setSearchText] = useState();
  const [messageText, setMessageText] = useState();
  const [isShow, setIsShow] = useState(false);
  const { chats, chat } = useSelector((state) => state.froomie);
  const [selectedChat, setSelectedChat] = useState(null);
  const accessToken = window.localStorage.getItem("accessToken");
  const { user } = useAuth();
  const [messagePopup, setMessagePopup] = useState(false);
  const [socket, setSocket] = useState(null);
  //const socket = require("socket.io-client")("http://localhost:4000");

  useEffect(() => {
    const newSocket = io(MAIN_URL);
    setSocket(newSocket);
    return () => {
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socket === null) return;
    const jsonObject = {
      accessToken: accessToken,
      chattingWith: selectedChat?.id,
    };

    socket.emit("join", jsonObject);
    console.log(`received/${selectedChat?.id}/${user?.id}`);
    socket.on("connect", () => {
      console.log(socket.id); // x8WIv7-mJelg7on_ALbx
    });
    socket.on(`received/${selectedChat?.id}/${user?.id}`, (data) => {
      dispatch(addNewMessage(data));
    });
  }, [socket, selectedChat, accessToken]);

  // useEffect(() => {
  //   if (selectedChat !== undefined) {
  //     console.log("Called");
  //     const jsonObject = {
  //       accessToken: accessToken,
  //       chattingWith: selectedChat?.id,
  //     };
  //     socket.emit("join", jsonObject);

  //     socket.on(`received/${selectedChat?.id}/${user?.id}`, (data) => {
  //       console.log(data);
  //     });
  //   } else {
  //     socket.disconnect();
  //   }
  // }, [user, selectedChat, accessToken]);

  useEffect(() => {
    dispatch(getChatList());
    dispatch(getHistory(selectedChat?.id));
  }, [dispatch, selectedChat]);

  const sendMessage = () => {
    dispatch(sendChatMessage(selectedChat?.id, messageText));
    setMessageText("");
    dispatch(getHistory(selectedChat?.id));
  };

  return (
    <>
      <Stack
        style={{
          marginLeft: 100,
        }}
      >
        <Grid container>
          <Grid item xs={4}>
            <Stack
              style={{
                marginTop: 30,
                marginLeft: 30,
                marginRight: 30,
              }}
            >
              <FlexBetween>
                <Typography
                  fontSize={24}
                  fontFamily="AvertaStd-Bold"
                  color="black"
                >
                  Inbox
                </Typography>
                {/* {user?.type !== "manager" && (
                  <ButtonBase
                    onClick={() => {
                      setMessagePopup(true);
                    }}
                  >
                    <img alt="message" src="/assets/icons/message.svg" />
                  </ButtonBase>
                )} */}
              </FlexBetween>
              <FlexBetween
                backgroundColor="white"
                style={{
                  height: 50,
                  borderRadius: 24,
                  marginTop: 30,
                }}
              >
                <Stack
                  direction="row"
                  style={{
                    width: "100%",
                  }}
                >
                  <img
                    alt="search"
                    src="/assets/search2.svg"
                    style={{
                      marginLeft: 20,
                      marginRight: 8,
                    }}
                  />
                  <InputBase
                    style={{
                      paddingRight: 8,
                    }}
                    placeholder="Search..."
                    fullWidth
                    value={searchText}
                    onChange={(event) => {
                      setSearchText(event.target.value);
                    }}
                  />
                </Stack>
                <ButtonBase
                  style={{
                    marginRight: 16,
                  }}
                  onClick={() => {}}
                >
                  <img alt="filter" src="/assets/icons/filter-line.svg" />
                </ButtonBase>
              </FlexBetween>
              <Box
                style={{
                  height: height - 250,
                }}
                sx={{
                  overflow: "auto",
                }}
              >
                <List
                  style={{
                    overflow: "auto",
                  }}
                >
                  {chats.map((item) => {
                    return (
                      <Stack
                        key={item?.id}
                        style={{
                          marginTop: 24,
                        }}
                      >
                        <FlexBetween>
                          <Stack direction="row">
                            <img
                              style={{
                                width: 48,
                                height: 48,
                                borderRadius: 24,
                              }}
                              alt={`/listing/${item?.id}`}
                              src={IMAGE_BASE_URL + item?.photo}
                            />
                            <Stack
                              style={{
                                marginLeft: 16,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSelectedChat(item);
                              }}
                            >
                              <Typography
                                fontSize={16}
                                fontFamily="AvertaStd-semibold"
                                color="#9490DB"
                              >
                                {item?.name}
                              </Typography>
                              <Typography
                                fontSize={12}
                                fontFamily="AvertaStd-semibold"
                                color="#21273D"
                                noWrap
                                width={450}
                              >
                                {item?.lastMessage}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Typography
                            fontSize={10}
                            fontFamily="AvertaStd-semibold"
                            color="#9094A3"
                            style={{
                              width: 90,
                              marginLeft: 8,
                            }}
                          >
                            {dayjs(item?.lastMessageDate).format(
                              "MMM DD, YYYY"
                            )}
                          </Typography>
                        </FlexBetween>
                        <Divider
                          style={{
                            marginTop: 24,
                          }}
                        />
                      </Stack>
                    );
                  })}
                </List>
              </Box>
            </Stack>
          </Grid>
          <Grid
            item
            xs={8}
            style={{
              backgroundColor: "#FBFBFB",
            }}
          >
            <Stack
              style={{
                display: "flex",
                height: "100%",
              }}
            >
              <FlexBetween
                style={{
                  margin: 30,
                  flex: "0 1 auto",
                }}
              >
                <Stack direction="row">
                  <img
                    style={{
                      width: 48,
                      height: 48,
                      borderRadius: 24,
                    }}
                    alt="profile"
                    src={IMAGE_BASE_URL + selectedChat?.photo}
                  />
                  <Stack
                    style={{
                      marginLeft: 16,
                    }}
                  >
                    <Typography
                      fontSize={16}
                      fontFamily="AvertaStd-semibold"
                      color="#21273D"
                    >
                      {selectedChat?.name}
                    </Typography>
                    <Typography fontSize={14} color="#9094A3">
                      Status
                    </Typography>
                  </Stack>
                </Stack>
                <ButtonBase>
                  <img alt="more" src="/assets/more-fill.svg" />
                </ButtonBase>
              </FlexBetween>
              <Divider />
              <Box
                style={{
                  flex: "1 1 auto",
                  height: 600,
                }}
              >
                <List
                  style={{
                    overflow: "auto",
                    height: "100%",
                  }}
                >
                  {chat
                    ?.slice(0)
                    .reverse()
                    .map((item, index) => {
                      return (
                        <Stack key={item?.id}>
                          {/* {dayjs(item?.createdAt).format("MMM DD, YYYY") !==
                            dayjs(chat[index - 1]?.createdAt).format(
                              "MMM DD, YYYY"
                            ) && (
                            <Typography
                              align="center"
                              style={{
                                marginTop: 16,
                              }}
                            >
                              {dayjs(item?.createdAt).format("MMM DD, YYYY")}
                            </Typography>
                          )} */}
                          <Typography
                            align="center"
                            style={{
                              marginTop: 16,
                            }}
                          >
                            {dayjs(item?.createdAt).format("MMM DD, YYYY")}
                          </Typography>
                          {(() => {
                            if (item?.receiver?.id === selectedChat?.id) {
                              return (
                                <FlexBetween
                                  style={{
                                    marginRight: 16,
                                  }}
                                >
                                  <Typography></Typography>
                                  <Stack
                                    direction="row"
                                    style={{
                                      padding: 20,
                                      borderRadius: 100,
                                      maxWidth: "50%",
                                      backgroundColor: "#E0E0E0",
                                      marginTop: 16,
                                    }}
                                  >
                                    <Typography
                                      ontSize={16}
                                      fontFamily="AvertaStd-semibold"
                                      color="#3C383D"
                                    >
                                      {item?.message}
                                    </Typography>
                                    <Typography
                                      width={80}
                                      color="#9094A3"
                                      style={{
                                        paddingLeft: 8,
                                      }}
                                    >
                                      {dayjs(item?.createdAt).format("h:mm a")}
                                    </Typography>
                                  </Stack>
                                </FlexBetween>
                              );
                            } else {
                              return (
                                <FlexBetween
                                  style={{
                                    marginLeft: 16,
                                  }}
                                >
                                  <Stack
                                    direction="row"
                                    style={{
                                      padding: 20,
                                      borderRadius: 100,
                                      maxWidth: "50%",
                                      backgroundColor: "#E0E0E0",
                                      marginTop: 16,
                                    }}
                                  >
                                    <Typography
                                      ontSize={16}
                                      fontFamily="AvertaStd-semibold"
                                      color="#3C383D"
                                    >
                                      {item?.message}
                                    </Typography>
                                    <Typography
                                      width={80}
                                      color="#9094A3"
                                      style={{
                                        paddingLeft: 8,
                                      }}
                                    >
                                      {dayjs(item?.createdAt).format("h:mm a")}
                                    </Typography>
                                  </Stack>
                                  <Typography></Typography>
                                </FlexBetween>
                              );
                            }
                          })()}
                        </Stack>
                      );
                    })}
                </List>
              </Box>
              <Box
                style={{
                  height: 86,
                  flex: "0 1 auto",
                }}
              >
                <Stack
                  direction="row"
                  style={{
                    marginLeft: 30,
                    marginRight: 30,
                    marginTop: 20,
                  }}
                >
                  <Stack
                    direction="row"
                    style={{
                      width: "100%",
                    }}
                  >
                    <ButtonBase
                      style={{
                        backgroundColor: "#F7F7F7",
                        width: 42,
                        height: 42,
                        borderRadius: 21,
                      }}
                    >
                      <img alt="plus" src="/assets/plus.svg" />
                    </ButtonBase>
                    <FlexBetween
                      backgroundColor="#F7F7F7"
                      style={{
                        height: 50,
                        width: "100%",
                        borderRadius: 24,
                        marginLeft: 8,
                        marginRight: 8,
                      }}
                    >
                      <Stack
                        direction="row"
                        style={{
                          width: "100%",
                        }}
                      >
                        <InputBase
                          style={{
                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                          fullWidth
                          placeholder="Type Here"
                          value={messageText}
                          onChange={(event) => {
                            setMessageText(event.target.value);
                          }}
                        />
                      </Stack>
                    </FlexBetween>
                  </Stack>
                  <ButtonBase onClick={sendMessage}>
                    <img alt="send" src="/assets/send_button.svg" />
                  </ButtonBase>
                </Stack>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <Popup openPopup={messagePopup} setOpenPopup={setMessagePopup}>
        <div
          style={{
            width: 450,
          }}
        >
          <Stack>
            <ButtonBase
              style={{
                width: 40,
                height: 40,
                position: "absolute",
                top: 16,
                right: 16,
              }}
              onClick={() => setMessagePopup(false)}
            >
              <img alt="x_circle" src="/assets/icons/fi_x-circle.svg" />
            </ButtonBase>
            <Typography
              style={{
                marginTop: 30,
              }}
              fontSize={24}
              fontFamily="AvertaStd-Bold"
              color="#231F20"
            >
              Search User
            </Typography>
          </Stack>
        </div>
      </Popup>
    </>
  );
};

export default Messages;
