import { Box, ButtonBase, Link, Stack, Typography } from "@mui/material";
import React from "react";
import { useRef } from "react";
import { appColors } from "../theme";

const DragDropFiles = ({ files, setFiles, showFiles, isMultiple = true }) => {
  const inputRef = useRef();

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setFiles(event.dataTransfer.files);
  };

  if (showFiles === true) {
    if (files)
      return (
        <div>
          <Stack
            direction="row"
            style={{
              marginTop: 16,
            }}
          >
            {Array.from(files).map((file, idx) => {
              return (
                <Box key={idx}>
                  <Stack>
                    <img
                      width={100}
                      height={100}
                      alt="upload"
                      src={URL.createObjectURL(file)}
                      style={{
                        objectFit: "cover",
                        borderRadius: 50,
                      }}
                    />
                    <ButtonBase
                      style={{
                        borderRadius: 30,
                        marginTop: 20,
                      }}
                      onClick={() => {
                        setFiles(null);
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 30,
                          backgroundColor: "red",
                        }}
                      >
                        <Typography
                          fontSize={14}
                          fontFamily="AvertaStd-semibold"
                          color="white"
                          style={{
                            marginTop: 5,
                            marginBottom: 5,
                            marginLeft: 30,
                            marginRight: 30,
                          }}
                        >
                          Remove
                        </Typography>
                      </Box>
                    </ButtonBase>
                  </Stack>
                </Box>
              );
            })}
          </Stack>
        </div>
      );
  }

  return (
    <>
      <div className="dropzone" onDragOver={handleDragOver} onDrop={handleDrop}>
        <Stack
          direction="row"
          style={{
            marginTop: 16,
          }}
        >
          <img alt="upload" src="/assets/icons/uploadImage.svg" />
          <Stack
            style={{
              display: "flex",
              justifyContent: "center",
              marginLeft: 20,
            }}
          >
            <Stack direction="row">
              <Link
                style={{ cursor: "grab" }}
                onClick={() => inputRef.current.click()}
              >
                <Typography
                  color={appColors.primaryTextColor}
                  fontSize={14}
                  fontFamily="AvertaStd-semibold"
                >
                  Click to upload
                </Typography>
              </Link>
              <Typography
                fontSize={14}
                color="#A0A0A0"
                style={{
                  marginLeft: 4,
                }}
              >
                or Drag and drop
              </Typography>
            </Stack>
            <Typography color="#A0A0A0" fontSize={12}>
              PNG, JPG or JPEG (max. 800*400px)
            </Typography>
          </Stack>
        </Stack>
        <input
          type="file"
          multiple={isMultiple}
          onChange={(event) => {
            if (files?.length > 0) {
              setFiles((current) => [...current, ...event.target.files]);
            } else {
              setFiles(event.target.files);
            }
          }}
          hidden
          accept="image/png, image/jpeg, image/jpg"
          ref={inputRef}
        />
      </div>
    </>
  );
};

export default DragDropFiles;
