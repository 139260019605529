import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent } from "@mui/material";

Popup.prototypes = {
  title: PropTypes.string,
  children: PropTypes.object,
  openPopup: PropTypes.bool,
  setOpenPopup: PropTypes.bool,
};

export default function Popup(props) {
  const { title, children, openPopup, setOpenPopup } = props;

  return (
    <Dialog open={openPopup} maxWidth="md">
      {/* <DialogTitle>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <ButtonBase
            style={{
              width: 40,
              height: 40,
            }}
            onClick={() => setOpenPopup(false)}
          >
            <img alt="x_circle" src="/src/assets/icons/fi_x-circle.svg" />
          </ButtonBase>
        </div>
      </DialogTitle> */}
      <DialogContent>
        <div>{children}</div>
      </DialogContent>
    </Dialog>
  );
}
