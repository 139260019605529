import {
  Box,
  ButtonBase,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { appColors } from "../../theme";
import { Link, useNavigate, useParams } from "react-router-dom";
import FlexBetween from "../../components/FlexBetween";
import IOSSwitch from "../../components/IOSSwitch";
import { useDispatch, useSelector } from "../../state/store";
import { getSingleRoommate } from "../../state/slices/froomie";
import { IMAGE_BASE_URL } from "../../utils/constants";
import { useSnackbar } from "notistack";
import useAuth from "../../hooks/useAuth";
import axiosInstance from "../../utils/axios";
import Popup from "../../components/Popup";

const RoommateDetails = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isEdit, setIsEdit] = useState(false);
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const dispatch = useDispatch();
  const { roommate } = useSelector((state) => state.froomie);
  const { id } = useParams();
  const { isAuthenticated } = useAuth();
  const [message, setMessage] = useState("");
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    dispatch(getSingleRoommate(id));
  }, [dispatch, id]);

  // const activities = ["Sports", "Student Government", "Greek life"];
  // const interests = [
  //   "Partying",
  //   "Reading",
  //   "Shopping",
  //   "Self-care",
  //   "LGBTQIA+",
  //   "Coffee",
  // ];

  async function copyToClip() {
    await navigator.clipboard.writeText(window.location.href);
    enqueueSnackbar("Link copied to clipboard ", { variant: "success" });
  }

  const sendMessage = async () => {
    if (isAuthenticated) {
      const values = {
        id: roommate?.user?.id,
        message: message,
      };
      try {
        const options = {
          method: "POST",
          url: "/chats/sendMessage",
          data: values,
        };
        const response = await axiosInstance.request(options);
        if (response.data.status) {
          enqueueSnackbar("Message Sent Successfully", { variant: "success" });
          setOpenPopup(false);
        }
      } catch (error) {
        enqueueSnackbar("Server Error", { variant: "error" });
      }
    } else {
      enqueueSnackbar("You have to Login to send Message.", {
        variant: "error",
      });
    }
  };

  return (
    <div
      style={{
        backgroundColor: "white",
      }}
    >
      <Stack padding="1rem 6%">
        <ButtonBase
          style={{
            width: 160,
            marginTop: 24,
          }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <Stack direction="row">
            <img alt="back" src="/assets/icons/u_arrow-left.svg" />
            <Typography color={appColors.primaryTextColor} fontSize={14}>
              Back to details page
            </Typography>
          </Stack>
        </ButtonBase>
        <Paper
          style={{
            borderRadius: 30,
            marginTop: 36,
            width: "100%",
            padding: 32,
          }}
          sx={{
            border: 1,
            borderColor: "#E0E0E0",
          }}
        >
          <Grid
            container
            style={{
              height: {
                md: 500,
              },
            }}
          >
            <Grid item xs={12} md={5}>
              <Box
                style={{
                  height: isNonMobile ? 500 : 300,
                  width: "100%",
                  paddingRight: isNonMobile ? 15 : 0,
                }}
              >
                {roommate?.profilePictures[0] && (
                  <img
                    style={{
                      objectFit: "cover",
                      borderTopLeftRadius: 15,
                      borderBottomLeftRadius: isNonMobile ? 15 : 0,
                      borderTopRightRadius: isNonMobile ? 0 : 15,
                      width: "100%",
                      height: "100%",
                    }}
                    alt="test"
                    src={IMAGE_BASE_URL + roommate?.profilePictures[0]}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={7}>
              <Box
                style={{
                  height: isNonMobile ? 500 : 150,
                  width: "100%",
                  paddingLeft: isNonMobile ? 15 : 0,
                  marginTop: isNonMobile ? 0 : 20,
                }}
              >
                <Grid
                  container
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <Grid item xs={6}>
                    <Box
                      style={{
                        height: isNonMobile ? 500 : 150,
                        width: "100%",
                        paddingRight: isNonMobile ? 15 : 8,
                      }}
                    >
                      {roommate?.profilePictures[1] && (
                        <img
                          style={{
                            objectFit: "cover",
                            width: "100%",
                            height: "100%",
                            borderBottomLeftRadius: isNonMobile ? 0 : 15,
                          }}
                          alt="test"
                          src={IMAGE_BASE_URL + roommate?.profilePictures[1]}
                        />
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      style={{
                        height: isNonMobile ? 500 : 150,
                        width: "100%",
                        paddingLeft: isNonMobile ? 15 : 8,
                      }}
                    >
                      {roommate?.profilePictures[2] && (
                        <img
                          style={{
                            objectFit: "cover",
                            borderTopRightRadius: isNonMobile ? 15 : 0,
                            borderBottomRightRadius: 15,
                            width: "100%",
                            height: "100%",
                          }}
                          alt="test"
                          src={IMAGE_BASE_URL + roommate?.profilePictures[2]}
                        />
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <FlexBetween>
            <Stack
              direction="row"
              style={{
                marginTop: isNonMobile ? 40 : 24,
              }}
            >
              <Typography
                fontSize={isNonMobile ? 36 : 22}
                fontFamily="AvertaStd-Bold"
                color={appColors.primaryTextColor}
              >
                {roommate?.user?.name}
              </Typography>
              {roommate?.user?.verificationStatus && (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: 10,
                  }}
                >
                  <Box
                    style={{
                      width: 16,
                      height: 16,
                      borderRadius: 8,
                      backgroundColor: "#68DD74",
                    }}
                  />
                </Box>
              )}
            </Stack>
            {/* <ButtonBase
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                });
                setIsEdit(true);
              }}
              style={{
                marginTop: isNonMobile ? 0 : 20,
              }}
            >
              <img alt="more" src="/assets/icons/fi_more-vertical.svg" />
            </ButtonBase> */}
          </FlexBetween>
          <Stack direction="row">
            <Typography
              fontSize={isNonMobile ? 20 : 12}
              fontFamily="AvertaStd-semibold"
              color={appColors.darkGrayTextColor}
            >
              {roommate?.user?.school}
            </Typography>
            <Typography
              color="#4B4B4B"
              fontSize={isNonMobile ? 20 : 12}
              fontFamily="AvertaStd-semibold"
              style={{
                marginLeft: 20,
              }}
            >
              {" "}
            </Typography>
          </Stack>
          <FlexBetween>
            <Stack
              direction="row"
              spacing={2}
              style={{
                marginTop: 36,
              }}
            >
              <ButtonBase
                style={{
                  borderRadius: 24,
                }}
                onClick={() => {
                  setOpenPopup(true);
                }}
              >
                <Box
                  style={{
                    backgroundColor: "#9490DB",
                    width: 125,
                    height: 48,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 24,
                  }}
                >
                  <Stack direction="row">
                    <img src="/assets/icons/u_comment.svg" alt="comment" />
                    <Typography
                      color="white"
                      fontSize={14}
                      fontFamily="AvertaStd-semibold"
                      style={{
                        marginLeft: 4,
                      }}
                    >
                      Message
                    </Typography>
                  </Stack>
                </Box>
              </ButtonBase>
              <ButtonBase
                style={{
                  borderRadius: 24,
                }}
                onClick={copyToClip}
              >
                <Box
                  style={{
                    width: 160,
                    height: 48,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 24,
                  }}
                  sx={{
                    border: 1,
                    borderColor: "#9490DB",
                  }}
                >
                  <Typography
                    color="#9490DB"
                    fontSize={14}
                    fontFamily="AvertaStd-semibold"
                    style={{
                      marginLeft: 4,
                    }}
                  >
                    Share this profile
                  </Typography>
                </Box>
              </ButtonBase>
            </Stack>
            <Stack direction="row" spacing={4}>
              <Link to={`https://www.tiktok.com/@${roommate?.tiktok}`}>
                <img alt="tiktok" src="/assets/icons/tiktok.svg" />
              </Link>
              <Link to={`https://www.instagram.com/${roommate?.instagram}`}>
                <img alt="instagram" src="/assets/icons/instagram.svg" />
              </Link>
              <Link to={`https://www.snapchat.com/add/${roommate?.snapchat}`}>
                <img alt="snapchat" src="/assets/icons/snapchat.svg" />
              </Link>
            </Stack>
          </FlexBetween>
        </Paper>
        <Paper
          style={{
            borderRadius: 30,
            marginTop: 36,
            width: "100%",
            padding: 32,
          }}
          sx={{
            border: 1,
            borderColor: "#E0E0E0",
          }}
        >
          <Typography
            fontSize={20}
            fontFamily="AvertaStd-Bold"
            color={appColors.primaryTextColor}
          >
            About {roommate?.user?.name}
          </Typography>
          <Typography fontSize={16} color={appColors.darkGrayTextColor}>
            {roommate?.bio}
          </Typography>
          <Typography
            fontSize={14}
            fontFamily="AvertaStd-semibold"
            color="primary"
            style={{
              marginTop: 20,
            }}
          >
            Learn more
          </Typography>
        </Paper>
        <Stack direction={isNonMobile ? "row" : "column"} spacing={4}>
          <Paper
            style={{
              borderRadius: 30,
              marginTop: 36,
              width: "100%",
              padding: isNonMobile ? 32 : 20,
            }}
            sx={{
              border: 1,
              borderColor: "#E0E0E0",
            }}
          >
            <Typography
              fontSize={20}
              fontFamily="AvertaStd-Bold"
              color={appColors.primaryTextColor}
            >
              Title here
            </Typography>
            <Stack
              spacing={2.3}
              style={{
                marginTop: 24,
              }}
            >
              <FlexBetween>
                <Typography fontSize={20} color="#595959">
                  Housing:
                </Typography>
                <Typography
                  fontSize={20}
                  fontFamily="AvertaStd-Bold"
                  color={appColors.primaryTextColor}
                >
                  {roommate?.livingOption}
                </Typography>
              </FlexBetween>
              <FlexBetween>
                <Typography fontSize={20} color="#595959">
                  Graduation year:
                </Typography>
                <Typography
                  fontSize={20}
                  fontFamily="AvertaStd-Bold"
                  color={appColors.primaryTextColor}
                >
                  {roommate?.classYear}
                </Typography>
              </FlexBetween>
              <FlexBetween>
                <Typography fontSize={20} color="#595959">
                  Gender:
                </Typography>
                <Typography
                  fontSize={20}
                  fontFamily="AvertaStd-Bold"
                  color={appColors.primaryTextColor}
                >
                  {roommate?.gendar}
                </Typography>
              </FlexBetween>
              <FlexBetween>
                <Typography fontSize={20} color="#595959">
                  Intended major:
                </Typography>
                <Typography
                  fontSize={20}
                  fontFamily="AvertaStd-Bold"
                  color={appColors.primaryTextColor}
                >
                  {roommate?.major}
                </Typography>
              </FlexBetween>
            </Stack>
          </Paper>
          <Paper
            style={{
              borderRadius: 30,
              marginTop: 36,
              width: "100%",
              padding: isNonMobile ? 32 : 20,
            }}
            sx={{
              border: 1,
              borderColor: "#E0E0E0",
            }}
          >
            <Typography
              fontSize={20}
              fontFamily="AvertaStd-Bold"
              color={appColors.primaryTextColor}
            >
              Rooming info
            </Typography>
            <Stack
              spacing={1.2}
              style={{
                marginTop: 24,
              }}
            >
              <FlexBetween>
                <Typography fontSize={16} color={appColors.primaryTextColor}>
                  Are you a neat freak?
                </Typography>
                <Stack direction="row">
                  <Box
                    style={{
                      width: 60,
                      height: 32,
                      borderRadius: 16,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    sx={{
                      border: 1,
                      borderColor: appColors.primaryTextColor,
                    }}
                  >
                    <Typography
                      fontSize={14}
                      fontFamily="AvertaStd-semibold"
                      color={appColors.primaryTextColor}
                    >
                      {roommate?.isNeatFreak ? "Yes" : "No"}
                    </Typography>
                  </Box>
                  {/* <ButtonBase
                    style={{
                      marginLeft: 10,
                    }}
                  >
                    <img
                      alt="moreOptions"
                      src="/assets/icons/fi_more-vertical.svg"
                    />
                  </ButtonBase> */}
                </Stack>
              </FlexBetween>
              <FlexBetween>
                <Typography fontSize={16} color={appColors.primaryTextColor}>
                  Are you a night owl?
                </Typography>
                <Stack direction="row">
                  <Box
                    style={{
                      width: 60,
                      height: 30,
                      borderRadius: 15,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    sx={{
                      border: 1,
                      borderColor: appColors.primaryTextColor,
                    }}
                  >
                    <Typography
                      fontSize={14}
                      fontFamily="AvertaStd-semibold"
                      color={appColors.primaryTextColor}
                    >
                      {roommate?.isNightOwl ? "Yes" : "No"}
                    </Typography>
                  </Box>
                  {/* <ButtonBase
                    style={{
                      marginLeft: 10,
                    }}
                  >
                    <img
                      alt="moreOptions"
                      src="/assets/icons/fi_more-vertical.svg"
                    />
                  </ButtonBase> */}
                </Stack>
              </FlexBetween>
              <FlexBetween>
                <Typography fontSize={16} color={appColors.primaryTextColor}>
                  Are you in a relationship?
                </Typography>
                <Stack direction="row">
                  <Box
                    style={{
                      width: 60,
                      height: 30,
                      borderRadius: 15,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    sx={{
                      border: 1,
                      borderColor: appColors.primaryTextColor,
                    }}
                  >
                    <Typography
                      fontSize={14}
                      fontFamily="AvertaStd-semibold"
                      color={appColors.primaryTextColor}
                    >
                      {roommate?.isInRelationShip ? "Yes" : "No"}
                    </Typography>
                  </Box>
                  {/* <ButtonBase
                    style={{
                      marginLeft: 10,
                    }}
                  >
                    <img
                      alt="moreOptions"
                      src="/assets/icons/fi_more-vertical.svg"
                    />
                  </ButtonBase> */}
                </Stack>
              </FlexBetween>
              <FlexBetween>
                <Typography fontSize={16} color={appColors.primaryTextColor}>
                  Do you smoke weed?
                </Typography>
                <Stack direction="row">
                  <Box
                    style={{
                      width: 60,
                      height: 30,
                      borderRadius: 15,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    sx={{
                      border: 1,
                      borderColor: appColors.primaryTextColor,
                    }}
                  >
                    <Typography
                      fontSize={14}
                      fontFamily="AvertaStd-semibold"
                      color={appColors.primaryTextColor}
                    >
                      {roommate?.isSmoker ? "Yes" : "No"}
                    </Typography>
                  </Box>
                  {/* <ButtonBase
                    style={{
                      marginLeft: 10,
                    }}
                  >
                    <img
                      alt="moreOptions"
                      src="/assets/icons/fi_more-vertical.svg"
                    />
                  </ButtonBase> */}
                </Stack>
              </FlexBetween>
              <FlexBetween>
                <Typography fontSize={16} color={appColors.primaryTextColor}>
                  Do you like the room temperature cold?
                </Typography>
                <Stack direction="row">
                  <Box
                    style={{
                      width: 60,
                      height: 30,
                      borderRadius: 15,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    sx={{
                      border: 1,
                      borderColor: appColors.primaryTextColor,
                    }}
                  >
                    <Typography
                      fontSize={14}
                      fontFamily="AvertaStd-semibold"
                      color={appColors.primaryTextColor}
                    >
                      {roommate?.likeRoomTempCold ? "Yes" : "No"}
                    </Typography>
                  </Box>
                  {/* <ButtonBase
                    style={{
                      marginLeft: 10,
                    }}
                  >
                    <img
                      alt="moreOptions"
                      src="/assets/icons/fi_more-vertical.svg"
                    />
                  </ButtonBase> */}
                </Stack>
              </FlexBetween>
            </Stack>
          </Paper>
        </Stack>
        <Paper
          style={{
            borderRadius: 30,
            marginTop: 36,
            width: "100%",
            padding: isNonMobile ? 32 : 20,
          }}
          sx={{
            border: 1,
            borderColor: "#E0E0E0",
          }}
        >
          <Typography
            fontSize={20}
            fontFamily="AvertaStd-Bold"
            color={appColors.primaryTextColor}
          >
            Activities or intended
          </Typography>
          <Stack
            direction={isNonMobile ? "row" : "column"}
            spacing={2}
            style={{
              marginTop: 32,
            }}
          >
            {roommate?.activities?.split(",").map((activity, index) => {
              return (
                <Box
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 30,
                    backgroundColor: "#9490DB",
                  }}
                >
                  <Typography
                    fontSize={14}
                    fontFamily="AvertaStd-semibold"
                    color="white"
                    style={{
                      marginTop: 12,
                      marginBottom: 12,
                      marginLeft: 20,
                      marginRight: 20,
                    }}
                  >
                    {activity}
                  </Typography>
                </Box>
              );
            })}
          </Stack>

          {/* {roommate?.activities ? (
            <Typography
              fontSize={16}
              color={appColors.primaryTextColor}
              style={{
                marginTop: 12,
                marginBottom: 12,
                marginRight: 20,
              }}
            >
              {roommate?.activities}
            </Typography>
          ) : (
            <Stack
              direction={isNonMobile ? "row" : "column"}
              spacing={2}
              style={{
                marginTop: 32,
              }}
            >
              {activities.map((activity, index) => {
                return (
                  <Box
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 30,
                      backgroundColor: "#9490DB",
                    }}
                  >
                    <Typography
                      fontSize={14}
                      fontFamily="AvertaStd-semibold"
                      color="white"
                      style={{
                        marginTop: 12,
                        marginBottom: 12,
                        marginLeft: 20,
                        marginRight: 20,
                      }}
                    >
                      {activity}
                    </Typography>
                  </Box>
                );
              })}
            </Stack>
          )} */}
        </Paper>
        <Paper
          style={{
            borderRadius: 30,
            marginTop: 36,
            width: "100%",
            padding: 32,
            marginBottom: 40,
          }}
          sx={{
            border: 1,
            borderColor: "#E0E0E0",
          }}
        >
          <Typography
            fontSize={20}
            fontFamily="AvertaStd-Bold"
            color={appColors.primaryTextColor}
          >
            Interests/hobbies/lifestyle
          </Typography>
          <Stack
            direction={isNonMobile ? "row" : "column"}
            spacing={2}
            style={{
              marginTop: 32,
            }}
          >
            {roommate?.hobbies?.split(",").map((activity, index) => {
              return (
                <Box
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 30,
                    backgroundColor: "#9490DB",
                  }}
                >
                  <Typography
                    fontSize={14}
                    fontFamily="AvertaStd-semibold"
                    color="white"
                    style={{
                      marginTop: 12,
                      marginBottom: 12,
                      marginLeft: 20,
                      marginRight: 20,
                    }}
                  >
                    {activity}
                  </Typography>
                </Box>
              );
            })}
          </Stack>
          {/* {roommate?.hobbies ? (
            <Typography
              fontSize={16}
              color={appColors.primaryTextColor}
              style={{
                marginTop: 12,
                marginBottom: 12,
                marginRight: 20,
              }}
            >
              {roommate?.hobbies}
            </Typography>
          ) : (
            <Stack
              direction={isNonMobile ? "row" : "column"}
              spacing={2}
              style={{
                marginTop: 32,
              }}
            >
              {interests.map((activity, index) => {
                return (
                  <Box
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 30,
                      backgroundColor: "#9490DB",
                    }}
                  >
                    <Typography
                      fontSize={14}
                      fontFamily="AvertaStd-semibold"
                      color="white"
                      style={{
                        marginTop: 12,
                        marginBottom: 12,
                        marginLeft: 20,
                        marginRight: 20,
                      }}
                    >
                      {activity}
                    </Typography>
                  </Box>
                );
              })}
            </Stack>
          )} */}
        </Paper>
      </Stack>
      {isEdit && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            top: 0,
            right: 0,
            width: "100%",
            textAlign: "center",
          }}
        >
          <Stack
            direction="row"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            {isNonMobile && (
              <Box
                style={{
                  width: "100%",
                  backgroundColor: appColors.primaryTextColor,
                  opacity: 0.3,
                }}
              />
            )}

            <Box
              style={{
                width: 550,
                backgroundColor: "white",
                height: "100%",
              }}
            >
              <ButtonBase
                style={{
                  width: 32,
                  height: 32,
                  position: "absolute",
                  top: 40,
                  right: 30,
                }}
                onClick={() => setIsEdit(false)}
              >
                <img
                  width={32}
                  height={32}
                  alt="x-Circle"
                  src="/assets/icons/fi_x-circle.svg"
                />
              </ButtonBase>
              <ButtonBase
                style={{
                  marginTop: 36,
                  height: 50,
                  borderRadius: 25,
                  paddingLeft: 20,
                  paddingRight: 20,
                  position: "absolute",
                  bottom: 50,
                  right: 20,
                  width: isNonMobile ? 370 : 350,
                }}
                sx={{
                  border: 1,
                  borderColor: "#E0E0E0",
                }}
              >
                <FlexBetween
                  style={{
                    width: "100%",
                  }}
                >
                  <Typography
                    fontSize={14}
                    fontFamily="AvertaStd-semibold"
                    color={appColors.primaryTextColor}
                  >
                    Logout
                  </Typography>
                  <img alt="rightArrow" src="/assets/icons/rightArrow.svg" />
                </FlexBetween>
              </ButtonBase>
              <Stack
                style={{
                  marginLeft: isNonMobile ? 40 : 20,
                  marginRight: isNonMobile ? 40 : 20,
                  marginTop: 90,
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "left",
                }}
              >
                <Typography
                  align="left"
                  fontSize={32}
                  fontFamily="AvertaStd-Bold"
                  color={appColors.primaryTextColor}
                >
                  My profile
                </Typography>
                <ButtonBase
                  style={{
                    marginTop: 36,
                    height: 50,
                    borderRadius: 25,
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                  sx={{
                    border: 1,
                    borderColor: "#E0E0E0",
                  }}
                >
                  <FlexBetween
                    style={{
                      width: "100%",
                    }}
                  >
                    <Typography
                      fontSize={14}
                      fontFamily="AvertaStd-semibold"
                      color={appColors.primaryTextColor}
                    >
                      Student profile
                    </Typography>
                    <img alt="rightArrow" src="/assets/icons/rightArrow.svg" />
                  </FlexBetween>
                </ButtonBase>
                <ButtonBase
                  style={{
                    marginTop: 24,
                    height: 50,
                    borderRadius: 25,
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                  sx={{
                    border: 1,
                    borderColor: "#E0E0E0",
                  }}
                >
                  <FlexBetween
                    style={{
                      width: "100%",
                    }}
                  >
                    <Typography
                      fontSize={14}
                      fontFamily="AvertaStd-semibold"
                      color={appColors.primaryTextColor}
                    >
                      View favorite properties
                    </Typography>
                    <img alt="rightArrow" src="/assets/icons/rightArrow.svg" />
                  </FlexBetween>
                </ButtonBase>
                <ButtonBase
                  style={{
                    marginTop: 24,
                    height: 50,
                    borderRadius: 25,
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                  sx={{
                    border: 1,
                    borderColor: "#E0E0E0",
                  }}
                >
                  <FlexBetween
                    style={{
                      width: "100%",
                    }}
                  >
                    <Typography
                      fontSize={14}
                      fontFamily="AvertaStd-semibold"
                      color={appColors.primaryTextColor}
                    >
                      Edit roommate profile
                    </Typography>
                    <img alt="rightArrow" src="/assets/icons/rightArrow.svg" />
                  </FlexBetween>
                </ButtonBase>
                <ButtonBase
                  style={{
                    marginTop: 24,
                    height: 50,
                    borderRadius: 25,
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                  sx={{
                    border: 1,
                    borderColor: "#E0E0E0",
                  }}
                >
                  <FlexBetween
                    style={{
                      width: "100%",
                    }}
                  >
                    <Typography
                      fontSize={14}
                      fontFamily="AvertaStd-semibold"
                      color={appColors.primaryTextColor}
                    >
                      Notification
                    </Typography>
                    <IOSSwitch defaultChecked />
                  </FlexBetween>
                </ButtonBase>
                <ButtonBase
                  style={{
                    marginTop: 24,
                    height: 50,
                    borderRadius: 25,
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                  sx={{
                    border: 1,
                    borderColor: "#E0E0E0",
                  }}
                >
                  <FlexBetween
                    style={{
                      width: "100%",
                    }}
                  >
                    <Typography
                      fontSize={14}
                      fontFamily="AvertaStd-semibold"
                      color={appColors.primaryTextColor}
                    >
                      Roommate profile
                    </Typography>
                    <IOSSwitch />
                  </FlexBetween>
                </ButtonBase>
              </Stack>
            </Box>
          </Stack>
        </div>
      )}
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <div
          style={{
            width: 450,
          }}
        >
          <Stack>
            <ButtonBase
              style={{
                width: 40,
                height: 40,
                position: "absolute",
                top: 16,
                right: 16,
              }}
              onClick={() => setOpenPopup(false)}
            >
              <img alt="x_circle" src="/assets/icons/fi_x-circle.svg" />
            </ButtonBase>
            <Typography
              style={{
                marginTop: 30,
              }}
              fontSize={24}
              fontFamily="AvertaStd-Bold"
              color={appColors.primaryTextColor}
            >
              Send Message
            </Typography>
            <Typography
              fontSize={16}
              fontFamily="AvertaStd-semibold"
              color={appColors.primaryTextColor}
              style={{
                marginTop: 24,
              }}
            >
              Message
            </Typography>
            <Box
              style={{
                height: 150,
                marginRight: isNonMobile ? 16 : 0,
                marginTop: 16,
                borderRadius: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              sx={{
                border: 1,
                borderColor: "#C2C2C2",
              }}
            >
              <TextField
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                fullWidth
                hiddenLabel
                multiline
                id="standard-basic"
                placeholder="Message"
                variant="standard"
                InputProps={{ disableUnderline: true }}
                fontSize={14}
                color={appColors.primaryTextColor}
                minRows={6}
                maxRows={7}
                value={message}
                onChange={(event) => {
                  setMessage(event.target.value);
                }}
              />
            </Box>
            <ButtonBase
              style={{
                backgroundColor: "#9490DB",
                width: 450,
                height: 50,
                marginTop: 27,
                borderRadius: 25,
                marginBottom: 16,
              }}
              onClick={sendMessage}
            >
              <Typography
                fontSize={14}
                fontFamily="AvertaStd-semibold"
                color="#FDFDFD"
              >
                Send
              </Typography>
            </ButtonBase>
          </Stack>
        </div>
      </Popup>
    </div>
  );
};

export default RoommateDetails;
