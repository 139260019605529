import {
  Autocomplete,
  Box,
  ButtonBase,
  Checkbox,
  Link,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { appColors } from "../../../../theme";
import Popup from "../../../../components/Popup";
import UserFooter from "../../../../components/UserFooter";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "../../../../state/store";
import { getLocations, getSchools } from "../../../../state/slices/froomie";
import useAuth from "../../../../hooks/useAuth";
import { useSnackbar } from "notistack";

const Listing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { managerLogin } = useAuth();
  const { isAuthenticated, user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const types = ["Apartment Complex", "House"]; //Apartment Complex and House
  const leases = ["Individually", "All-Together"]; // Individually and All-Together
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const { schools } = useSelector((state) => state.froomie);
  const { locations } = useSelector((state) => state.froomie);
  const [university, setUniversity] = useState("");
  const [type, setType] = useState("");
  const [floorCount, setFloorCount] = useState("");
  const [bedCount, setBedCount] = useState("");
  const [lease, setLease] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [lat, setLat] = useState("");
  const [lon, setLon] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [openLoginPopup, setOpenLoginPopup] = useState(false);
  const [description, setDescription] = useState("");

  useEffect(() => {
    dispatch(getSchools());
    dispatch(getLocations("mi"));
  }, [dispatch]);

  const handleFooterClick = () => {
    console.log(isAuthenticated);
    if (isAuthenticated) {
      if (user?.type === "manager") {
        navigate("/amentities", {
          state: {
            type: type,
            floorCount: floorCount,
            bedCount: bedCount,
            lease: lease,
            university: university,
            apartment: name,
            address: address,
            lat: lat,
            lon: lon,
            description: description,
          },
        });
      } else {
        continueClick();
      }
    } else {
      //setOpenSuccessPopup(true);
      setOpenLoginPopup(true);
    }
  };

  const continueClick = async () => {
    try {
      const loginResponse = await managerLogin(
        email,
        password,
        passwordConfirm
      );
      if (loginResponse.status) {
        navigate("/amentities", {
          state: {
            type: type,
            floorCount: floorCount,
            bedCount: bedCount,
            lease: lease,
            university: university,
            apartment: name,
            address: address,
            lat: lat,
            lon: lon,
            description: description,
          },
        });
      } else {
        enqueueSnackbar(loginResponse.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
    }
  };

  const handleOnChange = (event) => {
    console.log("Calling");
    dispatch(getLocations(event.target.value));
  };

  return (
    <div>
      <Stack
        padding="1rem 6%"
        style={{
          marginTop: 38,
          width: 550,
        }}
      >
        <Typography
          fontSize={32}
          fontFamily="AvertaStd-Bold"
          color={appColors.primaryTextColor}
        >
          Your Listing
        </Typography>
        <Typography color="#A0A0A0" fontSize={14}>
          All fields are required
        </Typography>
        <Typography
          fontSize={16}
          fontFamily="AvertaStd-semibold"
          color={appColors.primaryTextColor}
          style={{
            marginTop: 32,
          }}
        >
          What type of property are you listing?
        </Typography>
        <Select
          style={{
            marginTop: 16,
            paddingRight: 10,
            fontSize: 14,
            fontFamily: "AvertaStd-semibold",
            color: appColors.primaryTextColor,
          }}
          sx={{
            border: 1,
            borderColor: "#E0E0E0",
            borderRadius: 30,
          }}
          displayEmpty
          value={type}
          onChange={(event) => {
            setType(event.target.value);
          }}
          renderValue={type !== "" ? undefined : () => "Select a type"}
        >
          {types.map((item, index) => {
            return (
              <MenuItem value={item} key={index}>
                <Typography>{item}</Typography>
              </MenuItem>
            );
          })}
        </Select>
        <Typography
          fontSize={16}
          fontFamily="AvertaStd-semibold"
          color={appColors.primaryTextColor}
          style={{
            marginTop: 32,
          }}
        >
          How many floor plans do you have?
        </Typography>
        <Box
          style={{
            height: 50,
            marginTop: 16,
            borderRadius: 25,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          sx={{
            border: 1,
            borderColor: "#E0E0E0",
          }}
        >
          <TextField
            style={{
              paddingLeft: 20,
              paddingRight: 20,
            }}
            fullWidth
            hiddenLabel
            id="standard-basic"
            placeholder="Type here"
            variant="standard"
            InputProps={{ disableUnderline: true }}
            fontSize={14}
            color={appColors.primaryTextColor}
            value={floorCount}
            onChange={(event) => {
              setFloorCount(event.target.value);
            }}
          />
        </Box>
        <Typography
          fontSize={16}
          fontFamily="AvertaStd-semibold"
          color={appColors.primaryTextColor}
          style={{
            marginTop: 32,
          }}
        >
          How many beds in total do you have?
        </Typography>
        <Box
          style={{
            height: 50,
            marginTop: 16,
            borderRadius: 25,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          sx={{
            border: 1,
            borderColor: "#E0E0E0",
          }}
        >
          <TextField
            style={{
              paddingLeft: 20,
              paddingRight: 20,
            }}
            fullWidth
            hiddenLabel
            id="standard-basic"
            placeholder="e.i: 34"
            variant="standard"
            InputProps={{ disableUnderline: true }}
            fontSize={14}
            color={appColors.primaryTextColor}
            value={bedCount}
            onChange={(event) => {
              setBedCount(event.target.value);
            }}
          />
        </Box>
        <Typography
          fontSize={16}
          fontFamily="AvertaStd-semibold"
          color={appColors.primaryTextColor}
          style={{
            marginTop: 32,
          }}
        >
          How are leases handled?
        </Typography>
        <Select
          style={{
            marginTop: 16,
            paddingRight: 10,
            fontSize: 14,
            fontFamily: "AvertaStd-semibold",
            color: appColors.primaryTextColor,
          }}
          sx={{
            border: 1,
            borderColor: "#E0E0E0",
            borderRadius: 30,
          }}
          displayEmpty
          value={lease}
          onChange={(event) => {
            setLease(event.target.value);
          }}
          renderValue={lease !== "" ? undefined : () => "Select Leases"}
        >
          {leases.map((item, index) => {
            return (
              <MenuItem value={item} key={index}>
                <Typography>{item}</Typography>
              </MenuItem>
            );
          })}
        </Select>
        <Typography
          fontSize={16}
          fontFamily="AvertaStd-semibold"
          color={appColors.primaryTextColor}
          style={{
            marginTop: 32,
          }}
        >
          What school is your listing near?
        </Typography>
        <Select
          style={{
            marginTop: 16,
            paddingRight: 10,
            fontSize: 14,
            fontFamily: "AvertaStd-semibold",
            color: appColors.primaryTextColor,
          }}
          sx={{
            border: 1,
            borderColor: "#E0E0E0",
            borderRadius: 30,
          }}
          displayEmpty
          value={university}
          onChange={(event) => {
            setUniversity(event.target.value);
          }}
          renderValue={
            university !== "" ? undefined : () => "Select a university..."
          }
        >
          {schools.map((item, index) => {
            return (
              <MenuItem value={item} key={index}>
                <Typography>{item}</Typography>
              </MenuItem>
            );
          })}
        </Select>
        <Typography
          fontSize={16}
          fontFamily="AvertaStd-semibold"
          color={appColors.primaryTextColor}
          style={{
            marginTop: 32,
          }}
        >
          What is the name of your apartment complex?
        </Typography>
        <Box
          style={{
            height: 50,
            marginTop: 16,
            borderRadius: 25,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          sx={{
            border: 1,
            borderColor: "#E0E0E0",
          }}
        >
          <TextField
            style={{
              paddingLeft: 20,
              paddingRight: 20,
            }}
            fullWidth
            hiddenLabel
            id="standard-basic"
            placeholder="Type here"
            variant="standard"
            InputProps={{ disableUnderline: true }}
            fontSize={14}
            color={appColors.primaryTextColor}
            value={name}
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
        </Box>
        <Typography
          fontSize={16}
          fontFamily="AvertaStd-semibold"
          color={appColors.primaryTextColor}
          style={{
            marginTop: 32,
          }}
        >
          What is the address of your apartment complex?
        </Typography>
        <Typography
          fontSize={16}
          fontFamily="AvertaStd-semibold"
          color={appColors.primaryTextColor}
          style={{
            marginTop: 30,
          }}
        >
          Description
        </Typography>
        <Paper
          style={{
            height: 170,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            borderRadius: 16,
            marginTop: 16,
          }}
        >
          <TextField
            style={{
              padding: 20,
            }}
            fullWidth
            hiddenLabel
            id="standard-basic"
            placeholder="Description"
            variant="standard"
            InputProps={{ disableUnderline: true }}
            fontSize={14}
            color={appColors.primaryTextColor}
            multiline
            maxRows={7}
            value={description}
            onChange={(event) => {
              setDescription(event.target.value);
            }}
          />
        </Paper>
        <Autocomplete
          style={{
            marginTop: 16,
          }}
          sx={{
            border: 1,
            borderColor: "#E0E0E0",
            borderRadius: 30,
          }}
          fullWidth
          hiddenLabel
          id="standard-basic"
          placeholder="Search Address"
          variant="standard"
          InputProps={{ disableUnderline: true }}
          fontSize={14}
          color={appColors.primaryTextColor}
          onChange={(event, value) => {
            console.log(value);
            setAddress(value.address);
            setLat(value.latitude);
            setLon(value.longitude);
          }}
          options={locations}
          getOptionLabel={(locations) => locations.address}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Address"
              onChange={handleOnChange}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "50px",

                  legend: {
                    marginLeft: "30px",
                  },
                },
                "& .MuiAutocomplete-inputRoot": {
                  paddingLeft: "20px !important",
                  borderRadius: "50px",
                },
                "& .MuiInputLabel-outlined": {
                  paddingLeft: "20px",
                },
                "& .MuiInputLabel-shrink": {
                  marginLeft: "20px",
                  paddingLeft: "10px",
                  paddingRight: 0,
                  background: "white",
                },
              }}
            />
          )}
        />
      </Stack>
      <div
        style={{
          left: 0,
          right: 0,
          textAlign: "center",
          marginTop: 30,
        }}
      >
        <UserFooter buttonTitle="Next" handleClick={handleFooterClick} />
      </div>
      <Popup openPopup={openLoginPopup} setOpenPopup={setOpenLoginPopup}>
        <div
          style={{
            width: 450,
          }}
        >
          <Stack>
            <ButtonBase
              style={{
                width: 40,
                height: 40,
                position: "absolute",
                top: 16,
                right: 16,
              }}
              onClick={() => setOpenLoginPopup(false)}
            >
              <img alt="x_circle" src="/assets/icons/fi_x-circle.svg" />
            </ButtonBase>
            <Typography
              style={{
                marginTop: 30,
              }}
              fontSize={24}
              fontFamily="AvertaStd-Bold"
              color={appColors.primaryTextColor}
            >
              Let’s create something
            </Typography>
            <Typography fontSize={14} color="#A0A0A0">
              Login to post new Housing
            </Typography>
            <Typography
              fontSize={16}
              fontFamily="AvertaStd-semibold"
              color={appColors.primaryTextColor}
              style={{
                marginTop: 32,
              }}
            >
              Email address
            </Typography>
            <Box
              style={{
                height: 50,
                marginTop: 16,
                borderRadius: 25,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              sx={{
                border: 1,
                borderColor: "#E0E0E0",
              }}
            >
              <TextField
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                fullWidth
                hiddenLabel
                id="standard-basic"
                placeholder="email"
                variant="standard"
                InputProps={{ disableUnderline: true }}
                fontSize={14}
                color={appColors.primaryTextColor}
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </Box>
            <Typography
              fontSize={16}
              fontFamily="AvertaStd-semibold"
              color={appColors.primaryTextColor}
              style={{
                marginTop: 32,
              }}
            >
              Password
            </Typography>
            <Box
              style={{
                height: 50,
                marginTop: 16,
                borderRadius: 25,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              sx={{
                border: 1,
                borderColor: "#E0E0E0",
              }}
            >
              <TextField
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                fullWidth
                hiddenLabel
                id="standard-basic"
                placeholder="Type here"
                variant="standard"
                InputProps={{ disableUnderline: true }}
                fontSize={14}
                color={appColors.primaryTextColor}
                type="password"
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />
            </Box>
            <ButtonBase
              style={{
                backgroundColor: "#9490DB",
                width: 450,
                height: 50,
                marginTop: 27,
                borderRadius: 25,
                marginBottom: 16,
              }}
              onClick={continueClick}
            >
              <Typography
                fontSize={14}
                fontFamily="AvertaStd-semibold"
                color="#FDFDFD"
              >
                Sign In
              </Typography>
            </ButtonBase>
            <ButtonBase
              style={{
                backgroundColor: "white",
                width: 450,
                height: 50,
                borderRadius: 25,
                marginBottom: 30,
              }}
              onClick={() => {
                setOpenLoginPopup(false);
                setOpenSuccessPopup(true);
              }}
              sx={{
                border: 1,
                borderColor: "#9490DB",
              }}
            >
              <Typography
                fontSize={14}
                fontFamily="AvertaStd-semibold"
                color="#9490DB"
              >
                Sign Up
              </Typography>
            </ButtonBase>
          </Stack>
        </div>
      </Popup>
      <Popup openPopup={openSuccessPopup} setOpenPopup={setOpenSuccessPopup}>
        <div
          style={{
            width: 450,
          }}
        >
          <Stack>
            <ButtonBase
              style={{
                width: 40,
                height: 40,
                position: "absolute",
                top: 16,
                right: 16,
              }}
              onClick={() => setOpenSuccessPopup(false)}
            >
              <img alt="x_circle" src="/assets/icons/fi_x-circle.svg" />
            </ButtonBase>
            <Typography
              style={{
                marginTop: 30,
              }}
              fontSize={24}
              fontFamily="AvertaStd-Bold"
              color={appColors.primaryTextColor}
            >
              Let’s create something
            </Typography>
            <Typography fontSize={14} color="#A0A0A0">
              Create a password before messaging the landlord
            </Typography>
            <Typography
              fontSize={16}
              fontFamily="AvertaStd-semibold"
              color={appColors.primaryTextColor}
              style={{
                marginTop: 32,
              }}
            >
              Email address
            </Typography>
            <Box
              style={{
                height: 50,
                marginTop: 16,
                borderRadius: 25,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              sx={{
                border: 1,
                borderColor: "#E0E0E0",
              }}
            >
              <TextField
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                fullWidth
                hiddenLabel
                id="standard-basic"
                placeholder="email"
                variant="standard"
                InputProps={{ disableUnderline: true }}
                fontSize={14}
                color={appColors.primaryTextColor}
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </Box>
            <Typography
              fontSize={16}
              fontFamily="AvertaStd-semibold"
              color={appColors.primaryTextColor}
              style={{
                marginTop: 32,
              }}
            >
              Create Password
            </Typography>
            <Box
              style={{
                height: 50,
                marginTop: 16,
                borderRadius: 25,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              sx={{
                border: 1,
                borderColor: "#E0E0E0",
              }}
            >
              <TextField
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                fullWidth
                hiddenLabel
                id="standard-basic"
                placeholder="Type here"
                variant="standard"
                InputProps={{ disableUnderline: true }}
                fontSize={14}
                color={appColors.primaryTextColor}
                type="password"
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />
            </Box>
            <Typography
              fontSize={16}
              fontFamily="AvertaStd-semibold"
              color={appColors.primaryTextColor}
              style={{
                marginTop: 32,
              }}
            >
              Re-type Password
            </Typography>
            <Box
              style={{
                height: 50,
                marginTop: 16,
                borderRadius: 25,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              sx={{
                border: 1,
                borderColor: "#E0E0E0",
              }}
            >
              <TextField
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                fullWidth
                hiddenLabel
                id="standard-basic"
                placeholder="Type here"
                variant="standard"
                InputProps={{ disableUnderline: true }}
                fontSize={14}
                color={appColors.primaryTextColor}
                type="password"
                value={passwordConfirm}
                onChange={(event) => {
                  setPasswordConfirm(event.target.value);
                }}
              />
            </Box>
            <Stack
              direction="row"
              style={{
                marginTop: 24,
              }}
            >
              <Checkbox />
              <Stack>
                <Typography
                  align="center"
                  fontSize={14}
                  style={{
                    marginLeft: -20,
                  }}
                >
                  I have read and agree to the Froomie
                </Typography>
                <Stack direction="row">
                  <Link component={RouterLink} to="">
                    <Typography color="primary" fontSize={14}>
                      Terms and conditions
                    </Typography>
                  </Link>
                  <Typography
                    align="center"
                    fontSize={14}
                    style={{
                      marginLeft: 2,
                      marginRight: 2,
                    }}
                  >
                    and
                  </Typography>
                  <Link
                    component={RouterLink}
                    to=""
                    style={{
                      marginLeft: 2,
                    }}
                  >
                    <Typography color="primary" fontSize={14}>
                      Privacy policy.
                    </Typography>
                  </Link>
                </Stack>
              </Stack>
            </Stack>
            <ButtonBase
              style={{
                backgroundColor: "#9490DB",
                width: 450,
                height: 50,
                marginTop: 27,
                borderRadius: 25,
                marginBottom: 30,
              }}
              onClick={continueClick}
            >
              <Typography
                fontSize={14}
                fontFamily="AvertaStd-semibold"
                color="#FDFDFD"
              >
                Continue
              </Typography>
            </ButtonBase>
          </Stack>
        </div>
      </Popup>
    </div>
  );
};

export default Listing;
