import React from "react";
import { appColors } from "../../../theme";
import useWindowDimensions from "../../../components/useWindowDimensions";
import {
  Box,
  Grid,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

const HelpCenter = () => {
  const { height } = useWindowDimensions();
  const isNonMobile = useMediaQuery("(min-width: 600px)");

  return (
    <div
      style={{
        background: "#F9F9F9",
      }}
    >
      <Box padding="1rem 6%">
        <Grid
          container
          sx={{
            height: {
              md: height - 240,
            },
          }}
          style={{
            marginTop: 40,
          }}
        >
          <Grid xs={12} md={8}>
            <Stack>
              <Typography
                fontSize={32}
                fontFamily="AvertaStd-Bold"
                color={appColors.primaryTextColor}
              >
                Help Center
              </Typography>
              <Typography color="#A0A0A0" fontSize={14}>
                Student housing complex managed by Wendy*Demo Text*
              </Typography>
              <Stack
                direction="row"
                style={{
                  marginTop: 24,
                }}
              >
                <Paper
                  style={{
                    width: 300,
                    height: 100,
                    borderRadius: 16,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Stack direction="row">
                    <img alt="phone" src="/assets/fi_smartphone.svg" />
                    <Stack
                      style={{
                        marginLeft: 18,
                      }}
                    >
                      <Typography
                        fontSize={16}
                        fontFamily="AvertaStd-semibold"
                        color="#A0A0A0"
                      >
                        Phone number
                      </Typography>
                      <Typography
                        color="#9490DB"
                        fontSize={20}
                        fontFamily="AvertaStd-semibold"
                      >
                        (629) 555-0129
                      </Typography>
                    </Stack>
                  </Stack>
                </Paper>
                <Paper
                  style={{
                    width: 300,
                    height: 100,
                    borderRadius: 16,
                    marginLeft: 28,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Stack direction="row">
                    <img alt="mail" src="/assets/fi_mail.svg" />
                    <Stack
                      style={{
                        marginLeft: 18,
                      }}
                    >
                      <Typography
                        fontSize={16}
                        fontFamily="AvertaStd-semibold"
                        color="#A0A0A0"
                      >
                        Email address
                      </Typography>
                      <Typography
                        color="#9490DB"
                        fontSize={20}
                        fontFamily="AvertaStd-semibold"
                      >
                        hello@froomie.io
                      </Typography>
                    </Stack>
                  </Stack>
                </Paper>
                <Paper
                  style={{
                    width: 300,
                    height: 100,
                    borderRadius: 16,
                    marginLeft: 28,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Stack direction="row">
                    <img alt="pin" src="/assets/fi_map-pin.svg" />
                    <Stack
                      style={{
                        marginLeft: 18,
                      }}
                    >
                      <Typography
                        fontSize={16}
                        fontFamily="AvertaStd-semibold"
                        color="#A0A0A0"
                      >
                        Location
                      </Typography>
                      <Typography
                        color="#9490DB"
                        fontSize={18}
                        fontFamily="AvertaStd-semibold"
                      >
                        Connecticut 35624, USA
                      </Typography>
                    </Stack>
                  </Stack>
                </Paper>
              </Stack>
              <Paper
                style={{
                  borderRadius: 24,
                  height: 460,
                  marginTop: 28,
                  marginRight: 20,
                }}
              >
                <Typography
                  color="#231F20"
                  fontSize={24}
                  fontFamily="AvertaStd-semibold"
                  style={{
                    marginTop: 32,
                    marginLeft: 32,
                  }}
                >
                  Video Tutorials
                </Typography>
                <Typography
                  color="#4E4547"
                  fontSize={14}
                  style={{
                    marginTop: 8,
                    marginLeft: 32,
                  }}
                >
                  We’ve some video tutorial that might gonna help you to know
                  more about us.
                </Typography>
              </Paper>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default HelpCenter;
