import React, { useState } from "react";
import {
  Box,
  IconButton,
  Typography,
  ButtonBase,
  useTheme,
  useMediaQuery,
  Link,
  Avatar,
} from "@mui/material";
import { Menu, Close } from "@mui/icons-material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import FlexBetween from "../components/FlexBetween";
import useAuth from "../hooks/useAuth";
import { IMAGE_BASE_URL } from "../utils/constants";

const Header = ({ isShow, setIsShow }) => {
  const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
  const navigate = useNavigate();
  const isNonMobileScreens = useMediaQuery("(min-width: 600px)");
  const { isAuthenticated, user } = useAuth();
  const [active, setActive] = useState("");

  const theme = useTheme();
  const background = theme.palette.background.default;
  const alt = theme.palette.background.alt;
  const primaryMain = theme.palette.primary.main;

  return (
    <FlexBetween padding="1rem 6%" backgroundColor={alt}>
      <FlexBetween gap="1.75rem">
        <Link underline="none" component={RouterLink} to="/">
          <img
            width="100%"
            height="42px"
            alt="advert"
            src="/assets/logo.svg"
            style={{ borderRadius: "0.75rem", margin: "0.75rem 0" }}
          />
        </Link>
        {/* {isNonMobileScreens && window.location.pathname === "/rentals" && (
          <FlexBetween
            backgroundColor="white"
            padding="0.1rem 1.5rem"
            border={1}
            borderColor="#C2C2C2"
            style={{
              width: 400,
              height: 48,
              borderRadius: 24,
            }}
          >
            <InputBase
              style={{
                width: 300,
              }}
              placeholder="Search..."
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value);
              }}
            />
            <IconButton onClick={searchClick}>
              <Search />
            </IconButton>
          </FlexBetween>
        )} */}
      </FlexBetween>

      {/* DESKTOP NAV */}
      {isNonMobileScreens ? (
        <FlexBetween gap="4rem">
          <ButtonBase
            onClick={() => {
              setActive("Browse rentals");
              navigate("/rentals");
            }}
          >
            <Typography
              fontSize={16}
              fontWeight={active === "Browse rentals" ? "bold" : "normal"}
            >
              Browse rentals
            </Typography>
          </ButtonBase>
          <ButtonBase
            onClick={() => {
              setActive("Find roommates");
              navigate("/roommates");
            }}
          >
            <Typography
              fontSize={16}
              fontWeight={active === "Find roommates" ? "bold" : "normal"}
            >
              Find roommates
            </Typography>
          </ButtonBase>
          <ButtonBase
            onClick={() => {
              setActive("Landlords");
              navigate("/landlords");
            }}
          >
            <Typography
              fontSize={16}
              fontWeight={active === "Landlords" ? "bold" : "normal"}
            >
              Landlords
            </Typography>
          </ButtonBase>
          {!isAuthenticated && (
            <ButtonBase
              onClick={() => {
                navigate("/userSignUp");
              }}
            >
              <Typography
                fontSize={14}
                color={primaryMain}
                fontWeight="semibold"
              >
                Sign up
              </Typography>
            </ButtonBase>
          )}
          {isAuthenticated && (
            <ButtonBase
              onClick={() => {
                navigate("/message");
              }}
            >
              <Typography
                fontSize={16}
                color="#231F20"
                fontFamily="AvertaStd-Semibold"
              >
                Messages
              </Typography>
            </ButtonBase>
          )}
          {isAuthenticated ? (
            <ButtonBase
              onClick={() => {
                console.log("click");
                setIsShow(true);
              }}
            >
              <Box
                sx={{
                  width: 110,
                  height: 46,
                  borderRadius: 30,
                  border: 1,
                  borderColor: "#C2C2C2",
                }}
              >
                <FlexBetween
                  style={{
                    padding: 4,
                  }}
                >
                  <img
                    src="/assets/icons/menu.svg"
                    alt="menu"
                    style={{
                      marginLeft: 24,
                    }}
                  />
                  <Avatar
                    src={IMAGE_BASE_URL + user.photo}
                    style={{
                      width: 36,
                      height: 36,
                    }}
                  />
                </FlexBetween>
              </Box>
            </ButtonBase>
          ) : (
            <ButtonBase
              onClick={() => {
                navigate("/signIn");
              }}
            >
              <Box
                sx={{
                  width: 110,
                  height: 46,
                  borderRadius: 30,
                  border: 1,
                  borderColor: primaryMain,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  fontSize={14}
                  color={primaryMain}
                  fontWeight="semibold"
                >
                  Sign in
                </Typography>
              </Box>
            </ButtonBase>
          )}
        </FlexBetween>
      ) : (
        <IconButton
          onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
        >
          <Menu />
        </IconButton>
      )}

      {/* MOBILE NAV */}
      {!isNonMobileScreens && isMobileMenuToggled && (
        <Box
          position="fixed"
          right="0"
          bottom="0"
          height="100%"
          zIndex="10"
          maxWidth="500px"
          minWidth="300px"
          backgroundColor={background}
        >
          {/* CLOSE ICON */}
          <Box display="flex" justifyContent="flex-end" p="1rem">
            <IconButton
              onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
            >
              <Close />
            </IconButton>
          </Box>

          {/* MENU ITEMS */}
          <FlexBetween
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="3rem"
          >
            <ButtonBase
              onClick={() => {
                navigate("/rentals");
                setIsMobileMenuToggled(false);
              }}
            >
              <Typography fontSize={16} fontWeight="bold">
                Browse rentals
              </Typography>
            </ButtonBase>
            <ButtonBase
              onClick={() => {
                navigate("/roommates");
                setIsMobileMenuToggled(false);
              }}
            >
              <Typography fontSize={16}>Find roommates</Typography>
            </ButtonBase>
            <ButtonBase
              onClick={() => {
                navigate("/landlords");
                setIsMobileMenuToggled(false);
              }}
            >
              <Typography fontSize={16}>Landlords</Typography>
            </ButtonBase>
            {!isAuthenticated && (
              <ButtonBase
                onClick={() => {
                  navigate("/userSignUp");
                  setIsMobileMenuToggled(false);
                }}
              >
                <Typography
                  fontSize={14}
                  color={primaryMain}
                  fontWeight="semibold"
                >
                  Sign up
                </Typography>
              </ButtonBase>
            )}
            {isAuthenticated ? (
              <ButtonBase
                onClick={() => {
                  setIsShow(true);
                  setIsMobileMenuToggled(false);
                }}
              >
                <Box
                  sx={{
                    width: 110,
                    height: 46,
                    borderRadius: 30,
                    border: 1,
                    borderColor: "#C2C2C2",
                  }}
                >
                  <FlexBetween
                    style={{
                      padding: 4,
                    }}
                  >
                    <img
                      src="/assets/icons/menu.svg"
                      alt="menu"
                      style={{
                        marginLeft: 24,
                      }}
                    />
                    <Avatar
                      src={IMAGE_BASE_URL + user.photo}
                      style={{
                        width: 36,
                        height: 36,
                      }}
                    />
                  </FlexBetween>
                </Box>
              </ButtonBase>
            ) : (
              <ButtonBase
                onClick={() => {
                  navigate("/signIn");
                }}
              >
                <Box
                  sx={{
                    width: 110,
                    height: 46,
                    borderRadius: 30,
                    border: 1,
                    borderColor: primaryMain,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    fontSize={14}
                    color={primaryMain}
                    fontWeight="semibold"
                  >
                    Sign in
                  </Typography>
                </Box>
              </ButtonBase>
            )}
          </FlexBetween>
        </Box>
      )}
    </FlexBetween>
  );
};

export default Header;
