import {
  Box,
  ButtonBase,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Header from "../../components/Header";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import InfoSidebar from "../../components/InfoSidebar";
import { useDispatch, useSelector } from "../../state/store";
import { useSnackbar } from "notistack";
import useAuth from "../../hooks/useAuth";
import { getColleges } from "../../state/slices/froomie";
import axiosInstance from "../../utils/axios";
import { appColors } from "../../theme";
import FlexBetween from "../../components/FlexBetween";
import IOSSwitch from "../../components/IOSSwitch";
import DragDropFiles from "../../components/DragDropFiles";

const SelectingLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [isShow, setIsShow] = useState(false);
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const [files, setFiles] = useState();
  const { colleges } = useSelector((state) => state.froomie);
  const [university, setUniversity] = useState(user?.school);
  const [name, setName] = useState(user?.name);
  const [year, setYear] = useState(user?.graduationYear);
  const [isChecked, setIsChecked] = useState(user?.roommateEnabled);
  const [isNotification, setIsNotification] = useState(
    user?.notificationEnabled
  );

  const years = ["2024", "2025", "2026", " 2027", "2028", "Graduate"];

  useEffect(() => {
    dispatch(getColleges());
  }, [dispatch]);

  const handleSave = async () => {
    const data = new FormData();
    data.append("photo", files[0]);
    data.append("graduationYear", year);
    data.append("school", university);

    try {
      const options = {
        method: "PATCH",
        url: "/users/updateMe",
        data,
      };
      const response = await axiosInstance.request(options);
      if (response.data.status) {
        enqueueSnackbar("Profile Updated.", { variant: "success" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Server Error", { variant: "error" });
    }
  };

  const updateRoommateProfile = async (checked) => {
    const data = new FormData();
    data.append("roommateEnabled", checked ? "true" : "false");

    try {
      const options = {
        method: "PATCH",
        url: "/users/updateMe",
        data,
      };
      const response = await axiosInstance.request(options);
      if (response.data.status) {
        setIsChecked(response.data.data?.user?.roommateEnabled);
        //localStorage.setItem(response.data.data?.user)
        enqueueSnackbar(
          response.data.data?.user?.roommateEnabled
            ? "Roommate Profile Enabled"
            : "Roommate Profile Disabled",
          { variant: "success" }
        );
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Server Error", { variant: "error" });
    }
  };

  const updateNotification = async (checked) => {
    const data = new FormData();
    data.append("notificationEnabled", checked ? "true" : "false");

    try {
      const options = {
        method: "PATCH",
        url: "/users/updateMe",
        data,
      };
      const response = await axiosInstance.request(options);
      if (response.data.status) {
        setIsNotification(response.data.data?.user?.notificationEnabled);
        //localStorage.setItem(response.data.data?.user)
        enqueueSnackbar(
          response.data.data?.user?.notificationEnabled
            ? "Notification Enabled"
            : "Notification Disabled",
          { variant: "success" }
        );
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Server Error", { variant: "error" });
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
      setIsShow(false);
    } catch (error) {
      console.error(error);
    }
  };

  const dashboardClick = () => {
    if (user?.type === "manager") {
      navigate("/dashboard");
    } else {
      setIsEdit(true);
    }
  };

  return (
    <div width="100%" height="100%">
      <Header
        isSidebarOpen={setIsSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        isShow={isShow}
        setIsShow={setIsShow}
      />
      <Box display={isNonMobile ? "flex" : "block"}>
        <InfoSidebar
          isNonMobile={isNonMobile}
          drawerWidth="320px"
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
        <Box flexGrow={1}>
          <Outlet />
        </Box>
      </Box>
      {isShow && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            top: 0,
            right: 0,
            width: "100%",
            textAlign: "center",
          }}
        >
          <Stack
            direction="row"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            {isNonMobile && (
              <Box
                style={{
                  width: "100%",
                  backgroundColor: appColors.primaryTextColor,
                  opacity: 0.3,
                }}
              />
            )}

            <Box
              style={{
                width: 550,
                backgroundColor: "white",
                height: "100%",
              }}
            >
              <ButtonBase
                style={{
                  width: 32,
                  height: 32,
                  position: "absolute",
                  top: 40,
                  right: 30,
                }}
                onClick={() => setIsShow(false)}
              >
                <img
                  width={32}
                  height={32}
                  alt="x-Circle"
                  src="/assets/icons/fi_x-circle.svg"
                />
              </ButtonBase>
              <ButtonBase
                style={{
                  marginTop: 36,
                  height: 50,
                  borderRadius: 25,
                  paddingLeft: 20,
                  paddingRight: 20,
                  position: "absolute",
                  bottom: 50,
                  right: 20,
                  width: isNonMobile ? 370 : 350,
                }}
                sx={{
                  border: 1,
                  borderColor: "#E0E0E0",
                }}
                onClick={() => {
                  handleLogout();
                }}
              >
                <FlexBetween
                  style={{
                    width: "100%",
                  }}
                >
                  <Typography
                    fontSize={14}
                    fontFamily="AvertaStd-semibold"
                    color={appColors.primaryTextColor}
                  >
                    Logout
                  </Typography>
                  <img alt="rightArrow" src="/assets/icons/rightArrow.svg" />
                </FlexBetween>
              </ButtonBase>
              <Stack
                style={{
                  marginLeft: isNonMobile ? 40 : 20,
                  marginRight: isNonMobile ? 40 : 20,
                  marginTop: 90,
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "left",
                }}
              >
                <Typography
                  align="left"
                  fontSize={32}
                  fontFamily="AvertaStd-Bold"
                  color={appColors.primaryTextColor}
                >
                  My profile
                </Typography>
                <ButtonBase
                  style={{
                    marginTop: 36,
                    height: 50,
                    borderRadius: 25,
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                  sx={{
                    border: 1,
                    borderColor: "#E0E0E0",
                  }}
                  onClick={dashboardClick}
                >
                  <FlexBetween
                    style={{
                      width: "100%",
                    }}
                  >
                    <Typography
                      fontSize={14}
                      fontFamily="AvertaStd-semibold"
                      color={appColors.primaryTextColor}
                    >
                      {user?.type === "manager"
                        ? "Dashboard"
                        : "Student profile"}
                    </Typography>
                    <img alt="rightArrow" src="/assets/icons/rightArrow.svg" />
                  </FlexBetween>
                </ButtonBase>
                <ButtonBase
                  style={{
                    marginTop: 24,
                    height: 50,
                    borderRadius: 25,
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                  sx={{
                    border: 1,
                    borderColor: "#E0E0E0",
                  }}
                  onClick={() => {
                    setIsShow(false);
                    navigate("/favorites");
                  }}
                >
                  <FlexBetween
                    style={{
                      width: "100%",
                    }}
                  >
                    <Typography
                      fontSize={14}
                      fontFamily="AvertaStd-semibold"
                      color={appColors.primaryTextColor}
                    >
                      View favorite properties
                    </Typography>
                    <img alt="rightArrow" src="/assets/icons/rightArrow.svg" />
                  </FlexBetween>
                </ButtonBase>
                <ButtonBase
                  style={{
                    marginTop: 24,
                    height: 50,
                    borderRadius: 25,
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                  sx={{
                    border: 1,
                    borderColor: "#E0E0E0",
                  }}
                  onClick={() => {
                    navigate("/questions");
                  }}
                >
                  <FlexBetween
                    style={{
                      width: "100%",
                    }}
                  >
                    <Typography
                      fontSize={14}
                      fontFamily="AvertaStd-semibold"
                      color={appColors.primaryTextColor}
                    >
                      {user?.type === "manager"
                        ? "Edit Profile"
                        : "Edit roommate profile"}
                    </Typography>
                    <img alt="rightArrow" src="/assets/icons/rightArrow.svg" />
                  </FlexBetween>
                </ButtonBase>
                <Box
                  style={{
                    marginTop: 24,
                    height: 50,
                    borderRadius: 25,
                    paddingLeft: 20,
                    paddingRight: 20,
                    display: "flex",
                    justifyContent: "center",
                  }}
                  sx={{
                    border: 1,
                    borderColor: "#E0E0E0",
                  }}
                >
                  <FlexBetween
                    style={{
                      width: "100%",
                    }}
                  >
                    <Typography
                      fontSize={14}
                      fontFamily="AvertaStd-semibold"
                      color={appColors.primaryTextColor}
                    >
                      Notification
                    </Typography>
                    <IOSSwitch
                      checked={isNotification}
                      onChange={(event) => {
                        updateNotification(event.target.checked);
                      }}
                    />
                  </FlexBetween>
                </Box>
                {user?.type === "manager" ? (
                  ""
                ) : (
                  <Box
                    style={{
                      marginTop: 24,
                      height: 50,
                      borderRadius: 25,
                      paddingLeft: 20,
                      paddingRight: 20,
                      display: "flex",
                      justifyContent: "center",
                    }}
                    sx={{
                      border: 1,
                      borderColor: "#E0E0E0",
                    }}
                  >
                    <FlexBetween
                      style={{
                        width: "100%",
                      }}
                    >
                      <Typography
                        fontSize={14}
                        fontFamily="AvertaStd-semibold"
                        color={appColors.primaryTextColor}
                      >
                        Roommate profile
                      </Typography>
                      <IOSSwitch
                        checked={isChecked}
                        onChange={(event) => {
                          updateRoommateProfile(event.target.checked);
                        }}
                      />
                    </FlexBetween>
                  </Box>
                )}
              </Stack>
            </Box>
          </Stack>
        </div>
      )}
      {isEdit && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            top: 0,
            right: 0,
            width: "100%",
            textAlign: "center",
          }}
        >
          <Stack
            direction="row"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            {isNonMobile && (
              <Box
                style={{
                  width: "100%",
                  backgroundColor: appColors.primaryTextColor,
                  opacity: 0.3,
                }}
              />
            )}

            <Box
              style={{
                width: 550,
                backgroundColor: "white",
                height: "100%",
              }}
            >
              <ButtonBase
                style={{
                  width: 32,
                  height: 32,
                  position: "absolute",
                  top: 40,
                  right: 30,
                }}
                onClick={() => setIsEdit(false)}
              >
                <img
                  width={32}
                  height={32}
                  alt="x-Circle"
                  src="/assets/icons/fi_x-circle.svg"
                />
              </ButtonBase>
              <FlexBetween
                direction="row"
                style={{
                  marginTop: 36,
                  height: 50,
                  paddingLeft: 20,
                  paddingRight: 20,
                  position: "absolute",
                  bottom: 50,
                  right: 20,
                  width: 380,
                }}
              >
                <ButtonBase
                  sx={{
                    height: 50,
                    borderRadius: 25,
                  }}
                  onClick={() => {
                    handleLogout();
                  }}
                >
                  <Typography
                    fontSize={14}
                    fontFamily="AvertaStd-semibold"
                    color="#EE1817"
                  >
                    Delete this account
                  </Typography>
                </ButtonBase>
                <ButtonBase
                  sx={{
                    background: "#9490DB",
                    width: 120,
                    height: 50,
                    borderRadius: 25,
                  }}
                  onClick={() => {
                    handleSave();
                  }}
                >
                  <Typography
                    fontSize={14}
                    fontFamily="AvertaStd-semibold"
                    color="white"
                  >
                    Save
                  </Typography>
                </ButtonBase>
              </FlexBetween>
              <Stack
                style={{
                  marginLeft: isNonMobile ? 40 : 20,
                  marginRight: isNonMobile ? 40 : 20,
                  marginTop: 90,
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "left",
                }}
              >
                <Typography
                  align="left"
                  fontSize={32}
                  fontFamily="AvertaStd-Bold"
                  color={appColors.primaryTextColor}
                >
                  Edit Profile
                </Typography>
                <Typography
                  fontSize={14}
                  fontFamily="AvertaStd-semibold"
                  color={appColors.primaryTextColor}
                  style={{
                    marginTop: 24,
                  }}
                  align="left"
                >
                  {user?.type === "manager"
                    ? "Profile picture"
                    : "Student profile picture"}
                </Typography>
                <DragDropFiles
                  files={files}
                  setFiles={setFiles}
                  showFiles={true}
                  isMultiple={false}
                />
                <Typography
                  fontSize={14}
                  fontFamily="AvertaStd-semibold"
                  color={appColors.primaryTextColor}
                  style={{
                    marginTop: 24,
                  }}
                  align="left"
                >
                  Name
                </Typography>
                <Box
                  style={{
                    height: 50,
                    marginTop: 16,
                    borderRadius: 25,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  sx={{
                    border: 1,
                    borderColor: "#E0E0E0",
                  }}
                >
                  <TextField
                    style={{
                      paddingLeft: 20,
                      paddingRight: 20,
                    }}
                    fullWidth
                    hiddenLabel
                    id="standard-basic"
                    placeholder="Name"
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    fontSize={14}
                    color={appColors.primaryTextColor}
                    value={name}
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                  />
                </Box>
                {user?.type === "manager" ? (
                  ""
                ) : (
                  <Stack>
                    <Typography
                      fontSize={14}
                      fontFamily="AvertaStd-semibold"
                      color={appColors.primaryTextColor}
                      style={{
                        marginTop: 24,
                      }}
                      align="left"
                    >
                      University
                    </Typography>
                    <Select
                      style={{
                        marginTop: 16,
                        paddingRight: 10,
                        fontSize: 14,
                        fontFamily: "AvertaStd-semibold",
                        color: appColors.primaryTextColor,
                      }}
                      sx={{
                        border: 1,
                        borderColor: "#E0E0E0",
                        borderRadius: 30,
                      }}
                      displayEmpty
                      value={university}
                      onChange={(event) => {
                        setUniversity(event.target.value);
                      }}
                      renderValue={
                        university !== ""
                          ? undefined
                          : () => "Select a university..."
                      }
                    >
                      {colleges.map((item, index) => {
                        return (
                          <MenuItem value={item.name} key={index}>
                            <Typography>{item.name}</Typography>
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <Typography
                      fontSize={14}
                      fontFamily="AvertaStd-semibold"
                      color={appColors.primaryTextColor}
                      style={{
                        marginTop: 24,
                      }}
                      align="left"
                    >
                      Graduation year
                    </Typography>
                    <Select
                      style={{
                        marginTop: 16,
                        paddingRight: 10,
                        fontSize: 14,
                        fontFamily: "AvertaStd-semibold",
                        color: appColors.primaryTextColor,
                      }}
                      sx={{
                        border: 1,
                        borderColor: "#E0E0E0",
                        borderRadius: 30,
                      }}
                      displayEmpty
                      value={year}
                      onChange={(event) => {
                        setYear(event.target.value);
                      }}
                      renderValue={
                        year !== "" ? undefined : () => "Select Graduation Year"
                      }
                    >
                      {years.map((item, index) => {
                        return (
                          <MenuItem value={item} key={index}>
                            <Typography>{item}</Typography>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Stack>
                )}
              </Stack>
            </Box>
          </Stack>
        </div>
      )}
    </div>
  );
};

export default SelectingLayout;
