import {
  Box,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import UserFooter from "../../../components/UserFooter";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { appColors } from "../../../theme";
import { useSnackbar } from "notistack";

const Major = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [major, setMajor] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [instagram, setInstagram] = useState("");
  const [snapChat, setSnapChat] = useState("");
  const [tiktok, setTiktok] = useState("");

  const gender = ["Co - Ed", "Male", "Female"];

  const handleFooterClick = () => {
    if (selectedGender === "") {
      enqueueSnackbar("Gender is Required", { variant: "error" });
    } else {
      navigate("/profileSignUp", {
        state: {
          living: location.state.living,
          neatFreak: location.state.neatFreak,
          nightOwl: location.state.nightOwl,
          relationship: location.state.relationship,
          weed: location.state.weed,
          temperature: location.state.temperature,
          activities: location.state.activities,
          hobbies: location.state.hobbies,
          gender: selectedGender,
          major: major,
          instagram: instagram,
          snapchat: snapChat,
          tiktok: tiktok,
        },
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          height: "calc(100vh - 110px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          flexDirection="column"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          sx={{
            flex: "1 1 auto",
          }}
        >
          <Stack
            style={{
              marginTop: 38,
              width: 350,
              marginBottom: 40,
            }}
          >
            <Typography
              fontSize={32}
              fontFamily="AvertaStd-Bold"
              color={appColors.primaryTextColor}
            >
              My roommate profile
            </Typography>
            <Typography fontSize={14} color="#A0A0A0">
              Let’s set up your roommate profile!
            </Typography>
            <Typography
              fontSize={16}
              fontFamily="AvertaStd-semibold"
              color={appColors.primaryTextColor}
              style={{
                marginTop: 32,
              }}
            >
              4. Intended Major
            </Typography>
            <Box
              style={{
                backgroundColor: "#EEEEEE",
                height: 50,
                marginRight: 16,
                marginTop: 16,
                borderRadius: 25,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                fullWidth
                hiddenLabel
                id="standard-basic"
                placeholder="Type here"
                variant="standard"
                InputProps={{ disableUnderline: true }}
                fontSize={14}
                color={appColors.primaryTextColor}
                value={major}
                onChange={(event) => {
                  setMajor(event.target.value);
                }}
              />
            </Box>
            <Typography
              fontSize={16}
              fontFamily="AvertaStd-semibold"
              color={appColors.primaryTextColor}
              style={{
                marginTop: 32,
              }}
            >
              Select your gender
            </Typography>
            <Select
              style={{
                marginTop: 16,
                paddingRight: 10,
                fontSize: 14,
                fontFamily: "AvertaStd-semibold",
                color: appColors.primaryTextColor,
              }}
              sx={{
                border: 1,
                borderColor: "#E0E0E0",
                borderRadius: 30,
              }}
              displayEmpty
              value={selectedGender}
              onChange={(event) => {
                setSelectedGender(event.target.value);
              }}
              renderValue={
                selectedGender !== "" ? undefined : () => "Select a type"
              }
            >
              {gender.map((item, index) => {
                return (
                  <MenuItem value={item} key={index}>
                    <Typography>{item}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
            <Typography
              fontSize={16}
              fontFamily="AvertaStd-semibold"
              color={appColors.primaryTextColor}
              style={{
                marginTop: 32,
              }}
            >
              Connect Socials (optional)
            </Typography>
            <Stack
              direction="row"
              style={{
                marginTop: 32,
              }}
            >
              <img
                style={{
                  marginTop: 10,
                }}
                width={32}
                height={32}
                alt="fb"
                src="/assets/instagram_color.svg"
              />
              <Box
                style={{
                  width: "100%",
                  backgroundColor: "#EEEEEE",
                  height: 50,
                  borderRadius: 25,
                  marginLeft: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                  fullWidth
                  hiddenLabel
                  id="standard-basic"
                  placeholder="Type here"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  fontSize={14}
                  color={appColors.primaryTextColor}
                  value={instagram}
                  onChange={(event) => {
                    setInstagram(event.target.value);
                  }}
                />
              </Box>
            </Stack>
            <Stack
              direction="row"
              style={{
                marginTop: 32,
              }}
            >
              <img
                style={{
                  marginTop: 10,
                }}
                width={32}
                height={32}
                alt="fb"
                src="/assets/snapchat_color.svg"
              />
              <Box
                style={{
                  width: "100%",
                  backgroundColor: "#EEEEEE",
                  height: 50,
                  borderRadius: 25,
                  marginLeft: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                  fullWidth
                  hiddenLabel
                  id="standard-basic"
                  placeholder="Type here"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  fontSize={14}
                  color={appColors.primaryTextColor}
                  value={snapChat}
                  onChange={(event) => {
                    setSnapChat(event.target.value);
                  }}
                />
              </Box>
            </Stack>
            <Stack
              direction="row"
              style={{
                marginTop: 32,
              }}
            >
              <img
                style={{
                  marginTop: 10,
                }}
                width={32}
                height={32}
                alt="fb"
                src="/assets/tiktok_color.svg"
              />
              <Box
                style={{
                  width: "100%",
                  backgroundColor: "#EEEEEE",
                  height: 50,
                  borderRadius: 25,
                  marginLeft: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                  fullWidth
                  hiddenLabel
                  id="standard-basic"
                  placeholder="Type here"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  fontSize={14}
                  color={appColors.primaryTextColor}
                  value={tiktok}
                  onChange={(event) => {
                    setTiktok(event.target.value);
                  }}
                />
              </Box>
            </Stack>
          </Stack>
        </Box>

        <Box
          style={{
            width: "100%",
            textAlign: "center",
            marginTop: 30,
          }}
          sx={{
            flex: "0 1 auto",
          }}
        >
          <UserFooter buttonTitle="Continue" handleClick={handleFooterClick} />
        </Box>
      </Box>
    </>
  );
};

export default Major;
