import { Box, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { appColors } from "../../theme";
import UserFooter from "../../components/UserFooter";
import useAuth from "../../hooks/useAuth";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

const SignIn = () => {
  const { login } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleFooterClick = async () => {
    try {
      const loginResponse = await login(email, password);
      if (loginResponse.status) {
        navigate("/");
      } else {
        enqueueSnackbar(loginResponse.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
    }
  };

  return (
    <div>
      <Box
        flexDirection="column"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack
          style={{
            marginTop: 38,
            width: 350,
          }}
        >
          <Typography
            fontSize={32}
            fontFamily="AvertaStd-Bold"
            color={appColors.primaryTextColor}
          >
            Welcome Back,
          </Typography>
          <Typography fontSize={14} color="#A0A0A0">
            Login into account
          </Typography>
          <Typography
            fontSize={16}
            fontFamily="AvertaStd-semibold"
            color={appColors.primaryTextColor}
            style={{
              marginTop: 32,
            }}
          >
            Email
          </Typography>
          <Box
            style={{
              backgroundColor: "#EEEEEE",
              height: 50,
              marginRight: 16,
              marginTop: 16,
              borderRadius: 25,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              style={{
                paddingLeft: 20,
                paddingRight: 20,
              }}
              fullWidth
              hiddenLabel
              placeholder="Email"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              fontSize={14}
              value={email}
              color={appColors.primaryTextColor}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
          </Box>

          <Typography
            fontSize={16}
            fontFamily="AvertaStd-semibold"
            color={appColors.primaryTextColor}
            style={{
              marginTop: 32,
            }}
          >
            Password
          </Typography>
          <Box
            style={{
              backgroundColor: "#EEEEEE",
              height: 50,
              marginRight: 16,
              marginTop: 16,
              borderRadius: 25,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              style={{
                paddingLeft: 20,
                paddingRight: 20,
              }}
              fullWidth
              hiddenLabel
              placeholder="Password"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              fontSize={14}
              color={appColors.primaryTextColor}
              type={"password"}
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />
          </Box>
        </Stack>
      </Box>
      <div
        style={{
          position: "absolute",
          bottom: "0px",
          left: 0,
          width: "100%",
          textAlign: "center",
        }}
      >
        <UserFooter buttonTitle="Next" handleClick={handleFooterClick} />
      </div>
    </div>
  );
};

export default SignIn;
