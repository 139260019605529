import {
  Box,
  ButtonBase,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import FlexBetween from "../components/FlexBetween";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const navigate = useNavigate();

  return (
    <div>
      <Box bgcolor="#000000" padding="1rem 6%">
        <Box
          display={isNonMobile ? "flex" : "block"}
          justifyContent={isNonMobile ? "space-between" : "normal"}
        >
          <Stack spacing={2}>
            <img
              width="157px"
              height="42px"
              alt="logo_white"
              src="/assets/logo_white.svg"
            />
            <Typography
              color="white"
              fontSize={14}
              style={{
                marginTop: 30,
                letterSpacing: 10,
              }}
            >
              CONTACT
            </Typography>
            <Typography
              color="white"
              fontSize={30}
              fontWeight="bold"
              fontFamily="AvertaStd-Bold"
            >
              hello@Froomie.io
            </Typography>
          </Stack>
          <Stack>
            <Typography
              color="white"
              fontSize={15}
              fontWeight="semibold"
              fontFamily="AvertaStd-Semibold"
            >
              Navigation
            </Typography>
            <ButtonBase
              onClick={() => {
                navigate("/");
              }}
            >
              <Typography
                color="gray"
                fontSize={14}
                style={{
                  marginTop: 30,
                }}
              >
                Home
              </Typography>
            </ButtonBase>
            <Typography
              color="gray"
              fontSize={14}
              style={{
                marginTop: 10,
                marginLeft: 20,
              }}
            >
              Features
            </Typography>
          </Stack>
          <Stack>
            <Typography color="white" fontSize={15} fontWeight="semibold">
              {""}
            </Typography>
            <Typography
              color="gray"
              fontSize={14}
              style={{
                marginTop: 30,
              }}
            >
              Contact Us
            </Typography>
            <Typography
              color="gray"
              fontSize={14}
              style={{
                marginTop: 10,
              }}
            >
              Product Updates
            </Typography>
          </Stack>
          <Stack
            style={{
              marginRight: 80,
            }}
          >
            <Typography color="white" fontSize={15} fontWeight="semibold">
              Join our Newsletter
            </Typography>
            <Typography
              color="gray"
              fontSize={14}
              style={{
                marginTop: 30,
              }}
            >
              Stay up to date to our
            </Typography>
            <Typography
              color="gray"
              fontSize={14}
              style={{
                marginTop: 10,
              }}
            >
              company information
            </Typography>
            <ButtonBase
              style={{
                width: 234,
                height: 45,
                marginTop: 20,
                marginBottom: isNonMobile ? 0 : 20,
              }}
            >
              <Box
                sx={{
                  width: 234,
                  height: 45,
                  borderRadius: 45,
                  border: 2,
                  borderColor: "gray",
                  backgroundColor: "#292526",
                  paddingRight: 0.3,
                  paddingTop: 0.3,
                  paddingLeft: 2,
                }}
              >
                <FlexBetween>
                  <Typography
                    fontSize={14}
                    color="white"
                    fontWeight="regular"
                    style={{
                      opacity: 0.5,
                    }}
                  >
                    Your email address
                  </Typography>
                  <img
                    width="36px"
                    height="36px"
                    alt="send"
                    src="/assets/send.svg"
                  />
                </FlexBetween>
              </Box>
            </ButtonBase>
          </Stack>
        </Box>
        <FlexBetween>
          <Stack direction="row" spacing={2}>
            <Link to={"https://www.facebook.com/froomieapp"}>
              <ButtonBase>
                <img
                  width="16px"
                  height="16px"
                  alt="fb_white"
                  src="/assets/fb_white.svg"
                />
              </ButtonBase>
            </Link>
            <Link to={"https://www.linkedin.com/company/froomie"}>
              <ButtonBase>
                <img
                  width="16px"
                  height="16px"
                  alt="linkdinwhite"
                  src="/assets/linkdinwhite.svg"
                />
              </ButtonBase>
            </Link>
            <Link to={"https://www.instagram.com/froomie"}>
              <ButtonBase>
                <img
                  width="16px"
                  height="16px"
                  alt="instagram_white"
                  src="/assets/instagram_white.svg"
                />
              </ButtonBase>
            </Link>
          </Stack>
        </FlexBetween>
        <Box
          style={{
            backgroundColor: "#FDFDFD",
            height: 1,
            marginTop: "40px",
          }}
        />
        <Box
          display={isNonMobile ? "flex" : "block"}
          justifyContent={isNonMobile ? "space-between" : "normal"}
          style={{
            marginTop: 36,
            marginBottom: 36,
            width: "100%",
          }}
        >
          <Typography color="#98A6AC" fontSize={14}>
            Copyright @ 2024 Froomie.io All Rights Approved
          </Typography>
          <Stack
            style={{
              marginTop: isNonMobile ? 0 : 10,
            }}
            direction={isNonMobile ? "row" : "column"}
            spacing={isNonMobile ? 10 : 1}
          >
            <ButtonBase
              onClick={() => {
                navigate("/privacy-policy");
              }}
            >
              <Typography color="#98A6AC" fontSize={14}>
                Privacy Policy
              </Typography>
            </ButtonBase>
            <ButtonBase
              onClick={() => {
                navigate("/terms-conditions");
              }}
            >
              <Typography color="#98A6AC" fontSize={14}>
                Terms & Conditions
              </Typography>
            </ButtonBase>
          </Stack>
        </Box>
      </Box>
    </div>
  );
};

export default Footer;
