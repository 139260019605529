import {
  Box,
  ButtonBase,
  Divider,
  Grid,
  IconButton,
  List,
  MenuItem,
  Select,
  Slider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FlexBetween from "../../components/FlexBetween";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "../../state/store";
import { IMAGE_BASE_URL, MAP_KEY } from "../../utils/constants";
import { getHousings, getSchoolList } from "../../state/slices/froomie";
import { GoogleMap, OverlayView, useLoadScript } from "@react-google-maps/api";
import Popup from "../../components/Popup";
import { appColors } from "../../theme";
import SegmentedControl from "../../components/SegmentedControl";
import { Clear } from "@mui/icons-material";

const Rentals = () => {
  const theme = useTheme();
  const primaryMain = theme.palette.primary.main;
  const navigate = useNavigate();
  const location = useLocation();
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const dispatch = useDispatch();
  const { housings, schoolList } = useSelector((state) => state.froomie);
  const [openFilter, setOpenFilter] = useState(false);
  const [value, setValue] = useState([0, 1200]);
  const [distance, setDistance] = useState("10 miles");
  const [bedRoom, setBedRoom] = useState("0");
  const [pet, setPet] = useState("None");
  const [school, setSchool] = useState();
  const [searchText, setSearchText] = useState("");

  const params = {
    page: 1,
    limit: 100,
  };

  const distances = [
    "10 miles",
    "20 miles",
    "30 miles",
    "40 miles",
    "50 miles",
  ];

  useEffect(() => {
    if (location.state?.search !== "") {
      params["school"] = location.state?.search;
      setSearchText(location.state?.search);
      //location.state = undefined;
    }
    dispatch(getSchoolList());
    dispatch(getHousings(params));
  }, [dispatch, location]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: MAP_KEY,
  });

  const center = useMemo(
    () => ({ lat: 38.8235983, lng: -94.53452120000001 }),
    []
  );

  const applyFilter = () => {
    params["minRent[gte]"] = value[0];
    if (school !== undefined) {
      params["distance"] = distance.slice(0, 2);
      params["lat"] = school.lat;
      params["lng"] = school.lng;
    }
    params["maxBedrooms[gte]"] = bedRoom;

    if (pet !== "None") {
      params["pets"] = pet;
    }
    dispatch(getHousings(params));
  };

  const searchClick = (value) => {
    if (value !== "") {
      params["school"] = value;
    }
    dispatch(getHousings(params));
  };

  return (
    <div
      style={{
        backgroundColor: "#FCFCFC",
      }}
    >
      <Stack
        padding="1rem 6%"
        style={{
          paddingTop: 24,
        }}
      >
        <Grid container spacing={isNonMobile ? 5 : 2}>
          <Grid item xs={12} md={5}>
            <Stack>
              <Typography
                fontSize={24}
                fontFamily="AvertaStd-Bold"
                color="#231F20"
              >
                Universities
              </Typography>
              <Stack
                direction={isNonMobile ? "row" : "column"}
                spacing={2}
                style={{
                  marginTop: 16,
                }}
              >
                <Stack direction="row">
                  <FlexBetween
                    backgroundColor="white"
                    padding="0.1rem 1.5rem"
                    border={1}
                    borderColor="#C2C2C2"
                    style={{
                      width: 400,
                      height: 48,
                      borderRadius: 24,
                    }}
                  >
                    <Select
                      style={{
                        width: "100%",
                        fontSize: 14,
                        fontFamily: "AvertaStd-semibold",
                        color: "black",
                      }}
                      sx={{
                        boxShadow: "none",
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            border: 0,
                          },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            border: 0,
                          },
                      }}
                      displayEmpty
                      value={searchText}
                      onChange={(event) => {
                        setSearchText(event.target.value);
                        searchClick(event.target.value);
                      }}
                      renderValue={
                        searchText !== ""
                          ? undefined
                          : () => "Select a university..."
                      }
                    >
                      {schoolList.map((item, index) => {
                        return (
                          <MenuItem value={item.name} key={index}>
                            <Typography>{item.name}</Typography>
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <IconButton
                      onClick={() => {
                        setSearchText("");
                        searchClick("");
                      }}
                    >
                      <Clear />
                    </IconButton>
                  </FlexBetween>
                </Stack>
                <ButtonBase
                  sx={{
                    borderRadius: 30,
                  }}
                  onClick={() => setOpenFilter(true)}
                >
                  <Box
                    sx={{
                      width: 110,
                      height: 46,
                      borderRadius: 30,
                      border: 1,
                      borderColor: primaryMain,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FlexBetween>
                      <img src="/assets/icons/u_filter.svg" alt="filter" />
                      <Typography
                        fontSize={14}
                        fontFamily="AvertaStd-semibold"
                        color={primaryMain}
                        style={{
                          paddingLeft: 6,
                        }}
                      >
                        Filter
                      </Typography>
                    </FlexBetween>
                  </Box>
                </ButtonBase>
              </Stack>
              <Divider
                style={{
                  backgroundColor: "#C2C2C2",
                  marginTop: 20,
                }}
              />
              <Typography
                fontSize={18}
                color="#231F20"
                style={{
                  marginTop: 20,
                }}
              >
                {housings?.length} Listings
              </Typography>
              <Box
                style={{
                  height: 610,
                }}
                sx={{
                  overflow: "auto",
                }}
              >
                <List
                  style={{
                    overflow: "auto",
                  }}
                >
                  {housings.map((item) => {
                    return (
                      <Box
                        key={item.id}
                        style={{
                          marginTop: 30,
                          borderRadius: 20,
                          height: isNonMobile ? 190 : 150,
                          marginRight: 20,
                        }}
                        sx={{
                          border: 1,
                          borderColor: "#C2C2C2",
                        }}
                        onClick={() => {
                          navigate(`/listing/${item.id}`);
                        }}
                      >
                        <Stack direction="row">
                          <Box
                            style={{
                              height: isNonMobile ? 166 : 126,
                              width: isNonMobile ? 178 : 138,
                              margin: 12,
                              borderRadius: 16,
                            }}
                          >
                            <img
                              style={{
                                objectFit: "cover",
                                height: isNonMobile ? 166 : 126,
                                width: isNonMobile ? 178 : 138,
                                borderRadius: 16,
                              }}
                              alt="listing"
                              src={IMAGE_BASE_URL + item.photos[0]}
                            />
                          </Box>
                          <Stack
                            style={{
                              padding: 12,
                              width: "100%",
                            }}
                          >
                            <FlexBetween>
                              <Stack direction="row">
                                <img
                                  width={16}
                                  alt="star"
                                  src="/assets/star.svg"
                                />
                                <Typography
                                  align="center"
                                  fontSize={14}
                                  color="#7F7F7F"
                                  style={{
                                    marginLeft: 4,
                                  }}
                                >
                                  {item?.reviewsAverage?.toFixed(2)} (
                                  {item?.numberOfReviews})
                                </Typography>
                              </Stack>
                              <ButtonBase
                                onClick={() => {
                                  console.log("Love Clicked");
                                }}
                              >
                                <img
                                  alt="love"
                                  src={
                                    item?.isFavorite
                                      ? "/assets/icons/favorite.svg"
                                      : "/assets/love.svg"
                                  }
                                />
                              </ButtonBase>
                            </FlexBetween>
                            <Typography
                              fontSize={isNonMobile ? 18 : 12}
                              fontFamily="AvertaStd-Bold"
                              color="#231F20"
                              style={{
                                marginTop: isNonMobile ? 14 : 4,
                                display: "inline",
                              }}
                            >
                              {item.title}
                            </Typography>
                            <Stack
                              direction="row"
                              style={{
                                height: 24,
                                width: 82,
                                backgroundColor: "#D4D3EB",
                                borderRadius: 6,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: isNonMobile ? 14 : 6,
                              }}
                            >
                              <img alt="usd" src="/assets/usd.svg" />
                              <Typography
                                fontSize={11}
                                color="#9490DB"
                                style={{
                                  marginLeft: 4,
                                  marginTop: 2,
                                }}
                              >
                                Promotion
                              </Typography>
                            </Stack>
                            <FlexBetween
                              style={{
                                marginTop: isNonMobile ? 20 : 4,
                              }}
                            >
                              <Stack
                                direction="row"
                                style={{
                                  marginTop: 10,
                                }}
                              >
                                <Typography
                                  fontSize={isNonMobile ? 20 : 9}
                                  fontFamily="AvertaStd-Bold"
                                  color="#231F20"
                                >
                                  Starting at $
                                  {item?.minRent === undefined
                                    ? item?.floorPlans[0]?.minRent
                                    : item?.minRent}
                                </Typography>
                                <Typography
                                  fontSize={10}
                                  color="#949494"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  /month
                                </Typography>
                              </Stack>
                              <Stack
                                direction="row"
                                spacing={0.3}
                                style={{
                                  marginTop: 10,
                                }}
                              >
                                <img
                                  alt="subway"
                                  src="/assets/icons/subway.svg"
                                />
                                <img
                                  alt="utensils"
                                  src="/assets/icons/utensils.svg"
                                />
                              </Stack>
                            </FlexBetween>
                          </Stack>
                        </Stack>
                      </Box>
                    );
                  })}
                </List>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box
              style={{
                height: "100%",
                width: "100%",
                borderRadius: 20,
              }}
            >
              {!isLoaded ? (
                <h1>Loading...</h1>
              ) : (
                <GoogleMap
                  mapContainerClassName="map-container"
                  center={center}
                  zoom={5}
                >
                  {/* <MarkerF
                    position={{
                      lat: 38.8235983,
                      lng: -94.53452120000001,
                    }}
                    icon={"/assets/mapMarker.svg"}
                  /> */}
                  {housings.map((item) => {
                    return (
                      <OverlayView
                        key={item?.id}
                        position={{
                          lat: item?.lat,
                          lng: item?.lng,
                        }}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                      >
                        <div>
                          <Box width={60} height={20}>
                            <Typography
                              fontSize={16}
                              fontFamily="AvertaStd-semibold"
                              align="center"
                              backgroundColor="#FFFFFF"
                              style={{
                                borderRadius: 20,
                              }}
                            >
                              $
                              {item?.minRent === undefined
                                ? item?.floorPlans[0]?.minRent
                                : item?.minRent}
                            </Typography>
                          </Box>
                        </div>
                      </OverlayView>
                      // <MarkerF
                      //   key={item?.id}
                      //   position={{
                      //     lat: item?.lat,
                      //     lng: item?.lng,
                      //   }}
                      //   icon={"/assets/mapMarker.svg"}
                      // />
                    );
                  })}
                </GoogleMap>
              )}
            </Box>
          </Grid>
        </Grid>
      </Stack>
      <Popup openPopup={openFilter} setOpenPopup={setOpenFilter}>
        <div
          style={{
            width: 600,
          }}
        >
          <Stack>
            <FlexBetween>
              <Typography
                fontSize={24}
                fontFamily="AvertaStd-Bold"
                color="#231F20"
              >
                Housing Filter
              </Typography>
              <ButtonBase
                style={{
                  width: 40,
                  height: 40,
                }}
                onClick={() => setOpenFilter(false)}
              >
                <img alt="x_circle" src="/assets/icons/fi_x-circle.svg" />
              </ButtonBase>
            </FlexBetween>
            <Divider
              style={{
                marginTop: 10,
              }}
            />
            <FlexBetween
              style={{
                marginTop: 20,
              }}
            >
              <Typography
                fontSize={20}
                fontFamily="AvertaStd-Bold"
                color="#231F20"
              >
                Budget
              </Typography>
              <Typography
                fontSize={20}
                fontFamily="AvertaStd-Bold"
                color="#231F20"
              >
                ${value[0]} - ${value[1]}
              </Typography>
            </FlexBetween>
            <Slider
              min={0}
              max={1200}
              value={value}
              onChange={(event) => {
                setValue(event.target.value);
              }}
            />
            <Typography
              fontSize={20}
              fontFamily="AvertaStd-Bold"
              color="#231F20"
              style={{
                marginTop: 20,
              }}
            >
              School
            </Typography>
            <Select
              style={{
                marginTop: 16,
                paddingRight: 10,
                fontSize: 14,
                fontFamily: "AvertaStd-semibold",
                color: appColors.primaryTextColor,
              }}
              sx={{
                border: 1,
                borderColor: "#E0E0E0",
                borderRadius: 30,
              }}
              displayEmpty
              value={school}
              onChange={(event) => {
                setSchool(event.target.value);
              }}
              // renderValue={
              //   distance !== "" ? undefined : () => "Select "
              // }
            >
              {schoolList.map((item, index) => {
                return (
                  <MenuItem value={item} key={index}>
                    <Typography>{item.name}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
            <Typography
              fontSize={20}
              fontFamily="AvertaStd-Bold"
              color="#231F20"
              style={{
                marginTop: 20,
              }}
            >
              Distance
            </Typography>
            <Select
              style={{
                width: 300,
                marginTop: 16,
                paddingRight: 10,
                fontSize: 14,
                fontFamily: "AvertaStd-semibold",
                color: appColors.primaryTextColor,
              }}
              sx={{
                border: 1,
                borderColor: "#E0E0E0",
                borderRadius: 30,
              }}
              displayEmpty
              value={distance}
              onChange={(event) => {
                setDistance(event.target.value);
              }}
              renderValue={
                distance !== "" ? undefined : () => "Select distance"
              }
            >
              {distances.map((item, index) => {
                return (
                  <MenuItem value={item} key={index}>
                    <Typography>{item}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
            <Typography
              fontSize={20}
              fontFamily="AvertaStd-Bold"
              color="#231F20"
              style={{
                marginTop: 20,
              }}
            >
              Bedrooms
            </Typography>
            <SegmentedControl
              name="bedrooms"
              callback={(val) => setBedRoom(val)}
              controlRef={useRef()}
              segments={[
                {
                  label: "Any",
                  value: "0",
                  ref: useRef(),
                },
                {
                  label: "1+",
                  value: "1",
                  ref: useRef(),
                },
                {
                  label: "2+",
                  value: "2",
                  ref: useRef(),
                },
                {
                  label: "3+",
                  value: "3",
                  ref: useRef(),
                },
                {
                  label: "4+",
                  value: "4",
                  ref: useRef(),
                },
              ]}
            />
            <Typography
              fontSize={20}
              fontFamily="AvertaStd-Bold"
              color="#231F20"
              style={{
                marginTop: 20,
              }}
            >
              Pets
            </Typography>
            <SegmentedControl
              name="pets"
              callback={(val) => setPet(val)}
              controlRef={useRef()}
              segments={[
                {
                  label: "None",
                  value: "None",
                  ref: useRef(),
                },
                {
                  label: "Cats",
                  value: "Cats",
                  ref: useRef(),
                },
                {
                  label: "Dogs",
                  value: "Dogs",
                  ref: useRef(),
                },
              ]}
            />
            <Divider
              style={{
                marginTop: 20,
              }}
            />
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ButtonBase
                style={{
                  width: 150,
                  height: 45,
                  backgroundColor: "#9490db",
                  marginTop: 24,
                  borderRadius: 5,
                }}
                onClick={() => {
                  applyFilter();
                  setOpenFilter(false);
                }}
              >
                <Typography
                  fontSize={16}
                  fontFamily="AvertaStd-semibold"
                  color="white"
                >
                  Apply
                </Typography>
              </ButtonBase>
            </Box>
          </Stack>
        </div>
      </Popup>
    </div>
  );
};

export default Rentals;
