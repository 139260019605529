import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import froomieReducer from "./slices/froomie";

const rootPersistConfig = {
  key: "root",
  storage,
  version: 1,
  keyPrefix: "redux-",
  whitelist: [],
};

const froomiePersistConfig = {
  key: "froomie",
  storage,
  version: 1,
  keyPrefix: "redux-",
  whitelist: ["sortBy"],
};

const rootReducer = combineReducers({
  froomie: persistReducer(froomiePersistConfig, froomieReducer),
});

export { rootPersistConfig, rootReducer };
