import {
  Box,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import UserFooter from "../../../components/UserFooter";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { appColors } from "../../../theme";
import FlexBetween from "../../../components/FlexBetween";
import IOSSwitch from "../../../components/IOSSwitch";
import { useSnackbar } from "notistack";

const Questions = () => {
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const navigate = useNavigate();
  const [living, setLiving] = useState("");
  const [neatFreak, setNeatFreak] = useState(false);
  const [nightOwl, setNightOwl] = useState(false);
  const [relationship, setRelationship] = useState(false);
  const [weed, setWeed] = useState(false);
  const [temperature, setTemperature] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleFooterClick = () => {
    if (living === "") {
      enqueueSnackbar("Looking to live is Required", { variant: "error" });
    } else {
      navigate("/activities", {
        state: {
          living: living,
          neatFreak: neatFreak,
          nightOwl: nightOwl,
          relationship: relationship,
          weed: weed,
          temperature: temperature,
        },
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          height: "calc(100vh - 110px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          flexDirection="column"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          sx={{
            flex: "1 1 auto",
          }}
        >
          <Stack
            style={{
              marginTop: 38,
              width: isNonMobile ? 350 : "100%",
              padding: isNonMobile ? 0 : 20,
            }}
          >
            <Typography
              fontSize={32}
              fontFamily="AvertaStd-Bold"
              color={appColors.primaryTextColor}
            >
              My roommate profile
            </Typography>
            <Typography fontSize={14} color="#A0A0A0">
              Let’s set up your roommate profile!
            </Typography>
            <Typography
              fontSize={16}
              fontFamily="AvertaStd-semibold"
              color={appColors.primaryTextColor}
              style={{
                marginTop: 32,
              }}
            >
              1. Where are you looking to live?
            </Typography>
            <Select
              style={{
                marginTop: 16,
                paddingRight: 10,
                fontSize: 14,
                fontFamily: "AvertaStd-semibold",
                color: appColors.primaryTextColor,
                width: isNonMobile ? 380 : "100%",
                height: 50,
              }}
              sx={{
                border: 1,
                borderColor: "#231F20",
                borderRadius: 30,
              }}
              displayEmpty
              value={living}
              onChange={(event) => {
                setLiving(event.target.value);
              }}
              renderValue={living !== "" ? undefined : () => "Select a type"}
            >
              <MenuItem value="On-Campus">
                <Typography>On-Campus</Typography>
              </MenuItem>
              <MenuItem value="Off-Campus">
                <Typography>Off-Campus</Typography>
              </MenuItem>
            </Select>
            <Typography
              fontSize={16}
              fontFamily="AvertaStd-semibold"
              color={appColors.primaryTextColor}
              style={{
                marginTop: 32,
              }}
            >
              2. Answer the following questions
            </Typography>
            <FlexBetween
              style={{
                marginTop: 24,
              }}
            >
              <Typography color={appColors.primaryTextColor} fontSize={16}>
                Are you a neat freak?
              </Typography>
              <IOSSwitch
                onChange={(event) => {
                  setNeatFreak(event.target.checked);
                }}
                checked={neatFreak}
              />
            </FlexBetween>
            <FlexBetween
              style={{
                marginTop: 24,
              }}
            >
              <Typography color={appColors.primaryTextColor} fontSize={16}>
                Are you a night owl?
              </Typography>
              <IOSSwitch
                onChange={(event) => {
                  setNightOwl(event.target.checked);
                }}
                checked={nightOwl}
              />
            </FlexBetween>
            <FlexBetween
              style={{
                marginTop: 14,
              }}
            >
              <Typography color={appColors.primaryTextColor} fontSize={16}>
                Are you in a relationship?
              </Typography>
              <IOSSwitch
                onChange={(event) => {
                  setRelationship(event.target.checked);
                }}
                checked={relationship}
              />
            </FlexBetween>
            <FlexBetween
              style={{
                marginTop: 14,
              }}
            >
              <Typography color={appColors.primaryTextColor} fontSize={16}>
                Do you smoke weed?
              </Typography>
              <IOSSwitch
                onChange={(event) => {
                  setWeed(event.target.checked);
                }}
                checked={weed}
              />
            </FlexBetween>
            <FlexBetween
              style={{
                marginTop: 24,
              }}
            >
              <Typography color={appColors.primaryTextColor} fontSize={16}>
                Do you like the room temperature cold?
              </Typography>
              <IOSSwitch
                onChange={(event) => {
                  setTemperature(event.target.checked);
                }}
                checked={temperature}
              />
            </FlexBetween>
          </Stack>
        </Box>
        <Box
          style={{
            width: "100%",
            textAlign: "center",
            marginTop: 30,
          }}
          sx={{
            flex: "0 1 auto",
          }}
        >
          <UserFooter buttonTitle="Continue" handleClick={handleFooterClick} />
        </Box>
      </Box>
    </>
  );
};

export default Questions;
